import client from "@/api/client"
import {
  ImageOriginFilterOptions,
  ImageSimpleDetail,
  ImageSortByColumn,
  ImageView,
  SearchImagesResponse,
} from "@/api/sdk"
import { createAuthenticatedInfiniteQuery, createAuthenticatedQuery } from "../createAuthenticatedQuery"
import { enableInfiniteQueryMiddleware } from "../middlewares/enableQueryMiddleware"

export interface UseWorkspaceImagesInfiniteQueryVariables {
  folderId?: string
  view?: ImageView
  take?: number
  search?: string
  sortBy?: ImageSortByColumn
  sortOrder?: "ASC" | "DESC"
  ownerUids?: string[]
  onlyShared?: boolean
  onlyDiscoverable?: boolean
  origin?: ImageOriginFilterOptions
  wildcardId?: string
}

export const useWorkspaceImagesInfiniteQuery = createAuthenticatedInfiniteQuery<
  SearchImagesResponse,
  UseWorkspaceImagesInfiniteQueryVariables,
  Error,
  ImageSimpleDetail
>({
  primaryKey: "infinite-workspace-images",
  queryFn: ({
    queryKey: [
      _primaryKey,
      {
        folderId,
        view,
        take = 20,
        search,
        sortBy,
        sortOrder,
        ownerUids,
        onlyDiscoverable,
        onlyShared,
        wildcardId,
        origin,
      },
    ],
    pageParam = 0,
  }) =>
    client.api
      .imageControllerSearchImages({
        folderId: folderId!,
        view: view,
        skip: pageParam * take,
        origin,
        take,
        searchQuery: search,
        sortBy,
        sortOrder,
        ownerUids,
        onlyDiscoverable,
        wildcardId,
        onlyShared,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.images.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: res => res.images,
  getTotalFn: res => res.total,
  use: [enableInfiniteQueryMiddleware(v => !!v.folderId)],
})

export const useWorkspaceImagesQuery = createAuthenticatedQuery<
  SearchImagesResponse,
  UseWorkspaceImagesInfiniteQueryVariables,
  Error
>({
  primaryKey: "preview-workspace-images",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api
      .imageControllerSearchImages({
        skip: 0,
        take: 8,
        folderId: variables.folderId,
        searchQuery: variables.search,
        view: variables.view,
      })
      .then(res => res.data),
})
