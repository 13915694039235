import { RecipeFilterMode, RecipeSortOptions, RecipeType } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import Modal from "@/components/Modal"
import { useDebounce } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useGetRecipesInfiniteQuery } from "@/queries/workspace/recipe"
import { useRecipeImageStore } from "@/stores"
import classNames from "classnames"
import { motion } from "framer-motion"
import Image from "next/image"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useMemo, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import LoadingLogo from "../LoadingLogo"
import SearchBar from "../Workspace/FoldersV2/SearchBar"
import TabsList from "../Workspace/FoldersV2/TabsList"
import { tabsRecipes } from "../Workspace/Recipes"
import RecipeCard from "../Workspace/Recipes/RecipeCard"
import NotFoundItems from "../Workspace/Pinned/NotFoundItems"
import { SIMPLE_GENERATE_RECIPE_ID } from "../Explore/Gallery/GalleryHeader/GalleryGeneratedImage"
import GridBreakpointColsContainer from "../GridBreakpointColsContainer"
import ErrorUI from "../ErrorUI"

const SendRecipeModal = () => {
  const [selectedRecipe, setSelectedRecipe] = useState<string | null>(null)
  const [recipeImage, setRecipeImage] = useRecipeImageStore(state => [state.recipeImage, state.setRecipeImage])
  const [tab, setTab] = useState<string>(tabsRecipes[1].id)
  const [search, setSearch] = useState<string>("")
  const scrollRef = useRef<HTMLDivElement>(null)
  const debouncedSearch = useDebounce(search, 350)

  const router = useCustomRouter()

  const handleClose = () => {
    setSelectedRecipe(null)
    setTab(tabsRecipes[1].id)
    setSearch("")
    recipeImage?.onRemoveFocus?.()
    setRecipeImage(null)
  }

  const mode =
    tab === "my-recipes"
      ? RecipeFilterMode.Owned
      : tab === "protogaia-recipes"
        ? RecipeFilterMode.Public
        : RecipeFilterMode.Shared

  const recipeIds = useMemo(
    () =>
      recipeImage?.type === "wildcard"
        ? [
            "image-generator-simple",
            "image-generator",
            "image-generator-sdxl",
            "character-design-concept",
            "text-to-gif",
          ]
        : [
            "upscaler",
            "details-enhancer",
            "remix",
            "inpainting",
            "image-to-gif",
            "gif-generator",
            "remove-background",
            "img2img",
            "details-enhancer-pro",
          ],
    [recipeImage],
  )

  const { flattenData: defaultRecipes = [], isLoading: isLoadingDefaultRecipes } = useGetRecipesInfiniteQuery({
    variables: {
      take: 10,
      mode: RecipeFilterMode.Public,
      sortBy: RecipeSortOptions.A_TO_Z,
      recipeIds,
      canUseImage: recipeImage?.type !== "wildcard" ? true : undefined,
      canUseWildcard: recipeImage?.type === "wildcard" ? true : undefined,
    },
    enabled: !!recipeImage?.isModal,
  })

  const variables = useMemo(() => {
    return {
      types: [RecipeType.Normal, RecipeType.Describe],
      searchTerm: debouncedSearch,
      mode,
      canUseImage: recipeImage?.type !== "wildcard" ? true : undefined,
      canUseWildcard: recipeImage?.type === "wildcard" ? true : undefined,
    }
  }, [debouncedSearch, mode, recipeImage])

  const debounceVariable = useDebounce(variables, 50)

  const {
    flattenData = [],
    isLoading,
    isError,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useGetRecipesInfiniteQuery({
    variables: debounceVariable,
    enabled: !!recipeImage?.isModal,
  })

  const mappedRecipes = useMemo(() => {
    if ((debouncedSearch && debouncedSearch !== "") || tab === "my-recipes") {
      return flattenData
    }

    return [
      ...defaultRecipes.sort((a, b) => {
        if (a.id === SIMPLE_GENERATE_RECIPE_ID) return -1
        if (b.id === SIMPLE_GENERATE_RECIPE_ID) return 1
        return 0
      }),
      ...flattenData.filter(i => !recipeIds.includes(i.id)),
    ]
  }, [flattenData, defaultRecipes, recipeIds, debouncedSearch, tab])

  const handleSend = () => {
    if (!selectedRecipe) return

    recipeImage?.onClose?.()

    setTimeout(() => {
      setSelectedRecipe(null)
      googleAnalytics.event({
        action: "click",
        category: "modal",
        label: "send_to_recipe",
        params: {
          recipe_id: selectedRecipe,
          recipe_image_url: recipeImage?.params?.["image"] ?? "",
        },
      })

      setRecipeImage({
        ...recipeImage!,
        id: selectedRecipe,
        isModal: false,
      })

      router.push(`/workspace/tools/recipes/${selectedRecipe}`, undefined, { shallow: true })
    }, 250)
  }

  const handleConfirmSearch = (search: string) => {
    setSearch(search)
  }

  const renderBody = () => {
    if (isLoading || isLoadingDefaultRecipes)
      return (
        <div className="flex items-center justify-center w-full flex-1 text-gray-600">
          <LoadingLogo />
        </div>
      )

    if (isError) return <ErrorUI />

    if (flattenData.length === 0) return <NotFoundItems />

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.35, delay: 0.35 }}
        className={"relative flex-1 list-recipes"}
        key={tab}
      >
        <InfiniteScroll
          loadMore={() => fetchNextPage()}
          hasMore={!!hasNextPage && !isFetching}
          useWindow={false}
          getScrollParent={() => scrollRef.current}
          threshold={200}
        >
          <GridBreakpointColsContainer>
            {mappedRecipes.map((recipe, idx) => (
              <div key={recipe.id} className="relative">
                <RecipeCard
                  className={`recipe-${idx + 1}`}
                  recipe={recipe}
                  hiddenMenu
                  onClick={() => setSelectedRecipe(recipe.id)}
                />
                <div
                  className={classNames(`absolute top-0 left-0 w-full h-full pointer-events-none rounded-xl`, {
                    "border-[2px] border-atherPurple-500": selectedRecipe === recipe.id,
                  })}
                />
              </div>
            ))}
          </GridBreakpointColsContainer>
        </InfiniteScroll>
      </motion.div>
    )
  }

  return (
    <Modal
      title="Select Recipe"
      isOpen={recipeImage?.isModal ?? false}
      onClose={handleClose}
      bodyClassName="overflow-hidden p-0"
      className="relative w-full max-w-[80rem] min-h-full overflow-hidden my-0 p-4 rounded-2xl bg-atherGray-900 modal-content-child"
      containerClassName={"md:backdrop-blur-xl overflow-hidden"}
    >
      <div className="flex flex-col flex-1 w-full overflow-hidden">
        <TabsList
          tabQuery={tab}
          tabsData={tabsRecipes.filter(i => i.id !== "shared-with-me" && i.id !== "recommended")}
          onChange={v => setTab(v)}
        />
        <div className="py-4 overflow-hidden flex-1 flex flex-col">
          <div className={"flex w-full justify-end space-x-2 relative mb-6"}>
            <SearchBar
              disabledSearchQuery
              search={search}
              setSearch={setSearch}
              inputClassName="w-80 bg-atherGray-850"
              onConfirmSearch={handleConfirmSearch}
              onClear={() => handleConfirmSearch("")}
            />
          </div>
          <div ref={scrollRef} className="flex flex-1 w-full overflow-auto">
            {renderBody()}
          </div>
        </div>
        <div className="flex justify-end mt-4 space-x-2">
          <IconButton colorScheme="secondary" onClick={handleClose}>
            Cancel
          </IconButton>
          <IconButton onClick={handleSend} disabled={!selectedRecipe}>
            Send to Recipe
          </IconButton>
        </div>
      </div>
    </Modal>
  )
}

export default SendRecipeModal
