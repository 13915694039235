import client from "@/api/client"
import {
  ExploreImageSimpleDetail,
  ListRecipeTasksResponse,
  ListRecipeTasksResponseForAgentScheduler,
  RecipeTaskSortByColumn,
  RecipeTaskWithFullImages,
  RecipeType,
} from "@/api/sdk"
import { createAuthenticatedInfiniteQuery, createAuthenticatedQuery } from "../createAuthenticatedQuery"
import { GetNextPageParamFunction, GetPreviousPageParamFunction } from "@tanstack/react-query"
import { createInfiniteQuery, createQuery } from "react-query-kit"

export interface UseExploreImagesInfiniteQueryVariables {
  statuses?: ("QUEUED" | "RUNNING" | "COMPLETED" | "FAILED" | "CANCELED")[]
  sortBy?: RecipeTaskSortByColumn
  sortOrder?: "ASC" | "DESC"
  recipeIds?: string[]
  search?: string
  taskIds?: string[]
  styleIds?: string[]
  sdWorkflowId?: string | null
  type?: RecipeType
  includeFullDataImages?: boolean
  take?: number
}

export const useGetTaskInfiniteQuery = ({
  primaryKey,
  getNextPageParam,
}: {
  primaryKey: string
  getNextPageParam: GetNextPageParamFunction<number, ListRecipeTasksResponseForAgentScheduler>
}) => {
  const query = createAuthenticatedInfiniteQuery<
    ListRecipeTasksResponseForAgentScheduler,
    UseExploreImagesInfiniteQueryVariables,
    Error,
    ExploreImageSimpleDetail
  >({
    primaryKey,
    queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
      client.api
        .recipeTaskControllerGetTasksPost({
          skip: pageParam * 20,
          take: 20,
          statuses: variables.statuses,
          sortBy: variables.sortBy,
          sortOrder: variables.sortOrder,
          recipeIds: variables.recipeIds,
        })
        .then(res => res.data),
    getNextPageParam,
    initialPageParam: 0,
    refetchOnWindowFocus: false,
  })

  return query
}

export const useGetHistoryTaskInfiniteQuery = createAuthenticatedInfiniteQuery<
  RecipeTaskWithFullImages[],
  UseExploreImagesInfiniteQueryVariables,
  Error,
  RecipeTaskWithFullImages
>({
  primaryKey: "recipe-history",
  auth: false,
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .recipeTaskControllerGetHistoryTasksPost({
        skip: pageParam * (variables.take ?? 20),
        take: variables.take ?? 20,
        type: variables.type,
        statuses: ["COMPLETED"],
        sortBy: variables.sortBy,
        sortOrder: variables.sortOrder,
        search: variables.search,
        recipeIds: variables.recipeIds,
        taskIds: variables.taskIds,
        styleIds: variables.styleIds,
        sdWorkflowId: variables.sdWorkflowId ?? undefined,
        includeFullDataImages: variables.includeFullDataImages,
      })
      .then(res => res.data.other),
  initialPageParam: 0,
  refetchOnWindowFocus: false,
  flattenFn: data => data.flatMap(d => d),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage?.length < 20 ? undefined : pages.length),
})

export const useGetOngoingTaskQuery = createQuery<
  ListRecipeTasksResponse,
  UseExploreImagesInfiniteQueryVariables,
  Error
>({
  primaryKey: "recipe-ongoing",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api
      .recipeTaskControllerGetOngoingTasksPost({
        recipeIds: variables.recipeIds,
        styleIds: variables.styleIds,
        taskIds: variables.taskIds,
        sdWorkflowId: variables.sdWorkflowId ?? undefined,
        take: variables.take ?? undefined,
        type: variables.type,
        statuses: ["RUNNING", "QUEUED"],
      })
      .then(res => res.data),
  refetchOnWindowFocus: false,
})
