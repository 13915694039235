import IconButton from "@/components/IconButton"
import ReactPortal from "@/components/ReactPortal"
import { SettingProvider } from "@/components/Setting/SettingProvider"
import SettingSidebar from "@/components/Setting/SettingSidebar"
import {
  AlertIcon,
  ArrowDownIcon,
  ClipboardIcon,
  CrownIcon,
  FlowSquareIcon,
  ScienceIcon,
  XIcon,
} from "@/components/shared/icons"
import { useScreen } from "@/hooks"
import useClientStore from "@/lib/clientStore"
import { useAuth } from "@/providers/authContext"
import { useDrawerNotification, useSignInStore } from "@/stores"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import Link from "next/link"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useEffect, useRef, useState } from "react"
import { RemoveScroll } from "react-remove-scroll"
import AppLogo from "./AppLogo"
import SidebarBody from "./SidebarBody"
import UserInfoMobile from "./UserInfoMobile"
import WorkspaceSelector from "./WorkspaceSelector"
import NotificationBell from "@/components/NotificationDrawer/NotificationBell"

const pageData = [
  {
    id: "explore",
    name: "Explore",
  },
  {
    id: "workspace",
    name: "Workspace",
    child: {},
  },
  {
    id: "my-creations",
    name: "My Creations",
  },
  {
    id: "quests",
    name: "Earn $WOW",
  },
  {
    id: "leaderboard",
    name: "Leaderboard",
  },
]

const SidebarDrawerMobile = ({ className }: { className?: string }) => {
  const [isMenuOpen, setIsMenuOpen] = useClientStore(state => [state.isMenuOpen, state.setIsMenuOpen])
  const [selectedMenu, setSelectedMenu] = useState<string | null>(null)
  const router = useCustomRouter()
  const { user, userInfoQuery } = useAuth()
  const { width } = useScreen()
  const isMobile = width < 1024
  const setSignInModal = useSignInStore(state => state.setSignInModal)
  const [isHide, setIsHide] = useState(true)
  const ref = useRef(null)

  useEffect(() => {
    if (router.isReady) {
      if (router.pathname.includes("/settings/account")) {
        setSelectedMenu("account")
      } else if (router.pathname.includes("/workspace")) {
        setSelectedMenu("workspace")
      } else {
        setSelectedMenu(null)
      }
    }
  }, [router.isReady, router.pathname])

  const RootMenu = () => {
    return (
      <motion.div
        className="space-y-2 flex-1 flex flex-col w-full"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{
          opacity: 0,
        }}
        transition={{
          type: "tween",
          duration: 0.3,
          ease: "easeInOut",
        }}
      >
        {pageData
          .filter(i => (user ? i : i.id !== "my-creations" && i.id !== "workspace"))
          .map(item => {
            return (
              <button
                key={item.id}
                onClick={() => {
                  if (item.id === "workspace") {
                    if (!user) {
                      setSignInModal({
                        signIn: true,
                      })

                      setIsMenuOpen(false)

                      return
                    }
                  }

                  if (item.child) {
                    setSelectedMenu(item.id)
                  } else {
                    // if (item.isExternal) {
                    //   window.open(item.id, "_blank")
                    //   setIsMenuOpen(false)
                    //   return
                    // }

                    router.push(`/${item.id}`)
                    setIsMenuOpen(false)
                    return
                  }
                }}
                className={cn(
                  "flex items-center justify-between hover:bg-atherGray-850 w-full px-4 py-2 rounded-2xl hover:text-atherGray-0",
                  {
                    "text-atherGray-300": selectedMenu !== item.id,
                    "text-atherGray-0 bg-atherGray-800": selectedMenu === item.id,
                  },
                )}
              >
                <span className="font-semibold">{item.name}</span>
                {item.child && <ArrowDownIcon className="-rotate-90" />}
              </button>
            )
          })}
      </motion.div>
    )
  }

  const renderMenu = selectedMenu => {
    return (
      <div className="overflow-hidden flex-1 flex flex-col">
        <SidebarBody
          onSelectedMenu={() => {
            setIsMenuOpen(false)
          }}
          className="px-0 py-0 flex-1 w-full"
        />
      </div>
    )
  }

  const ChildMenu = () => {
    return (
      <motion.div
        className="flex flex-col flex-1 overflow-hidden"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{
          opacity: 0,
        }}
        transition={{
          type: "tween",
          duration: 0.3,
          ease: "easeInOut",
        }}
      >
        <button
          className=" py-2 mb-2 flex items-center cursor-pointer"
          onClick={() => {
            setSelectedMenu(null)
          }}
        >
          <ArrowDownIcon className="rotate-90" />
          <span className="font-semibold ml-2">{selectedMenu === "workspace" ? "Workspace" : ""}</span>
        </button>

        {renderMenu(selectedMenu)}
      </motion.div>
    )
  }

  const MobileMenu = () => {
    if (selectedMenu === "account") {
      return (
        <div>
          <SettingProvider>
            <SettingSidebar
              className="w-full"
              isMobile
              onClose={() => {
                setIsMenuOpen(false)
              }}
              onBack={() => {
                setSelectedMenu(null)
              }}
            />
          </SettingProvider>
        </div>
      )
    }

    return (
      <div className="flex flex-col space-y-2">
        <AppLogo
          className="pl-0"
          onClick={() => {
            setIsMenuOpen(false)
          }}
        />
        <div className="border-b border-atherGray-800 pb-4">
          <div className="flex items-center justify-between">
            <h3 className="text-xl font-semibold">Profile</h3>
            <NotificationBell
              onClick={() => {
                setIsMenuOpen(false)
              }}
            />
          </div>
          <UserInfoMobile
            onSelectMenu={menu => {
              setSelectedMenu(menu)
            }}
          />
        </div>
        <div
          className={classNames("border-b border-atherGray-800 pb-4", {
            "border-none": !user || !userInfoQuery?.data,
          })}
        >
          <h3 className="text-xl font-semibold mb-2">Shortcuts</h3>
          <div className="flex flex-wrap gap-2">
            <Link
              href="/workspace/tools/recipes"
              shallow
              className="flex flex-col items-center p-2"
              onClick={() => setIsMenuOpen(false)}
            >
              <div className="bg-atherGray-850 w-14 h-14 flex items-center justify-center rounded-2xl">
                <ScienceIcon width={20} height={20} className="text-atherGray-100" />
              </div>
              <span className="text-xs mt-1">Recipes</span>
            </Link>
            <Link
              onClick={() => setIsMenuOpen(false)}
              href="/workspace/tools/comfyui"
              shallow
              className="flex flex-col items-center p-2"
            >
              <div className="bg-atherGray-850 w-14 h-14 flex items-center justify-center rounded-2xl">
                <FlowSquareIcon width={20} height={20} className="text-atherGray-100" />
              </div>
              <span className="text-xs mt-1">ComfyUI</span>
            </Link>
            <Link
              onClick={() => setIsMenuOpen(false)}
              href="/workspace/tools/styles"
              shallow
              className="flex flex-col items-center p-2"
            >
              <div className="bg-atherGray-850 w-14 h-14 flex items-center justify-center rounded-2xl">
                <ClipboardIcon width={20} height={20} className="text-atherGray-100" />
              </div>
              <span className="text-xs mt-1">Styles</span>
            </Link>
            <Link
              onClick={() => {
                setIsMenuOpen(false)
              }}
              href="/leaderboard"
              shallow
              className="flex flex-col items-center py-2"
            >
              <div className="bg-atherGray-850 w-14 h-14 flex items-center justify-center rounded-2xl">
                <CrownIcon width={20} height={20} className="text-atherGray-100" />
              </div>
              <span className="text-xs mt-1 w-max-[56px]">Leaderboard</span>
            </Link>
          </div>
        </div>
        <div className={classNames({ hidden: !user || !userInfoQuery?.data })}>
          <h3 className="text-xl font-semibold mb-2">Workspace</h3>
          <div className="mb-2">
            <WorkspaceSelector isMobile isHide={isHide} setIsHide={setIsHide} />
          </div>
          <AnimatePresence>
            {!isHide && (
              <motion.div initial={{ height: 0 }} animate={{ height: "auto" }} exit={{ height: 0 }}>
                <SidebarBody
                  onSelectedMenu={() => {
                    setIsMenuOpen(false)
                  }}
                  hiddenWorkspaceSelector
                  className="px-0 py-0 flex-1 w-full"
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    )
  }

  if (!isMobile) return null

  return (
    <AnimatePresence mode="wait">
      {isMenuOpen && (
        <ReactPortal wrapperId="layout">
          <RemoveScroll
            removeScrollBar
            style={{
              animationDelay: "0s",
            }}
          >
            <div
              className={cn(
                "block lg:hidden top-0 left-0 fixed w-full overflow-hidden h-[calc(var(--vh,1vh)*100)] z-[24]",
                className,
              )}
              style={{
                WebkitOverflowScrolling: "touch",
              }}
            >
              <motion.div
                // initial={{ opacity: 0 }}
                // animate={{ opacity: 1 }}
                // exit={{ opacity: 0 }}
                onClick={() => setIsMenuOpen(false)}
                className="bg-blackAlpha-800 absolute inset-0 hidden md:block"
              />
              <motion.div
                // initial={{ width: "0", opacity: 0 }}
                // animate={{ width: "auto", opacity: 1 }}
                // exit={{ width: "0", opacity: 0 }}
                // transition={{
                //   duration: 0.3,
                //   ease: "easeInOut",
                // }}
                ref={ref}
                className="overflow-hidden h-full"
              >
                <div className="overflow-hidden flex flex-col h-full w-full md:w-80 relative pb-[5rem] md:pb-0 bg-atherGray-950 ">
                  <div className="flex-col bg-atherGray-950 flex-1 w-full hidden md:flex overflow-hidden p-4">
                    {!selectedMenu ? <RootMenu /> : <ChildMenu />}
                  </div>
                  <div className="flex md:hidden flex-col bg-atherGray-950 flex-1 w-full overflow-auto p-4">
                    {MobileMenu()}
                  </div>
                  <IconButton
                    onClick={e => {
                      e.stopPropagation()
                      setIsMenuOpen(false)
                    }}
                    className="p-1.5 min-h-0 absolute top-2 right-2 hidden md:flex"
                    colorScheme="secondary"
                  >
                    <XIcon width={10} height={10} />
                  </IconButton>
                </div>
              </motion.div>
            </div>
          </RemoveScroll>
        </ReactPortal>
      )}
    </AnimatePresence>
  )
}

export default SidebarDrawerMobile
