import { IconProps } from "."

export const Alert2Icon = (props: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.54748 6.45512C5.1566 3.60783 5.96116 2.18418 7.06518 1.81771C7.67255 1.6161 8.3268 1.6161 8.93417 1.81771C10.0382 2.18418 10.8428 3.60783 12.4519 6.45512C14.061 9.30242 14.8655 10.7261 14.6242 11.8861C14.4914 12.5242 14.1643 13.1031 13.6897 13.5396C12.827 14.3332 11.2179 14.3332 7.99967 14.3332C4.78144 14.3332 3.17232 14.3332 2.30965 13.5396C1.83506 13.1031 1.50793 12.5242 1.37516 11.8861C1.1338 10.7261 1.93836 9.30242 3.54748 6.45512Z"
        fill="#DFA000"
      />
      <path d="M7.99401 10.6665H8" stroke="#191918" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M8 8.6665L8 5.99984"
        stroke="#191918"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
