import { cdnPublicFolderUrl, imageExtensionRegex } from "@/constants"
import { cn } from "@/utils/cn"
import { forwardRef, useEffect, useRef, useState } from "react"

interface ImageWebpComponentProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src?: string
  typeResolution?: "w400" | "w800" | "none"
}

const ImageWebpComponent = forwardRef<HTMLImageElement, ImageWebpComponentProps>(
  ({ src, className, style, onClick, typeResolution = "w400", ...rest }, ref) => {
    const [imageStep, setImageStep] = useState(1)
    const prevSrc = useRef<string | undefined>(src)

    const url =
      imageStep === 3
        ? `${cdnPublicFolderUrl}/not-found-image.png`
        : imageStep === 2
          ? src
          : !src
            ? `${cdnPublicFolderUrl}/not-found-image.png`
            : src.endsWith(`.${typeResolution}.webp`)
              ? src
              : typeResolution === "none"
                ? src
                : `${src?.replace(imageExtensionRegex, "")}.${typeResolution}.webp`

    useEffect(() => {
      if (prevSrc.current !== src) {
        setImageStep(1)
      }
    }, [src])

    return (
      <img
        ref={ref}
        src={url}
        data-original-src={src}
        loading="lazy"
        onError={() => {
          setImageStep(prev => prev + 1)
        }}
        onClick={e => {
          if (onClick) {
            e.stopPropagation()
            onClick(e)
          }
        }}
        alt={rest.alt ?? ""}
        className={cn(
          "block",
          {
            "!object-contain": !src || imageStep >= 2,
            "cursor-pointer": !!onClick,
            "max-w-[5rem]": !src || imageStep > 2,
          },
          className,
        )}
        style={{
          ...style,
          objectPosition: !src || imageStep >= 2 ? "center" : style?.objectPosition,
        }}
        {...rest}
      />
    )
  },
)

export default ImageWebpComponent
