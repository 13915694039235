import client from "@/api/client"
import { SDWorkflowDetail, SearchSDWorkflowDto, SearchSDWorkflowResponse } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery, createAuthenticatedQuery } from "@/queries/createAuthenticatedQuery"

export const useComfyUiWorkflowInfiniteQuery = createAuthenticatedInfiniteQuery<
  SearchSDWorkflowResponse,
  SearchSDWorkflowDto,
  Error,
  SDWorkflowDetail
>({
  primaryKey: "comfyui-workflow-infinity",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .sdWorkflowControllerList({
        skip: pageParam * (variables.take ?? 20),
        take: variables.take ?? 20,
        searchTerm: variables.searchTerm,
        sortBy: variables.sortBy,
        ids: variables.ids,
        mode: variables.mode,
        onlyDiscoverable: variables.onlyDiscoverable,
        onlyShared: variables.onlyShared,
        includeDetails: false,
      })
      .then(res => res.data),
  flattenFn: data => data.workflows,
  getTotalFn: data => data.total,
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage?.workflows.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  refetchOnWindowFocus: false,
})

export const useComfyUiWorkflowDetailQuery = createAuthenticatedQuery<SDWorkflowDetail, { workflowId: string }, Error>({
  primaryKey: "sdWorkflow-detail",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.sdWorkflowControllerGetWorkflowDetails(variables.workflowId).then(res => res.data),
  refetchOnWindowFocus: false,
})

export const useComfyUiWorkflowListQuery = createAuthenticatedQuery<SearchSDWorkflowResponse, unknown, Error>({
  primaryKey: "comfyui-workflow-list",
  queryFn: ({ queryKey: [_primaryKey, _variables] }) =>
    client.api.sdWorkflowControllerList({ includeDetails: false }).then(res => res.data),
})
