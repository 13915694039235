import client from "@/api/client"
import {
  ContentModerationRating,
  EntityType,
  ExploreImageFullDetail,
  FeedbackRating,
  ImageFolder,
  ImageFullDetail,
  ImageMetadataItem,
  ImageRecipe,
  ImageRecipeTask,
  ImageRoleCapabilities,
  ImageWorkspace,
  RecipeItem,
  RecipeTask,
  RecipeType,
  SearchWildcardResponse,
  UserFeedback,
  WildcardFilterMode,
  WorkflowDetail,
} from "@/api/sdk"
import IconButton from "@/components/IconButton"

import { Menu } from "@/components/MenuFloating"
import Modal from "@/components/Modal"
import { COMFY_RECIPE_ID } from "@/components/Tools/ComfyUIRecipe/ComfyUIRecipeBuilder/ComfyUIDetail"
import { TRY_STYLE_RECIPE } from "@/components/Tools/Style/StyleDetail/StyleDetail"
import FeedbackPopup from "@/components/Workspace/Feedback/FeedbackPopup"
import { useWorkflowDetailMutate } from "@/components/Workspace/Workflows/WorkflowItem/ActionButtonsWorkflow"
import { RecipeTaskChainParams } from "@/components/Workspace/Workflows/WorkflowsChaining"
import {
  DeleteIcon,
  DownloadIcon,
  DownloadIcon1,
  OpenNewTabIcon,
  RefreshIcon,
  ShareIcon8,
  ThreeDotHorizontalIcon,
  ThumbUpFillIcon,
  ThumbUpIcon,
} from "@/components/shared/icons"
import { useToast } from "@/hooks"
import useCustomRouter from "@/hooks/useCustomRouter"
import { googleAnalytics } from "@/lib/gtag"
import useModalStore from "@/lib/store"
import { useAuth } from "@/providers/authContext"
import { useCreateTaskMutation, useGetTaskRunInfo } from "@/queries"
import { enableQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"
import { useAddTaskMutation } from "@/queries/tools/comfyui-recipe"
import { useGetRecipesMutation } from "@/queries/workspace/recipe"
import { useDeleteItemStore, useManagementErrorsStore, useRecoverItemStore } from "@/stores"
import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { Fragment, useState } from "react"
import { isMobileOnly } from "react-device-detect"
import { createMutation, createQuery } from "react-query-kit"
import IconValue from "../Gallery/ArticleItem/IconValue"
import ImageReaction from "../Gallery/ImageItem/ImageReaction"
import SignInRequiredButton from "../SignInRequiredButton"
import { ModalDetailImageType } from "./ImageDetailProvider"
import { recipeParamsWithWildcard, runAgainWorkflowParams } from "@/utils/task"
import CircularProgress from "@/components/CircularProgress"
import { DownloadImage } from "@/components/DownloadImage/DownloadImage"

interface ImageActionButtonsProps {
  image:
    | (ExploreImageFullDetail & {
        isDiscoverable?: boolean
        capabilities?: ImageRoleCapabilities
        folder?: ImageFolder | null
        workspace?: ImageWorkspace
        deletedAt?: string | null
        recipeTask?: ImageRecipeTask | null
        userFeedback?: UserFeedback
      })
    | (ImageFullDetail & {
        deletedAt?: string | null
      })
  handleClose: () => void
  setIsFocus: (isFocus: boolean) => void
  refetchImages?: () => void
  queryDetailKey: QueryKey
  queryListKey?: QueryKey
  isShareExplore?: boolean
  parentFolderId?: string
  modal?: ModalDetailImageType
  setModal?: (modal: ModalDetailImageType) => void
  recipeParams?: {
    recipeId?: string
    recipeType?: RecipeType
  }
}

export const useRecipeDetailMutate = createMutation<RecipeItem, { recipeId: string }, Error>({
  mutationFn: ({ recipeId }) => client.api.recipeControllerGetById(recipeId).then(res => res.data),
})

export const useTaskDetailMutate = createMutation<RecipeTask, { taskId: string }, Error>({
  mutationFn: ({ taskId }) => client.api.recipeTaskControllerGetById(taskId).then(res => res.data),
})

export const useTaskDetailQuery = createQuery<RecipeTask, { taskId: string }, Error>({
  primaryKey: "task-detail",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.recipeTaskControllerGetById(variables.taskId).then(res => res.data),
  use: [enableQueryMiddleware(i => !!i.taskId)],
})

export const useWildcardListMutate = createMutation<SearchWildcardResponse, { ids: string[] }, Error>({
  mutationFn: ({ ids }) => {
    return client.api
      .wildcardControllerList({
        take: 100,
        mode: WildcardFilterMode.Owned,
        ids,
        includePublic: true,
        searchTerm: "",
      })
      .then(res => res.data)
  },
})

const ImageActionButtons = ({
  image,
  parentFolderId,
  handleClose,
  recipeParams,
  setIsFocus,
  modal,
  setModal,
  refetchImages,
  isShareExplore,
  queryListKey,
  queryDetailKey,
}: ImageActionButtonsProps) => {
  const qc = useQueryClient()
  const { user, handleSignIn } = useAuth()
  const [isOpen, setIsOpen] = useState(false)

  const isDisabled = user?.uid !== image.owner?.uid

  const setSharingData = useModalStore(state => state.setSharingData)
  const setDeletingItem = useDeleteItemStore(state => state.setDeletingItem)
  const setRecoverItem = useRecoverItemStore(state => state.setRecoverItem)
  const toast = useToast()
  const setErrorState = useManagementErrorsStore(state => state.setErrorState)
  const [tempWorkflow, setTempWorkflow] = useState<WorkflowDetail | null>(null)
  const { openNewTab } = useCustomRouter()

  const { mutateAsync: mutateCreateTask, isPending: isPendingCreate } = useCreateTaskMutation({
    onSuccess: ({ id, recipeId }) => {
      setIsOpen(false)

      toast({
        status: "success",
        title: "Submitted! Your task is running",
        duration: 5000,
      })

      if (recipeId === "recipe-to-recipe") {
        setTimeout(() => {
          openNewTab(`/workspace/macros/${id}`)
        }, 1)
        return
      }

      setTimeout(() => {
        openNewTab(`/workspace/tools/recipes/${recipeId}`)
      }, 1)
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }

      if (err?.error === "Bad Request") {
        toast({ title: "Cannot Complete Request", message: [err.message], status: "error" })
      } else {
        toast({ title: "Error", message: [err.message], status: "error" })
      }
    },
  })

  const { mutateAsync: createTask, isPending: isCreatingTask } = useAddTaskMutation({
    onSuccess: ({ id, sdWorkflowId }) => {
      setIsOpen(false)

      setTimeout(() => {
        open(`/workspace/tools/comfyui/${sdWorkflowId}`, "_blank")
      }, 1)
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }
    },
  })

  const { mutateAsync: mutateGetWorkflow, isPending: isPendingGetWorkflow } = useWorkflowDetailMutate({
    onSuccess: data => {
      setTempWorkflow(data)

      handleRunAgainImageWithWorkflow(data)
    },
    onError: error => {
      toast({
        status: "error",
        title: "Error",
        message: [error.message],
      })
    },
  })

  const { mutateAsync: mutateGetRecipeDetail, isPending: isLoadingGetReipe } = useRecipeDetailMutate({
    onError: error => {
      toast({
        status: "error",
        title: "Error",
        message: [error.message],
      })
    },
  })
  const { mutateAsync: mutateGetTask, isPending: isLoadingGetTask } = useGetTaskRunInfo({
    onError: error => {
      toast({
        status: "error",
        title: "Error",
        message: [error.message],
      })
    },
  })
  const { mutateAsync: mutateGetRecipes, isPending: isMutatingRecipes } = useGetRecipesMutation()

  const handleRunAgainImageWithWorkflow = async (workflow: WorkflowDetail) => {
    if (!workflow || workflow.deletedAt) {
      toast({
        status: "error",
        title: "Macro is not available",
        message: ["This workflow is not available, please try another one."],
      })

      return
    }

    if (!workflow) return

    const recipes = await mutateGetRecipes({
      types: [RecipeType.Normal, RecipeType.Describe],
      recipeIds: workflow.params.map(i => i.recipeId),
      take: workflow.params.length,
    })

    for (let i = 0; i < workflow.params.length; i++) {
      const param = workflow.params[i]

      if (
        !recipes
          .filter(r => !r.deletedAt)
          .map(i => i.id)
          .includes(param.recipeId)
      ) {
        toast({
          status: "error",
          title: "Can not run workflow!",
          message: [`Recipe of step ${i + 1} is not found.`],
        })
        return
      }
    }

    const mappedParams = await runAgainWorkflowParams(workflow)

    mutateCreateTask({
      params: mappedParams as RecipeTaskChainParams[],
      recipeId: "recipe-to-recipe",
      folderId: workflow.folder.id,
      name: workflow.name,
    })
  }

  const handleRunAgainImageWithRecipe = async (
    recipe: RecipeItem,
    image: {
      id: string
      metadata: ImageMetadataItem[] | null
      prompt?: string | null
      negativePrompt: string
      folder?: ImageFolder | null
      recipe: ImageRecipe | null
      recipeTask?: ImageRecipeTask | null
    },
  ) => {
    if (!recipe || recipe.deletedAt) {
      toast({
        status: "error",
        title: "Recipe is not available",
        message: ["This recipe is not available, please try another one."],
      })

      return
    }

    const params = Object.values(image.metadata ?? {}).reduce((acc, value) => {
      acc[value.key] = value.value
      return acc
    }, {})

    const recipeInputs = recipe.steps?.map(step => step.inputs).flat()
    const hasWildcardField = recipeInputs.some(input => input.allowWildcard)

    let newParams = {
      ...params,
      prompt: image.prompt,
      negativePrompt: image.negativePrompt,
    } as Record<string, string>

    if (hasWildcardField) {
      newParams = await recipeParamsWithWildcard(newParams, recipeInputs)
    }

    if (image.recipe?.id === COMFY_RECIPE_ID) {
      const task = await mutateGetTask({ taskId: image.recipeTask?.id ?? "" })

      if (!task) return

      createTask({
        params: task.params as RecipeTaskChainParams[],
        recipeId: COMFY_RECIPE_ID,
        sdWorkflowId: task.sdWorkflowId,
      })

      return
    }

    mutateCreateTask({
      folderId: image.folder?.id ?? "",
      recipeId: image.recipe?.id ?? "",
      params: newParams,
    })
  }

  const handleRunAgain = async () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "image",
        action: "Run Again Image",
        image_id: image.id,
        image_name: image.name,
      },
    })

    if (!image) return

    if (image.workflow) {
      if (tempWorkflow) {
        handleRunAgainImageWithWorkflow(tempWorkflow)

        return
      }

      await mutateGetWorkflow({ workflowId: image.workflow.id })

      return
    }

    if (image.recipe) {
      const recipe = await mutateGetRecipeDetail({ recipeId: image.recipe.id })

      handleRunAgainImageWithRecipe(recipe, image)

      return
    }
  }

  const { mutate: mutateDownloadImage } = useMutation({
    mutationFn: () => client.api.imageControllerDownloadImage({ imageIds: [image.id] }),
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: queryDetailKey })
    },
  })

  const router = useCustomRouter()

  const { mutate: mutateShare } = useMutation({
    mutationFn: () =>
      client.api.imageControllerIncreaseImageShareCount({
        imageIds: [image.id],
      }),
  })

  const handleNewTab = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "image",
        action: "Open in new tab",
        image_id: image.id,
        image_name: image.name,
      },
    })

    setIsOpen(false)

    setTimeout(() => {
      openNewTab(`${window.location.href}?redirect=${router.asPath}`)
    }, 150)
  }

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setIsFocus(true)

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "image",
        action: "Delete Image",
        image_id: image.id,
        image_name: image.name,
      },
    })

    setDeletingItem({
      id: image.id,
      deletedAt: image.deletedAt,
      folderId: parentFolderId ?? image.folder?.id,
      recipeParams,
      queryKey: queryListKey,
      type: "image",
      onClose: () => {
        refetchImages?.()
        handleClose()
      },
      onRemoveFocus: () => setIsFocus(false),
    })
    setIsOpen(false)
  }

  const handleRecovery = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setIsFocus(true)

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "image",
        action: "Recover Image",
        image_id: image.id,
        image_name: image.name,
      },
    })

    setIsOpen(false)
    setRecoverItem({
      ids: [image.id],
      type: "image",
      deletedAt: image.deletedAt,
      parentFolderId: parentFolderId ?? image.folder?.id,
      onClose: handleClose,
      onRemoveFocus: () => setIsFocus(false),
    })
  }

  const handleShare = async () => {
    if (isShareExplore) {
      const isSignIn = await handleSignIn()

      if (!isSignIn) return
    }

    setIsFocus(true)

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "image",
        action: "Share Image",
        image_id: image.id,
        image_name: image.name,
      },
    })

    setSharingData({
      id: image.id,
      name: image.name ?? image.id,
      type: "image",
      link: `${window.location.origin}/${isShareExplore ? "workspace" : "explore"}/images/${image.id}`,
      creator: image.owner,
      folderId: parentFolderId ?? image.folder?.id,
      url: image.url,
      workspaceId: image.workspace?.id,
      onClose: () => setIsFocus(false),
    })
    setIsOpen(false)
    mutateShare()
  }

  const handleFeedback = async () => {
    const isSignIn = await handleSignIn()

    if (!isSignIn || !!image.userFeedback) return

    setModal?.("FEEDBACK")
  }

  const isMutating =
    isPendingCreate ||
    isPendingGetWorkflow ||
    isLoadingGetReipe ||
    isCreatingTask ||
    isLoadingGetTask ||
    isMutatingRecipes

  const actions = [
    {
      label: "Run Again",
      onClick: handleRunAgain,
      icon: <RefreshIcon width={16} height={16} className={"text-atherGray-300"} />,
      isLoading: isMutating,
      visible:
        !isDisabled &&
        (image.workflow || image.recipe) &&
        !image.deletedAt &&
        image.recipe?.id !== COMFY_RECIPE_ID &&
        image.recipe?.id !== TRY_STYLE_RECIPE &&
        image.moderationRating !== ContentModerationRating.HardcoreNsfw,
    },
    {
      label: "Share",
      onClick: handleShare,
      icon: <ShareIcon8 width={16} height={16} className={"text-atherGray-300"} />,
      visible: isMobileOnly,
    },
    {
      label: "Recover",
      onClick: handleRecovery,
      icon: <RefreshIcon width={16} height={16} className={"text-atherGray-300 rotate-180"} />,
      visible: image.deletedAt && image.capabilities?.canRecover,
    },
    {
      label: "Open in new tab",
      onClick: handleNewTab,
      icon: <OpenNewTabIcon width={16} height={16} className="text-atherGray-300" />,
      visible: true,
    },
    {
      label: "Delete",
      onClick: handleDelete,
      icon: <DeleteIcon width={16} height={16} className="text-red-500" />,
      visible: image.capabilities?.canDelete,
    },
  ]

  return (
    <div className="flex items-center w-full">
      <div className="flex text-sm space-x-2 flex-1">
        <ImageReaction
          className="text-atherGray-300"
          showHoverAttributes={false}
          style={{
            background: "transparent",
          }}
          image={image}
        />
        <DownloadImage src={image.url} name={image.name}>
          {({ onClick, isLoading, progress }) => (
            <IconButton
              className="flex items-center p-1 text-atherGray-300 bg-transparent"
              title="Download"
              colorScheme="secondary"
              disabled={isLoading}
              onClick={e => {
                e.stopPropagation()

                googleAnalytics.handleCategoryEvent({
                  action: "click",
                  params: {
                    tab_name: "image",
                    action: "Download Image",
                    image_id: image.id,
                    image_name: image.name,
                  },
                })
                onClick()
                mutateDownloadImage()
              }}
            >
              {image.owner?.uid === "anonymous" ? (
                <>
                  <DownloadIcon1 width={16} height={16} />
                  Download
                </>
              ) : (
                <IconValue
                  className="text-atherGray-300"
                  icon={
                    isLoading ? (
                      <CircularProgress color="#682DDD" className="size-4" borderWidth={3} progress={progress} />
                    ) : (
                      <DownloadIcon1 width={16} height={16} className={"text-atherGray-300"} />
                    )
                  }
                  value={image.metric?.downloadCount ?? 0}
                />
              )}
            </IconButton>
          )}
        </DownloadImage>

        {!isShareExplore && (
          <>
            <IconButton
              className="md:flex items-center p-1 px-2 text-atherGray-300 bg-transparent hidden"
              title="Open in new tab"
              colorScheme="secondary"
              onClick={handleNewTab}
            >
              <OpenNewTabIcon width={16} height={16} />
            </IconButton>
            <IconButton
              className="flex md:hidden items-center p-1 px-2 text-atherGray-300 bg-transparent"
              title="Share"
              colorScheme="secondary"
              onClick={handleShare}
            >
              <ShareIcon8 width={16} height={16} />
            </IconButton>
          </>
        )}
        {isShareExplore && !isDisabled && !image.deletedAt && (
          <Fragment>
            <IconButton
              className={classNames("flex items-center py-1 px-2 text-atherGray-300 bg-transparent", {
                "bg-atherGray-0 rounded-full hover:bg-atherGray-0 active:bg-atherGray-0 !cursor-default":
                  !!image.userFeedback,
              })}
              title={"Rating"}
              colorScheme="secondary"
              onClick={handleFeedback}
            >
              {image.userFeedback ? (
                <ThumbUpFillIcon
                  width={16}
                  height={16}
                  className={classNames({
                    "text-red-500 rotate-180": image.userFeedback?.rating === FeedbackRating.NotGood,
                    "text-atherPurple-500": image.userFeedback?.rating === FeedbackRating.Good,
                  })}
                />
              ) : (
                <ThumbUpIcon width={16} height={16} className={"text-atherGray-300"} />
              )}
            </IconButton>
            <Modal
              className="max-w-fit p-0"
              bodyClassName="p-0"
              closeButtonClassName="absolute top-2 right-2 modal-content-child"
              isOpen={modal === "FEEDBACK"}
              onClose={() => setModal?.(null)}
            >
              <FeedbackPopup
                isModal
                debounceIsOpen
                entityId={image.id}
                entityType={EntityType.IMAGE}
                onSuccess={() => setModal?.(null)}
                className="w-[20rem]"
              />
            </Modal>
          </Fragment>
        )}
        {image.owner?.uid !== "anonymous" && isShareExplore && (
          <Menu
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            placement="bottom-end"
            listClassName="bg-atherGray-850 border border-atherGray-800 text-whiteAlpha-900 overflow-hidden rounded-2xl p-0 text-sm shadow-md"
            trigger={
              <IconButton
                title="More"
                className="flex items-center py-1 px-2 h-full text-atherGray-300 bg-transparent"
                colorScheme="secondary"
              >
                <ThreeDotHorizontalIcon width={16} height={16} />
              </IconButton>
            }
          >
            <div className="py-2 overflow-hidden w-full transform-gpu">
              {actions.map(
                (action, index) =>
                  action.visible && (
                    <IconButton
                      key={index}
                      isLoading={action.isLoading}
                      title={action.label}
                      onClick={action.onClick}
                      colorScheme="secondary"
                      className={classNames("flex justify-start w-full rounded-none p-2 bg-atherGray-850", {
                        "justify-center": action.isLoading,
                      })}
                      leftIcon={action.icon}
                    >
                      {action.label}
                    </IconButton>
                  ),
              )}
            </div>
          </Menu>
        )}
      </div>
      {isShareExplore ? (
        <Fragment>
          {!image.deletedAt && image.capabilities?.canShare && (
            <IconButton
              colorScheme="secondary"
              className="font-semibold text-xs hidden md:flex"
              onClick={e => {
                e.stopPropagation()
                handleShare()
              }}
            >
              <ShareIcon8 className="mr-1" width={16} height={16} />
              Share
            </IconButton>
          )}
          {image.owner?.uid === "anonymous" && (
            <SignInRequiredButton
              className="max-w-[5.5rem] rounded-lg bg-atherPurple-500 p-1.5 text-sm font-semibold text-[#EFE8FD]"
              text="Sign In"
            />
          )}
        </Fragment>
      ) : (
        <IconButton
          colorScheme="secondary"
          className="font-semibold text-xs hidden md:flex ml-2"
          onClick={e => {
            e.stopPropagation()
            handleShare()
          }}
        >
          <ShareIcon8 className="mr-1" width={16} height={16} />
          Share
        </IconButton>
      )}
    </div>
  )
}

export default ImageActionButtons
