import client from "@/api/client"
import { PinnedItemType, PinnedItems, RecentStyleItem, SearchSDStyleResponse } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { useWorkspaceStyleInfiniteQuery } from "./useGetStyleInfiniteQuery"
import { useRecentStylesInfiniteQuery } from "@/components/Workspace/RecentV2/RecentStyles"
import { useWorkspacePinInfiniteQuery } from "@/queries/workspace"

interface DeleteStyleMutationVariables {
  ids: string[]
  deleteAt: string | null
}

export const useDeleteStyleMutation = createMutation({
  mutationFn: async ({ ids, deleteAt }: DeleteStyleMutationVariables) => {
    if (deleteAt) {
      return client.api
        .sdStyleControllerFullyDeleteStyles({
          styleIds: ids,
        })
        .then(res => res.data)
    }

    return client.api
      .sdStyleControllerSoftDeleteStyles({
        styleIds: ids,
      })
      .then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids } }) => {
        // Update style list
        const styleListKey = useWorkspaceStyleInfiniteQuery.getKey()
        const styleListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchSDStyleResponse, number>>({
          queryKey: styleListKey,
        })

        styleListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                styles: page.data.filter(style => {
                  return !ids.includes(style.id)
                }),
              }
              updatedPage.total = updatedPage.styles.length
              return updatedPage
            }),
          }
          queryClient.setQueryData(key, updatedData)
        })

        // Update styles recent list
        const stylesRecentListKey = useRecentStylesInfiniteQuery.getKey()

        const stylesRecentListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecentStyleItem[], number>>({
          queryKey: stylesRecentListKey,
        })

        if (stylesRecentListQueriesDataEntries) {
          stylesRecentListQueriesDataEntries.forEach(([key, stylesRecentListData]) => {
            if (stylesRecentListData) {
              const updatedData = {
                ...stylesRecentListData,
                pages: stylesRecentListData.pages.map(page => {
                  return [...page.filter(recent => !ids.includes(recent.style.id))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update style pinned list
        const stylePinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.STYLE,
        })

        const stylePinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: stylePinnedListKey,
        })

        if (stylePinnedListQueriesDataEntries) {
          stylePinnedListQueriesDataEntries.forEach(([key, stylePinnedListData]) => {
            if (stylePinnedListData) {
              const updatedData = {
                ...stylePinnedListData,
                pages: stylePinnedListData.pages.map(page => {
                  return [...page.filter(item => !ids.includes(item.style?.id ?? ""))]
                }),
              }

              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
  ],
})
