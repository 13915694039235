import { PinnedItemType, SDWorkflowDetail } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import {
  BookIcon4,
  CommentIcon,
  DeleteIcon,
  DownloadIcon,
  Layer3DIcon,
  LayerAddIcon,
  MoreHorizontalIcon,
  PinIcon,
  PotIcon2,
  SaveIcon,
  ScienceIcon,
  SettingIcon,
  ShareIcon8,
  SubtitleIcon,
  UnPinIcon,
  IconProps,
  FlowSquareIcon9,
} from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import useModalStore from "@/lib/store"
import { useAuth } from "@/providers/authContext"
import React, { useState } from "react"
import { ModeComfy } from "./ComfyUIDetail"
import classNames from "classnames"
import { useDeleteItemStore } from "@/stores"
import { useDuplicateComfyUiWorkflowMutation } from "@/queries/tools/comfyui-recipe/updateComfyUiRecipeMutations"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useToast } from "@/hooks"
import { usePinMutation } from "@/queries"
import Popover from "@/components/Popover"
import FolderActionRow from "@/components/Workspace/FolderActions/FolderActionRow"
import { isMobile } from "react-device-detect"
import LoadingIcon from "@/components/LoadingIcon"
import Tooltip from "@/components/Tooltip"

interface ComfyUIActionsProps {
  onToggleConvertRecipe: () => void
  onTogglePreview: () => void
  onToggleComments: () => void
  onDownloadGraph: () => void
  onToggleTasks: () => void
  onToggleNodeLibrary: () => void
  onTogglePromptLibrary: () => void
  onToggleSettings: () => void
  onToggleTaskTracker: () => void
  onTogglePublish: () => void
  onSave: () => void
  workflow?: SDWorkflowDetail
  mode: ModeComfy | null
  isAutoSaving: boolean
  hasPendingSave: boolean
  isSettingsOpen: boolean
  isTaskOnGoing: boolean
  isLibraryOpen: boolean
}

interface ActionProps {
  icon: React.ComponentType<IconProps>
  label: string
  onClick: () => void
  enable: boolean
  highlight: boolean
  visible: boolean
  loading?: boolean
  isLoading?: boolean
}

const ComfyUIActions = ({
  workflow,
  onToggleComments,
  isAutoSaving,
  mode,
  onToggleTasks,
  onDownloadGraph,
  onToggleSettings,
  onToggleNodeLibrary,
  onTogglePromptLibrary,
  isTaskOnGoing,
  onSave,
  hasPendingSave,
  isSettingsOpen,
  isLibraryOpen,
  onTogglePublish,
  onToggleConvertRecipe,
  onTogglePreview,
}: ComfyUIActionsProps) => {
  const { user } = useAuth()
  const setSharingData = useModalStore(state => state.setSharingData)
  const setDeletingItem = useDeleteItemStore(state => state.setDeletingItem)
  const isOwner = workflow?.creator?.uid === user?.uid
  const router = useCustomRouter()
  const toast = useToast()

  const handleShare = () => {
    if (!workflow) return

    googleAnalytics.event({
      action: "click",
      category: "comfyui_detail",
      label: `share_comfyui_workflow`,
      params: {
        comfyui_id: workflow.id,
        comfyui_name: workflow.name,
        pathname: window.location.pathname,
      },
    })

    setSharingData({
      id: workflow.id,
      name: workflow.name,
      link: `${window.location.origin}/workspace/tools/comfyui/${workflow.id}`,
      type: "comfyui-workflow",
      creator: workflow.creator,
      workspaceId: workflow.workspaceId,
      url: "",
    })
  }

  const handleDelete = () => {
    if (!workflow) return

    googleAnalytics.event({
      action: "click",
      category: "comfyui_detail",
      label: `delete_comfyui_workflow`,
      params: {
        comfyui_id: workflow.id,
        comfyui_name: workflow.name,
        pathname: window.location.pathname,
      },
    })

    setDeletingItem({
      id: workflow.id,
      type: "comfyui-workflow",
      deletedAt: workflow.deletedAt,
      onClose: () => {
        router.push(workflow.isDraft ? "/workspace/tools/comfyui?tab=drafts" : "/workspace/tools/comfyui")
      },
    })
  }

  const { mutate: mutateDuplicate, isPending: isDuplicating } = useDuplicateComfyUiWorkflowMutation({
    onSuccess(data) {
      setTimeout(() => {
        router.openNewTab(`/workspace/tools/comfyui/${data.id}`)
      }, 1)
    },
    onError() {
      toast({
        status: "error",
        title: "Error",
        message: ["Something went wrong"],
      })
    },
  })
  const handleDuplicate = () => {
    if (!workflow) return
    googleAnalytics.event({
      action: "click",
      category: "comfyui_detail",
      label: `duplicate_comfyui_workflow`,
      params: {
        comfyui_id: workflow.id,
        comfyui_name: workflow.name ?? name,
        pathname: window.location.pathname,
      },
    })
    mutateDuplicate({ workflowId: workflow.id })
  }

  const { mutate: mutatePinTask, isPending: isPendingPinTask } = usePinMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: `ComfyUI ${workflow?.pinned ? "unpinned" : "pinned"}`,
      })
    },
  })

  const handlePin = () => {
    if (!workflow) return

    googleAnalytics.event({
      action: "click",
      category: "comfyui_detail",
      label: `${workflow?.pinned ? "unpin" : "pin"}_comfyui_workflow`,
      params: {
        comfyui_id: workflow.id,
        comfyui_name: workflow.name,
        pathname: window.location.pathname,
      },
    })

    mutatePinTask({ ids: [workflow?.id], type: PinnedItemType.SD_WORKFLOW, isPinned: !workflow.pinned })
  }

  const createAction = (
    icon: React.ComponentType<IconProps>,
    label: string,
    onClick: () => void,
    options: Partial<ActionProps> = {},
  ) => ({
    icon,
    label,
    onClick,
    enable: true,
    highlight: false,
    visible: true,
    ...options,
  })

  const actions = [
    createAction(Layer3DIcon, "Tasks History", onToggleTasks, {
      enable: workflow?.capabilities?.canView,
      highlight: mode === "tasks-history",
    }),
    createAction(ScienceIcon, "Published to Recipe", onTogglePublish, {
      enable: isOwner,
      highlight: mode === "publish-recipe",
    }),
    createAction(PotIcon2, "Convert to Recipe", onToggleConvertRecipe, {
      enable: isOwner,
      highlight: mode === "convert-recipe",
    }),
    createAction(CommentIcon, "Comment", onToggleComments, {
      enable: workflow?.capabilities?.canView,
      highlight: mode === "comment",
    }),
    createAction(ShareIcon8, "Share", handleShare, {
      enable: workflow?.capabilities?.canShare && !workflow?.isDraft,
    }),
  ]

  const actions2 = [
    createAction(SaveIcon, "Save", onSave, {
      loading: isAutoSaving,
      highlight: hasPendingSave,
      enable: workflow?.capabilities?.canUpdate && hasPendingSave,
      visible: workflow?.capabilities?.canUpdate,
    }),
    createAction(BookIcon4, "Prompt Library", onTogglePromptLibrary, {
      visible: workflow?.capabilities?.canUpdate,
      highlight: mode === "prompt-library",
    }),
    createAction(FlowSquareIcon9, "Node library", onToggleNodeLibrary, {
      visible: workflow?.capabilities?.canUpdate,
      highlight: isLibraryOpen,
    }),
    createAction(SettingIcon, "Setting", onToggleSettings, {
      enable: workflow?.capabilities?.canUpdate,
      visible: workflow?.capabilities?.canUpdate,
      // highlight: isSettingsOpen,
    }),
  ]

  const actionsMore = [
    createAction(SubtitleIcon, "Preview", onTogglePreview, {
      enable: isOwner,
      highlight: mode === "info",
    }),
    createAction(LayerAddIcon, "Duplicate", handleDuplicate, {
      isLoading: isDuplicating,
      enable: workflow?.capabilities?.canView,
    }),
    createAction(workflow?.pinned ? UnPinIcon : PinIcon, workflow?.pinned ? "Unpin" : "Pin", handlePin, {
      enable: workflow?.capabilities?.canView,
      isLoading: isPendingPinTask,
    }),
    createAction(DownloadIcon, "Download", onDownloadGraph, {
      enable: workflow?.capabilities?.canView,
    }),
    createAction(DeleteIcon, "Delete", handleDelete, {
      enable: workflow?.capabilities?.canDelete,
      visible: workflow?.capabilities?.canDelete,
    }),
  ]

  if (isMobile) return null

  const renderActionButton = (action: ActionProps, index: number) => (
    <Tooltip
      side="right"
      trigger={
        <IconButton
          key={index}
          disabled={!action.enable}
          onClick={action.onClick}
          className="flex-col font-normal p-2 min-h-0"
          colorScheme="transparent"
          loadingClassName="bg-atherGray-900"
          isLoading={action.loading}
        >
          <div
            className={classNames("p-1 rounded-lg relative", {
              "bg-atherPurple-500": action.highlight,
            })}
          >
            {isTaskOnGoing && action.label === "Tasks History" && (
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex size-[5rem] justify-center items-center overflow-hidden">
                <LoadingIcon width={40} height={40} />
              </div>
            )}
            <action.icon
              width={20}
              height={20}
              className={classNames({
                "text-atherGray-0": action.highlight,
                "text-atherGray-300": !action.highlight,
              })}
            />
          </div>
        </IconButton>
      }
    >
      <span className="text-sm">{action.label}</span>
    </Tooltip>
  )

  return (
    <div className="w-full flex-1 flex flex-col overflow-auto z-[1]">
      <div className="text-atherGray-300 flex flex-col items-center flex-1">
        {actions.map(renderActionButton)}
        <MoreActionsPopover actions={actionsMore} />
      </div>
      <div className="text-atherGray-300 flex flex-col items-center">
        {actions2.filter(a => a.visible).map(renderActionButton)}
      </div>
    </div>
  )
}

const MoreActionsPopover = ({ actions }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Popover
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      align="start"
      trigger={
        <IconButton
          className="flex-col font-normal p-2 min-h-0"
          colorScheme="transparent"
          loadingClassName="bg-atherGray-900"
        >
          <div
            className={classNames("p-1 rounded-lg", {
              "bg-atherPurple-500": isOpen,
            })}
          >
            <MoreHorizontalIcon
              width={20}
              height={20}
              className={classNames({
                "text-atherGray-0": isOpen,
                "text-atherGray-300": !isOpen,
              })}
            />
          </div>
          <span className="text-[0.65rem] leading-4">More</span>
        </IconButton>
      }
    >
      <div className="bg-atherGray-900 border border-atherGray-800 rounded-2xl w-[12rem] translate-x-[4rem] absolute top-0 -translate-y-[3.5rem] p-2 space-y-1">
        {actions
          .filter(a => a.visible)
          .map(action => (
            <FolderActionRow
              key={action.label}
              isLoading={action.isLoading}
              className={classNames({
                "bg-atherGray-800 text-atherGray-0 hover:bg-atherGray-800": action.highlight,
              })}
              iconLeft={
                <action.icon
                  width={20}
                  height={20}
                  className={classNames({
                    "text-red-500": action.label === "Delete",
                    "text-atherGray-0": action.highlight,
                  })}
                />
              }
              onClick={e => {
                e.stopPropagation()
                action.onClick()
                setIsOpen(false)
              }}
              isDisabled={!action.enable}
            >
              {action.label}
            </FolderActionRow>
          ))}
      </div>
    </Popover>
  )
}

export default ComfyUIActions
