import "@farcaster/auth-kit/styles.css"
import "@solana/wallet-adapter-react-ui/styles.css"

import Web3ModalProvider from "@/context"
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base"
import { ConnectionProvider, WalletProvider, useWallet } from "@solana/wallet-adapter-react"
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui"
import { clusterApiUrl } from "@solana/web3.js"
import { AuthKitProvider } from "@farcaster/auth-kit"
import { FC, PropsWithChildren, useEffect } from "react"
import {
  Coin98WalletAdapter,
  CoinbaseWalletAdapter,
  MathWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
  TrustWalletAdapter,
} from "@solana/wallet-adapter-wallets"
import { clientDomain, clientUrl } from "@/constants"
import { useAuth } from "@/providers/authContext"

import { googleAnalytics } from "@/lib/gtag"
import { useWalletInfo } from "@web3modal/wagmi/react"

export const isProduction = process.env.NEXT_PUBLIC_ENV === "production"

const { setProperty } = googleAnalytics

// solana wallet
const network = isProduction ? WalletAdapterNetwork.Mainnet : WalletAdapterNetwork.Devnet
const endpoint = clusterApiUrl(network)
const wallets = [
  new PhantomWalletAdapter(),
  new SolflareWalletAdapter(),
  new CoinbaseWalletAdapter(),
  new MathWalletAdapter(),
  new TrustWalletAdapter(),
  new TorusWalletAdapter(),
  new Coin98WalletAdapter(),
]

const config = {
  relay: "https://relay.farcaster.xyz",
  rpcUrl: "https://mainnet.optimism.io",
  domain: clientDomain,
  siweUri: clientUrl,
}

const SetGAProperties: FC<PropsWithChildren> = ({ children }) => {
  const { userInfoQuery, loading } = useAuth()

  const wallet = useWallet()
  const solanaWallet = wallet.publicKey?.toString()

  const { walletInfo } = useWalletInfo()
  const evmWallet = walletInfo?.address

  useEffect(() => {
    if (loading) return

    const timer = setTimeout(() => {
      if (userInfoQuery?.data) {
        const user_id = userInfoQuery.data.uid
        if (user_id) {
          setProperty({
            user_id,
            user_username: userInfoQuery.data.username,
            user_name: userInfoQuery.data.name,
            user_email: userInfoQuery.data.email,
            solana_wallet_address: solanaWallet,
            eth_wallet_address: evmWallet,
            credit_balance: userInfoQuery.data.availableCredits,
          })
        }
        return
      }
      setProperty({ user_id: "anonymous" })
    }, 250)

    return () => clearTimeout(timer)
  }, [userInfoQuery, loading, solanaWallet, evmWallet])

  return <>{children}</>
}

const LazyProviders: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <Web3ModalProvider>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
            <WalletModalProvider>
              <AuthKitProvider config={config}>
                <SetGAProperties>{children}</SetGAProperties>
              </AuthKitProvider>
            </WalletModalProvider>
          </WalletProvider>
        </ConnectionProvider>
      </Web3ModalProvider>
    </>
  )
}

export default LazyProviders
