import { FolderWithChildren } from "@/api/sdk"
import { cdnUrl, devCdnUrl } from "@/constants"
export * from "./detect"

export const findFolderByIdRecursive = (folders: FolderWithChildren[], id: string): FolderWithChildren | null => {
  for (const folder of folders) {
    if (folder.id === id) {
      return folder
    }

    const foundFolder = findFolderByIdRecursive(folder.children, id)
    if (foundFolder) {
      return foundFolder
    }
  }

  return null
}

export const isAcceptedEmail = (email: string) => {
  return /^[a-zA-Z0-9._%+-]+@(atherlabs\.com|artventure\.ai|autoagentx\.com|protogaia\.com)$/.test(email)
}

export const isEmail = (email: string) => {
  return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
    email,
  )
}

export const camelCaseToProperCase = (input: string): string =>
  input
    .replace(/([A-Z])/g, " $1") // Insert a space before each capital letter
    .replace(/^./, firstCharacter => firstCharacter.toUpperCase()) // Capitalize the first character
    .trim() // Remove any leading or trailing whitespace

// capitalize first letter of every words in a string
export const capitalize = (str: string) => {
  return str
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

export const currency = (
  amount: number,
  prefix = "",
  options: {
    maximumFractionDigits?: number
    minimumFractionDigits?: number
    isConvert?: boolean
  } = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    isConvert: true,
  },
) => {
  const power = Math.pow(10, options.maximumFractionDigits || 2)
  const amountFloor = Math.round(amount * power) / power
  if (options.isConvert) {
    if (amount >= Math.pow(10, 9)) {
      const rNumber = Number((amount / Math.pow(10, 9)).toFixed(2))
      const rAmountFloor = Math.round(rNumber * power) / power
      return prefix + rAmountFloor.toLocaleString(undefined, { ...options }) + "B"
    }
    if (amount >= Math.pow(10, 6)) {
      const rNumber = Number((amount / Math.pow(10, 6)).toFixed(2))
      const rAmountFloor = Math.round(rNumber * power) / power
      return prefix + rAmountFloor.toLocaleString(undefined, { ...options }) + "M"
    }
    if (amount >= Math.pow(10, 3)) {
      const rNumber = Number((amount / Math.pow(10, 3)).toFixed(2))
      const rAmountFloor = Math.round(rNumber * power) / power
      return prefix + rAmountFloor.toLocaleString(undefined, { ...options }) + "K"
    }
  }
  return prefix + amountFloor.toLocaleString(undefined, { ...options })
}

export const encoded = (str: string) => {
  const freq = [] as { char: string; count: number }[]

  // 1. collect char frequency for each occurrence
  for (const char of str) {
    const last = freq[freq.length - 1] as { char: string; count: number } | undefined

    if (!last || last.char !== char) {
      freq.push({ char, count: 1 })
    } else {
      last.count++
    }
  }

  // 2. stringify
  return freq.reduce((result, { char, count }) => `${result}${count}${char}`, "")
}

export const decode = (str: string) => {
  const freq = [] as { char: string; count: number }[]

  // 1. collect char frequency for each occurrence
  for (let i = 0; i < str.length; i += 2) {
    freq.push({ char: str[i + 1], count: Number(str[i]) })
  }

  // 2. stringify
  return freq.reduce((result, { char, count }) => `${result}${char.repeat(count)}`, "")
}

export const zeroPad = (num: number, places: number) => String(num).padStart(places, "0")

export const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }

  return outputArray
}

export const isCheckDomainUrl = (url: string) => {
  const domains = [devCdnUrl, cdnUrl]

  if (!url) return false

  for (const domain of domains) {
    if (url.includes(domain)) {
      return true
    }
  }

  return false
}

export const isLinkUrlImage = (url: string) => {
  if (!url) return false

  const regex = /(https?:\/\/.*\.(?:png|jpg|webp|jpeg))/i

  return regex.test(url)
}
