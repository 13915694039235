import "@/styles/globals.css"
import "react-toastify/dist/ReactToastify.css"
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/pagination"

import _ from "lodash"
import { NextPage } from "next"
import type { AppProps } from "next/app"
import Head from "next/head"
import dynamic from "next/dynamic"
import { Montserrat } from "next/font/google"
import { Fragment, useEffect } from "react"

import { cdnPublicFolderUrl } from "@/constants"
import useTelegramInitData from "@/hooks/telegram/useTelegramInitData"
import { isProduction } from "@/components/Layouts/LazyProviders"
import { QueryProvider } from "@/providers/QueryProvider"

const AppProviders = dynamic(() => import("../providers/default"))
const LoadingScreen = dynamic(() => import("@/components/LoadingScreen"))

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactNode) => React.ReactNode
  showLoading?: () => boolean
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export const montserrat = Montserrat({
  weight: ["300", "400", "500", "600", "700"],
  style: ["italic", "normal"],
  display: "swap",
  subsets: ["latin-ext", "latin", "vietnamese"],
})

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const telegramInitData = useTelegramInitData()

  const renderWithLayout = () => {
    const withLayout = Component.getLayout || (page => page)

    return (
      <AppProviders>
        <LoadingScreen showLoading={Component.showLoading?.()}>
          {withLayout(<Component {...pageProps} />)}
        </LoadingScreen>
      </AppProviders>
    )
  }

  useEffect(() => {
    if (!telegramInitData || _.isEmpty(telegramInitData)) return

    if (!isProduction) {
      // test open wallet connect on telegram mini app
      // test on dev first
      window.open = (function (open) {
        return function (url, _, features) {
          return open.call(window, url, "_blank", features)
        }
      })(window.open)
      console.log("Modified window.open function to test on telegram mini app")
    }
  }, [telegramInitData])

  useEffect(() => {
    const handleRecaptcha = () => {
      let head = document.getElementsByTagName("head")[0]
      let script = document.createElement("script")
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`
      script.defer = true
      head.appendChild(script)
    }

    const handleServiceWorker = () => {
      if (navigator && "serviceWorker" in navigator) {
        navigator.serviceWorker
          .register("/sw.js")
          .then(registration => {
            registration!.onupdatefound = () => {
              const installingSw = registration.installing
              installingSw!.onstatechange = () => {
                if (installingSw!.state === "installed") {
                  if (navigator?.serviceWorker?.controller) {
                    window.location.reload()
                  }
                }
              }
            }
          })
          .catch(err => console.log("Service Worker registration failed: ", err))
      }
    }

    const handleViewPort = () => {
      const vh = window.visualViewport ? window.visualViewport?.height * 0.01 : window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)

      window.addEventListener("resize", () => {
        // We execute the same script as before
        const vh = window.visualViewport ? window.visualViewport?.height * 0.01 : window.innerHeight * 0.01
        document.documentElement.style.setProperty("--vh", `${vh}px`)
      })
    }

    const io = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          //handle recaptcha
          handleRecaptcha()

          //handle viewport
          handleViewPort()

          //handle service worker
          handleServiceWorker()
        }
      },
      {
        root: document.body,
        rootMargin: "0px",
        threshold: 1,
      },
    )

    io.observe(document.getElementById("__next") as HTMLElement)
  }, [])

  return (
    <Fragment>
      <Head>
        <meta charSet="utf-8" />
        <link rel="icon" href={`${cdnPublicFolderUrl}/logomark.png`} />
        <meta name="viewport" content="width=device-width, initial-scale=1 maximum-scale=1 viewport-fit=cover" />
        <meta name="theme-color" content="#000000" />
        <meta name="background-color" content="#000000" />
        {process.env.NEXT_PUBLIC_ENV === "develop" && (
          <>
            <meta name="robots" content="noindex, nofollow" />
            <meta name="google-site-verification" content="K5bEFMiI-KWVrYKQuHnjvjjmrpIiV191Qq7wf5DxOO4" />
          </>
        )}
        <link rel="manifest" href="/manifest.json" crossOrigin="use-credentials" />
        <link rel="apple-touch-icon" href={`${cdnPublicFolderUrl}/logomark.png`} />
        <title>GAIA</title>
      </Head>
      <QueryProvider>{renderWithLayout()}</QueryProvider>
    </Fragment>
  )
}
