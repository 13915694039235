import client from "@/api/client"
import {
  ChatRoomInviteStatus,
  ChatRoomItem,
  EntityType,
  GetChatRoomsResult,
  GetNotificationsResponse,
  GrantAccessAction,
  NotificationType,
  NotificationWithStatus,
  SharingRole,
  WorkspaceInviteStatus,
} from "@/api/sdk"
import { useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useSwitchWorkspaceMutation } from "@/queries"
import { useWorkspaceStyleInfiniteQuery } from "@/queries/tools/style/useGetStyleInfiniteQuery"
import { InfiniteData, useMutation, useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { formatDistanceToNowStrict } from "date-fns"
import useCustomRouter from "@/hooks/useCustomRouter"
import { memo, useRef, useState } from "react"
import { FaChevronDown } from "react-icons/fa"
import UserCreated from "../Explore/Gallery/UserCreated"
import { useAcceptInviteMutation, useWorkspaceRoomsInfiniteQuery } from "@/queries/workspace/room"
import uniqBy from "lodash/uniqBy"
import IconButton from "../IconButton"
import ImageWebpComponent from "../ImageWebpComponent"
import { Menu } from "../MenuFloating"
import { BillingIcon, MessageMultiIcon } from "../shared/icons"
import { NotificationResponseType } from "./NotificationDrawer"

export interface NotificationItemProps {
  notification: NotificationWithStatus
  isToast?: boolean
  onClose?: () => void
}

const NotificationItem = ({ notification, isToast = false, onClose }: NotificationItemProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState<"view" | "edit" | undefined>("view")
  const qc = useQueryClient()
  const router = useCustomRouter()
  const clickRef = useRef("")
  const { userInfoQuery } = useAuth()
  const toast = useToast()

  const { mutate: mutateReadNotification } = useMutation({
    mutationFn: (notificationId: string) => client.api.notificationControllerReadNotification(notificationId),
    onMutate: async notificationId => {
      await qc.cancelQueries({ queryKey: ["notifications"] })

      const previousNotifications = qc.getQueriesData<InfiniteData<GetNotificationsResponse, number>>({
        queryKey: ["notifications"],
      })
      if (previousNotifications) {
        previousNotifications.forEach(([key, data]) => {
          if (data) {
            qc.setQueryData(key, {
              ...data,
              pages: data.pages.map(page => ({
                ...page,
                notifications: page.notifications.map(notification => {
                  if (notification.id === notificationId) {
                    return {
                      ...notification,
                      status: {
                        ...notification.status,
                        isRead: true,
                      },
                    }
                  }
                  return notification
                }),
              })),
            })
          }
        })
      }
    },
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["notifications"] })
    },
    onError: error => {
      toast({
        status: "error",
        title: "Error",
        message: [error.message ?? "Something went wrong"],
      })
    },
  })

  const { mutate: mutateAccess, isPending: isLoadingAccess } = useMutation({
    mutationFn: ({ accessRequestId, role }: { accessRequestId: string; role?: SharingRole }) =>
      client.api.accessRequestControllerGrantAccess(accessRequestId, {
        action: role ? GrantAccessAction.Approve : GrantAccessAction.Ignore,
        role: role ?? SharingRole.Viewer,
      }),
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["notifications"] })
      if (isToast) {
        onClose?.()
      }
    },
    onError: error => {
      toast({
        status: "error",
        title: "Error",
        message: [error.message ?? "Something went wrong"],
      })
    },
  })

  const { mutate: mutateAcceptInvitedRoom, isPending: isLoadingAcceptInviteRoom } = useAcceptInviteMutation({
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["notifications"] })
      if (isToast) {
        onClose?.()
      }
    },
    onError: error => {
      toast({
        status: "error",
        title: "Error",
        message: [error.message ?? "Something went wrong"],
      })
    },
  })

  const { mutate: mutateAcceptInvited, isPending: isLoadingAcceptInvite } = useMutation({
    mutationFn: ({ inviteId, status }: { inviteId: number; status: WorkspaceInviteStatus }) =>
      client.api.workspaceControllerUpdateWorkspaceInvite(inviteId, {
        status,
      }),
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["notifications"] })
      if (isToast) {
        onClose?.()
      }
    },
    onError: error => {
      toast({
        status: "error",
        title: "Error",
        message: [error.message ?? "Something went wrong"],
      })
    },
  })

  const { mutateAsync: mutateSwitchWorkspace } = useSwitchWorkspaceMutation({
    onError: error => {
      toast({
        status: "error",
        title: "Error",
        message: [error.message ?? "Something went wrong"],
      })
    },
  })

  const handleClickItem = async ({ id, metadata, type }: NotificationWithStatus) => {
    googleAnalytics.event({
      action: "click",
      category: "notification",
      label: `click_notification_${type.toLowerCase()}_${id}`,
      value: 1,
    })

    const parsedMetadata = JSON.parse(metadata || "{}") as NotificationResponseType
    onClose?.()

    if (id) mutateReadNotification(id)

    switch (type) {
      case NotificationType.CREDIT_PURCHASE_SUCCESS:
      case NotificationType.SUBSCRIPTION_PURCHASE_SUCCESS:
        router.push(`/settings/account?tab=Billing&notificationId=${id}`, undefined, {
          shallow: true,
        })
        return

      case NotificationType.WORKSPACE_RENEW_SUBSCRIPTION:
        if (!parsedMetadata.workspaceId) return

        const result1 = await mutateSwitchWorkspace({
          id: parsedMetadata.workspaceId,
        })

        if (result1) {
          window.location.href = `/settings/account?tab=Billing&notificationId=${id}`
        }

        return

      case NotificationType.SHARE_FOLDER:
        router.push(`/workspace/folders?folderId=${parsedMetadata.folderId}&notificationId=${id}`, undefined, {
          shallow: true,
        })

        return

      case NotificationType.SHARE_IMAGE:
      case NotificationType.APPROVED_REQUEST_IMAGE_ACCESS:
      case NotificationType.IGNORED_REQUEST_IMAGE_ACCESS:
        router.push(`/workspace/images/${parsedMetadata.imageId}`, undefined, {
          shallow: true,
        })

        return

      case NotificationType.CHAT_ROOM_DAILY_UNREAD_MESSAGES:
        router.push(`/rooms`, undefined, {
          shallow: true,
        })
        return
      case NotificationType.CHAT_ROOM_TAGGED:
        router.push(
          `/rooms?roomId=${parsedMetadata.roomId}&messageId=${parsedMetadata.messageId}&notification=true`,
          undefined,
          {
            shallow: true,
          },
        )
        return

      case NotificationType.ACCEPTED_CHAT_ROOM_INVITE:
        router.push(`/rooms?roomId=${parsedMetadata.roomId}`, undefined, {
          shallow: true,
        })

        if (router.pathname.includes("/rooms")) {
          const publicKey = useWorkspaceRoomsInfiniteQuery.getKey({
            isPrivate: false,
            searchTerm: "",
          })
          const privateKey = useWorkspaceRoomsInfiniteQuery.getKey({
            isPrivate: true,
            searchTerm: "",
          })

          const roomListKey = useWorkspaceRoomsInfiniteQuery.getKey()

          const roomListEntities = qc.getQueriesData<InfiniteData<GetChatRoomsResult, number>>({
            queryKey: roomListKey,
          })

          const mappedRoom = uniqBy(
            roomListEntities?.reduce((acc: ChatRoomItem[], curr) => {
              return [...acc, ...(curr[1]?.pages?.flatMap(p => p.rooms) ?? [])]
            }, []),
            r => r.id,
          )

          const room = mappedRoom.find(r => r.id === parsedMetadata.roomId)

          if (!room) return

          qc.invalidateQueries({ queryKey: publicKey })
          qc.invalidateQueries({ queryKey: privateKey })
        }

        return

      case NotificationType.ACCEPTED_WORKSPACE_INVITE:
      case NotificationType.WORKSPACE_ROLE_UPDATE:
        if (!parsedMetadata.workspaceId) return

        const result2 = await mutateSwitchWorkspace({
          id: parsedMetadata.workspaceId,
        })

        if (result2) {
          window.location.href = `/workspace`
        }

        return

      case NotificationType.SHARE_STYLE:
        router.push(`/workspace/tools/styles/${parsedMetadata.styleId}`, undefined, {
          shallow: true,
        })

        qc.invalidateQueries({ queryKey: useWorkspaceStyleInfiniteQuery.getKey() })

        return

      case NotificationType.SHARE_WORKFLOW:
        router.push(`/workspace/macros/${parsedMetadata.workflowId}`, undefined, {
          shallow: true,
        })

        return

      case NotificationType.SHARE_WILDCARD:
        router.push(`/workspace/tools/wildcards/${parsedMetadata.wildcardId}`, undefined, {
          shallow: true,
        })

        return

      case NotificationType.SHARE_RECIPE:
        router.push(`/workspace/tools/recipes/${parsedMetadata.recipeId}`, undefined, {
          shallow: true,
        })

        return

      case NotificationType.SHARE_SD_WORKFLOW:
        router.push(`/workspace/tools/comfyui/${parsedMetadata.sdWorkflowId}`, undefined, {
          shallow: true,
        })

        return

      case NotificationType.COMMENT:
      case NotificationType.REPLY:
      case NotificationType.MENTION:
      case NotificationType.ARTICLE_REACTION:
      case NotificationType.IMAGE_REACTION:
      case NotificationType.INSPIRATION_IMAGE_REACTION:
      case NotificationType.TIP_RECEIVED:
        if (parsedMetadata.entityId && type === NotificationType.TIP_RECEIVED) {
          router.push(`/profile/${userInfoQuery?.data?.username}`, undefined, {
            shallow: true,
          })

          return
        }
        if (!parsedMetadata.entityId && type === NotificationType.TIP_RECEIVED) {
          router.push(`/quests`, undefined, {
            shallow: true,
          })
        }

        if (parsedMetadata.entityType === EntityType.ARTICLE) {
          router.push(
            `/explore/articles/${parsedMetadata.articleId}${`?notificationId=${id}`}${
              parsedMetadata.commentId ? `&commentId=${parsedMetadata.commentId}` : ""
            }${parsedMetadata.parentCommentId ? `&parentCommentId=${parsedMetadata.parentCommentId}` : ""}`,
            undefined,
            {
              shallow: true,
            },
          )

          return
        }

        if (parsedMetadata.entityType === EntityType.INSPIRATION_IMAGE) {
          router.push(
            `/explore/images/${parsedMetadata.imageId}${`?notificationId=${id}`}${
              parsedMetadata.commentId ? `&commentId=${parsedMetadata.commentId}` : ""
            }${parsedMetadata.parentCommentId ? `&parentCommentId=${parsedMetadata.parentCommentId}` : ""}`,
            undefined,
            {
              shallow: true,
            },
          )

          return
        }

        if (parsedMetadata.entityType === EntityType.EXPLORE_WORKFLOW) {
          router.push(
            `/explore/macros/${parsedMetadata.workflowId}${`?notificationId=${id}`}${
              parsedMetadata.commentId ? `&commentId=${parsedMetadata.commentId}` : ""
            }${parsedMetadata.parentCommentId ? `&parentCommentId=${parsedMetadata.parentCommentId}` : ""}`,
            undefined,
            {
              shallow: true,
            },
          )

          return
        }

        if (parsedMetadata.entityType === EntityType.EXPLORE_STYLE) {
          router.push(
            `/explore/styles/${parsedMetadata.sdStyleId}${`?notificationId=${id}`}${
              parsedMetadata.commentId ? `&commentId=${parsedMetadata.commentId}` : ""
            }${parsedMetadata.parentCommentId ? `&parentCommentId=${parsedMetadata.parentCommentId}` : ""}`,
            undefined,
            {
              shallow: true,
            },
          )

          return
        }

        if (parsedMetadata.entityType === EntityType.EXPLORE_RECIPE) {
          router.push(
            `/explore/recipes/${parsedMetadata.recipeId}${`?notificationId=${id}`}${
              parsedMetadata.commentId ? `&commentId=${parsedMetadata.commentId}` : ""
            }${parsedMetadata.parentCommentId ? `&parentCommentId=${parsedMetadata.parentCommentId}` : ""}`,
            undefined,
            {
              shallow: true,
            },
          )

          return
        }

        if (parsedMetadata.entityType === EntityType.EXPLORE_SD_WORKFLOW) {
          router.push(
            `/explore/comfyui/${parsedMetadata.sdWorkflowId}${`?notificationId=${id}`}${
              parsedMetadata.commentId ? `&commentId=${parsedMetadata.commentId}` : ""
            }${parsedMetadata.parentCommentId ? `&parentCommentId=${parsedMetadata.parentCommentId}` : ""}`,
            undefined,
            {
              shallow: true,
            },
          )

          return
        }

        if (parsedMetadata.entityType === EntityType.WORKFLOW) {
          router.push(
            `/workspace/macros/${parsedMetadata.workflowId}${`?notificationId=${id}`}${
              parsedMetadata.commentId ? `&commentId=${parsedMetadata.commentId}` : ""
            }${parsedMetadata.parentCommentId ? `&parentCommentId=${parsedMetadata.parentCommentId}` : ""}`,
            undefined,
            {
              shallow: true,
            },
          )

          return
        }

        if (parsedMetadata.entityType === EntityType.IMAGE) {
          router.push(
            `/workspace/images/${parsedMetadata.imageId}${`?notificationId=${id}`}${
              parsedMetadata.commentId ? `&commentId=${parsedMetadata.commentId}` : ""
            }${parsedMetadata.parentCommentId ? `&parentCommentId=${parsedMetadata.parentCommentId}` : ""}`,
            undefined,
            {
              shallow: true,
            },
          )

          return
        }

        if (parsedMetadata.entityType === EntityType.STYLE) {
          router.push(
            `/workspace/tools/styles/${parsedMetadata.sdStyleId}${`?notificationId=${id}`}${
              parsedMetadata.commentId ? `&commentId=${parsedMetadata.commentId}` : ""
            }${parsedMetadata.parentCommentId ? `&parentCommentId=${parsedMetadata.parentCommentId}` : ""}`,
            undefined,
            {
              shallow: true,
            },
          )

          return
        }

        if (parsedMetadata.entityType === EntityType.SD_WORKFLOW) {
          router.push(
            `/workspace/tools/comfyui/${parsedMetadata.sdWorkflowId}${`?notificationId=${id}`}${
              parsedMetadata.commentId ? `&commentId=${parsedMetadata.commentId}` : ""
            }${parsedMetadata.parentCommentId ? `&parentCommentId=${parsedMetadata.parentCommentId}` : ""}`,
            undefined,
            {
              shallow: true,
            },
          )

          return
        }

        if (parsedMetadata.entityType === EntityType.RECIPE) {
          router.push(
            `/workspace/tools/recipes/${parsedMetadata.recipeId}${`?notificationId=${id}`}${
              parsedMetadata.commentId ? `&commentId=${parsedMetadata.commentId}` : ""
            }${parsedMetadata.parentCommentId ? `&parentCommentId=${parsedMetadata.parentCommentId}` : ""}`,
            undefined,
            {
              shallow: true,
            },
          )

          return
        }
    }
  }

  const handleAccess = (id: string, value: "view" | "edit" | undefined, metadata: string | null) => {
    const role = value === "view" ? SharingRole.Viewer : value === "edit" ? SharingRole.Editor : undefined
    const parsedMetadata = JSON.parse(metadata || "{}") as NotificationResponseType
    googleAnalytics.event({
      action: "click",
      category: "notification",
      label: "notification_access",
      params: {
        role: role ?? "",
        image_id: parsedMetadata.imageId,
        notification_id: id,
      },
    })
    if (!parsedMetadata.accessRequestId) return

    mutateAccess({ accessRequestId: parsedMetadata.accessRequestId, role })
  }

  const handleAcceptInvited = (id: string, status: WorkspaceInviteStatus, metadata: string | null) => {
    const parsedMetadata = JSON.parse(metadata || "{}") as NotificationResponseType

    googleAnalytics.event({
      action: "click",
      category: "notification",
      label: "notification_accept_invite",
      params: {
        image_id: parsedMetadata.imageId,
        notification_id: id,
        status: status,
      },
    })

    if (!parsedMetadata.workspaceInviteId) return

    mutateAcceptInvited({ inviteId: parsedMetadata.workspaceInviteId, status })
  }

  const handleAcceptInvitedRoom = (id: string, status: ChatRoomInviteStatus, metadata: string | null) => {
    const parsedMetadata = JSON.parse(metadata || "{}") as NotificationResponseType

    googleAnalytics.event({
      action: "click",
      category: "notification",
      label: "notification_accept_invite",
      params: {
        image_id: parsedMetadata.imageId,
        notification_id: id,
        status: status,
      },
    })

    if (!parsedMetadata.chatRoomInviteId) return

    mutateAcceptInvitedRoom({
      inviteId: parsedMetadata.chatRoomInviteId,
      data: {
        status,
      },
    })
  }

  const renderRequestAccess = () => {
    if (
      notification.type === NotificationType.WORKSPACE_INVITE ||
      notification.type === NotificationType.CHAT_ROOM_INVITE
    ) {
      return (
        <div className="pl-[4.5rem] mt-4 flex items-center space-x-2">
          <IconButton
            isLoading={(isLoadingAcceptInvite || isLoadingAcceptInviteRoom) && clickRef.current === "accept"}
            onClick={e => {
              e.stopPropagation()
              clickRef.current = "accept"

              if (notification.type === NotificationType.CHAT_ROOM_INVITE) {
                handleAcceptInvitedRoom(notification.id, ChatRoomInviteStatus.Accepted, notification.metadata)

                return
              }

              handleAcceptInvited(notification.id, WorkspaceInviteStatus.Accepted, notification.metadata)
            }}
            className="px-2 py-1 text-xs font-semibold"
          >
            Accept
          </IconButton>
          <IconButton
            isLoading={(isLoadingAcceptInvite || isLoadingAcceptInviteRoom) && clickRef.current === "ignore"}
            onClick={e => {
              e.stopPropagation()
              clickRef.current = "ignore"

              if (notification.type === NotificationType.CHAT_ROOM_INVITE) {
                handleAcceptInvitedRoom(notification.id, ChatRoomInviteStatus.Rejected, notification.metadata)

                return
              }

              handleAcceptInvited(notification.id, WorkspaceInviteStatus.Recjected, notification.metadata)
            }}
            className={classNames("px-2 py-1 text-xs font-semibold  text-atherGray-300", {
              "bg-atherGray-900": isLoadingAccess && clickRef.current === "ignore",
            })}
            colorScheme="transparent"
          >
            Ignore
          </IconButton>
        </div>
      )
    }

    if (notification.type === NotificationType.REQUEST_ACCESS) {
      return (
        <div className="pl-[4.5rem] flex mt-4 space-x-2 items-center">
          <Menu
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            className="p-0"
            listClassName="bg-atherGray-850"
            trigger={
              <button className="w-[6.25rem] flex items-center p-2 text-atherGray-0 text-xs rounded-md cursor-pointer bg-atherGray-850 hover:bg-atherGray-800">
                <p className="flex-1 text-left">{selected === "view" ? "Can View" : "Can Edit"}</p>
                <FaChevronDown className="ml-2" size="0.8rem" />
              </button>
            }
          >
            <div className="text-atherGray-0 w-[5.25rem] flex flex-col">
              <IconButton
                onClick={e => {
                  e.stopPropagation()
                  setSelected("view")
                  setIsOpen(false)
                }}
                className={
                  "flex p-2 items-center min-h-0  justify-start text-xs bg-transparent hover:bg-transparent w-full"
                }
                title="Can View"
              >
                Can View
              </IconButton>
              <IconButton
                onClick={e => {
                  e.stopPropagation()
                  setSelected("edit")
                  setIsOpen(false)
                }}
                className={
                  "flex p-2 items-center min-h-0  justify-start text-xs bg-transparent hover:bg-transparent w-full"
                }
                title="Can Edit"
              >
                Can Edit
              </IconButton>
            </div>
          </Menu>
          <IconButton
            isLoading={isLoadingAccess && clickRef.current === "grant"}
            onClick={e => {
              e.stopPropagation()
              clickRef.current = "grant"
              handleAccess(notification.id, selected, notification.metadata)
            }}
            className="px-2 py-1 text-xs font-semibold"
          >
            Grant Access
          </IconButton>
          <IconButton
            isLoading={isLoadingAccess && clickRef.current === "ignore"}
            onClick={e => {
              e.stopPropagation()
              clickRef.current = "ignore"
              handleAccess(notification.id, undefined, notification.metadata)
            }}
            className={classNames("px-2 py-1 text-xs font-semibold  text-atherGray-300", {
              "bg-atherGray-900": isLoadingAccess && clickRef.current === "ignore",
            })}
            colorScheme="transparent"
          >
            Ignore
          </IconButton>
        </div>
      )
    }

    if (
      notification.type === NotificationType.APPROVED_REQUEST_ACCESS ||
      notification.type === NotificationType.ACCEPTED_WORKSPACE_INVITE ||
      notification.type === NotificationType.ACCEPTED_CHAT_ROOM_INVITE
    ) {
      return <p className="pl-[4.25rem]  md:pl-[4.75rem]  mt-2 text-sm text-atherGray-500">Approved by you</p>
    }

    if (
      notification.type === NotificationType.IGNORED_REQUEST_ACCESS ||
      notification.type === NotificationType.REJECTED_WORKSPACE_INVITE ||
      notification.type === NotificationType.REJECTED_CHAT_ROOM_INVITE
    ) {
      return <p className="pl-[4.25rem]  md:pl-[4.75rem] mt-2 text-sm text-atherGray-500">Ignored by you</p>
    }

    return null
  }

  return (
    <div
      key={notification.id}
      className={classNames("cursor-pointer overflow-hidden", {
        "border-b border-atherGray-800 py-4 px-4 hover:bg-atherGray-850": !isToast,
        "pt-2 md:pt-4 w-full md:w-[24rem] bg-atherGray-800 hover:bg-atherGray-800": isToast,
      })}
      onClick={() => handleClickItem(notification)}
    >
      <div className={"flex items-start"}>
        <div className="flex mr-4">
          {!isToast && (
            <div
              className={classNames("mr-3 w-2 h-2 rounded-full bg-atherPurple-500", {
                "bg-atherPurple-500": !notification.status.isRead,
                "bg-transparent": notification.status.isRead,
              })}
            />
          )}
          {notification.type === NotificationType.CHAT_ROOM_DAILY_UNREAD_MESSAGES ? (
            <span className="w-10 h-10 flex items-center justify-center bg-atherGray-700 rounded-full">
              <MessageMultiIcon width={20} height={20} />
            </span>
          ) : notification.type === NotificationType.WORKSPACE_RENEW_SUBSCRIPTION ||
            notification.type === NotificationType.CREDIT_PURCHASE_SUCCESS ||
            notification.type === NotificationType.SUBSCRIPTION_PURCHASE_SUCCESS ? (
            <span className="w-10 h-10 flex items-center justify-center bg-atherGray-700 rounded-full">
              <BillingIcon width={20} height={20} />
            </span>
          ) : (
            <UserCreated size="md" picture={notification.senderImageUrl ?? ""} name={"U"} hiddenName />
          )}
        </div>
        <div className="flex-1 text-sm text-whiteAlpha-700">
          <div dangerouslySetInnerHTML={{ __html: notification.headline }} />
          <div className="mt-2">
            {notification.message.map((message, index) => (
              <div key={index} className="flex flex-wrap" dangerouslySetInnerHTML={{ __html: message }} />
            ))}
          </div>
          {!isToast && (
            <p className="mt-2 text-xs font-light">
              {formatDistanceToNowStrict(new Date(notification.createdAt), { addSuffix: true })}
            </p>
          )}
        </div>
        {(notification.imageUrl || notification.articleCoverUrl) && (
          <div
            className={classNames("bg-atherGray-950 ml-2 h-14 md:h-16 w-14 md:w-16 flex items-center justify-center")}
          >
            <ImageWebpComponent
              src={`${notification.articleCoverUrl ?? notification.imageUrl}`}
              alt=""
              className={classNames("object-contain max-h-full")}
            />
          </div>
        )}
      </div>
      {renderRequestAccess()}
    </div>
  )
}

export default memo(NotificationItem)
