import { Comment, EntityType, User, UserDetail } from "@/api/sdk"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import IconButton from "@/components/IconButton"
import LexicalCustomEditor from "@/components/LexicalEditor/LexicalCustomEditor"
import { MentionDataV2 } from "@/components/LexicalEditor/MentionPlugin"
import { $isBeautifulMentionNode } from "@/components/LexicalEditor/plugin/nodes/MentionNode"
import { Menu } from "@/components/MenuFloating"
import {
  CommentIcon,
  DeleteIcon,
  EditIcon,
  FavoriteFillIcon,
  FavoriteIcon,
  ThreeDotHorizontalIcon,
} from "@/components/shared/icons"
import { useCallbackOnKeys, useEditCommentImage } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/lib/utils"
import { QueryKey } from "@tanstack/react-query"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { $getRoot, EditorState, LexicalEditor } from "lexical"
import useCustomRouter from "@/hooks/useCustomRouter"
import React, { MutableRefObject, memo, useEffect, useRef, useState } from "react"
import { BiCaretDown } from "react-icons/bi"
import { MdClose } from "react-icons/md"
import DateDistanceToNow from "../../../../DateDistanceToNow"
import ChildItemsComment from "./ChildItemsComment"

interface ItemProps {
  itemId: string
  comment: Comment
  onLike: () => void
  nodeId?: string | null
  isChild?: boolean
  onSelectedNode?: (node: string) => void
  onSelected?: (active?: boolean) => void
  mentions: MentionDataV2[]
  otherClassName?: string
  className?: string
  itemOwnerUid?: string
  setSearchMention: (search: string) => void
  selectedComment?: Comment | null
  onDeleted: () => void
  isOwner: boolean
  isDisabledComment?: boolean
  setIsSuccessChild?: React.Dispatch<React.SetStateAction<boolean>>
  userId: string | undefined
  shouldOpenedComment: boolean
  setSelectedComment?: (v: Comment | null) => void
  baseChildParam: number | null
  prevBaseChildParam: MutableRefObject<number | null>
  nextBaseChildParam: MutableRefObject<number | null>
  childTakePage: number
  childTotalPage: MutableRefObject<number>
  refetchComments: () => void
  user: UserDetail | undefined
  queryCommentsImageKey: QueryKey
  setIsFocus: (isFocus: boolean) => void
  typeComment: EntityType
  isMentionsLoading: boolean
}

const ItemComment = ({
  selectedComment,
  comment,
  onLike,
  onSelected,
  mentions,
  itemId,
  nodeId,
  onDeleted,
  onSelectedNode,
  isOwner,
  isDisabledComment,
  isMentionsLoading,
  shouldOpenedComment,
  isChild,
  setSelectedComment,
  baseChildParam,
  setIsSuccessChild,
  nextBaseChildParam,
  itemOwnerUid,
  prevBaseChildParam,
  otherClassName,
  childTakePage,
  className,
  childTotalPage,
  setSearchMention,
  setIsFocus,
  user,
  typeComment,
  queryCommentsImageKey,
}: ItemProps) => {
  const [isLiked, setIsLiked] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const router = useCustomRouter()
  const [isOpenDropDown, setIsOpenDropDown] = useState(false)
  const [childCommentsCount, setChildCommentsCount] = useState(0)
  const isHighlight = router.query?.commentId === comment.id
  const [replyComment, setReplyComment] = useState<User | null>(null)

  const [editorState, setEditorState] = useState<EditorState | undefined>()
  const editorRef = useRef<LexicalEditor>(null)

  const { mutate: mutateEdit } = useEditCommentImage(queryCommentsImageKey, {
    onMutate: () => {
      setIsEditMode(false)
    },
  })

  useEffect(() => {
    setIsLiked(!!comment.likedBy?.find(i => i.uid === user?.uid) ?? false)
  }, [comment.likedBy])

  useEffect(() => {
    if (shouldOpenedComment && router.isReady && comment) {
      const timer = setTimeout(() => {
        setSelectedComment?.(comment)
      }, 150)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [shouldOpenedComment, router.isReady, comment])

  useEffect(() => {
    setChildCommentsCount(comment.childCommentsCount ?? 0)
  }, [comment.childCommentsCount])

  const onEditComment = (data?: EditorState) => {
    const JSONEditorState = JSON.stringify(data?.toJSON())

    const getMentionsAdded = data?.read(() =>
      [...data?._nodeMap.values()].reduce((obj, value) => {
        if (!$isBeautifulMentionNode(value)) return obj
        const valueMention = value.__data as MentionDataV2

        if (obj.map(item => item.uid).includes(valueMention.uid)) return obj
        obj.push(valueMention)
        return obj
      }, [] as MentionDataV2[]),
    )
    const getText = data?.read(() => $getRoot()?.getTextContent())

    mutateEdit({
      commentId: comment.id,
      data: {
        formatValue: JSONEditorState ?? "",
        uidMentions: getMentionsAdded?.map(i => i.uid) ?? [],
        value: getText ?? "",
      },
    })
  }

  const handleTarget = e => {
    e.stopPropagation()
    if (!onSelectedNode || !comment.nodeId) return

    onSelectedNode(comment.nodeId?.toString())
  }

  useCallbackOnKeys([{ key: "Escape" }], () => setIsEditMode(false), isEditMode)

  return (
    <div id={`${comment.id}`} className={cn("flex flex-col w-full", className)} onClick={handleTarget}>
      {comment.nodeId && (
        <div className="flex items-center overflow-hidden">
          <p className="text-xs flex-1 truncate font-semibold text-atherGray-500">NODE #{comment.nodeId}</p>
          {/* <button type="button" onClick={handleTarget} className="cursor-pointer">
            <Target3Icon width={12} height={12} />
          </button> */}
        </div>
      )}

      <div className="flex items-start rounded-md">
        <div className="py-2 mr-2">
          <div className={"flex items-center w-8 h-8 rounded-full"}>
            <UserCreated
              picture={comment.commenter?.picture}
              hiddenName
              name={comment.commenter?.name}
              className="w-full h-full"
              imageClassName="w-full h-full"
            />
          </div>
        </div>
        <AnimatePresence mode="wait" initial={false}>
          <motion.div
            className={classNames("p-2 min-w-auto text-atherGray-300 rounded-md ", {
              "bg-[#23406C]": isHighlight,
              "bg-transparent": !isHighlight,
              "w-full": isEditMode,
            })}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.15 }}
            key={isEditMode ? "edit" : "view"}
          >
            <div
              className={classNames("flex items-center justify-between", {
                "mb-1": isEditMode,
              })}
            >
              <span className="text-xs font-semibold text-atherGray-0">
                {comment.commenter?.name} {isOwner ? "(me)" : ""}
              </span>
              {isEditMode && (
                <div
                  onClick={() => {
                    setIsEditMode(false)
                  }}
                  className="text-red-500 cursor-pointer"
                >
                  <MdClose size="1.2rem" />
                </div>
              )}
            </div>
            <LexicalCustomEditor
              isMentionsLoading={isEditMode ? isMentionsLoading : undefined}
              setIsFocus={setIsFocus}
              editorKey={`edit_${comment.id}`}
              initValue={comment.formatValue ?? undefined}
              readOnly={!isEditMode}
              editorRef={editorRef}
              editorState={editorState}
              setEditorState={setEditorState}
              mentions={mentions}
              onAddComment={onEditComment}
              onSearchMention={setSearchMention}
            >
              <div className="">
                <div
                  className={classNames("flex items-center w-full", {
                    hidden: isEditMode,
                  })}
                >
                  <DateDistanceToNow className="text-atherGray-500" date={comment.createdAt} />
                  <IconButton
                    colorScheme="secondary"
                    onClick={e => {
                      e.stopPropagation()

                      googleAnalytics.handleCategoryEvent({
                        action: "click",
                        params: {
                          action: "Like Comment",
                          [`${typeComment.toLowerCase()}_id`]: itemId,
                        },
                      })

                      onLike()
                    }}
                    className={classNames(
                      "flex items-center text-xs  py-1 px-1.5 ml-3 mr-1 bg-transparent text-atherGray-400",
                    )}
                    title="Like"
                  >
                    {!isLiked ? <FavoriteIcon width={14} height={14} /> : <FavoriteFillIcon width={14} height={14} />}
                    <p className="ml-1 min-w-[0.5rem]">{comment.likeCount}</p>
                  </IconButton>
                  <IconButton
                    colorScheme="secondary"
                    onClick={e => {
                      e.stopPropagation()

                      googleAnalytics.handleCategoryEvent({
                        action: "click",
                        params: {
                          action: "Reply Comment",
                          [`${typeComment.toLowerCase()}_id`]: itemId,
                        },
                      })

                      onSelected?.()

                      setReplyComment(comment.commenter)

                      handleTarget(e)
                    }}
                    className="flex items-center mr-1 py-1 px-1.5 text-xs font-semibold bg-transparent text-atherGray-400"
                  >
                    <CommentIcon width={14} height={14} />
                  </IconButton>
                  {isOwner && (
                    <Menu
                      isOpen={isOpenDropDown}
                      setIsOpen={setIsOpenDropDown}
                      listClassName="p-2"
                      trigger={
                        <IconButton
                          className="flex items-center py-1 px-1.5 mr-1 text-xs font-semibold bg-transparent text-atherGray-400"
                          colorScheme="secondary"
                        >
                          <ThreeDotHorizontalIcon width={14} height={14} />
                        </IconButton>
                      }
                    >
                      <div className="text-white flex flex-col">
                        <IconButton
                          leftIcon={<EditIcon className="mr-2" />}
                          onClick={e => {
                            e.stopPropagation()

                            setIsEditMode(v => !v)
                            setIsOpenDropDown(false)
                          }}
                          className={
                            "flex p-2 rounded-lg items-center justify-start bg-transparent hover:bg-atherGray-600  w-full"
                          }
                          title="Edit"
                        >
                          <p className="text-xs">{isEditMode ? "Cancel" : "Edit"}</p>
                        </IconButton>

                        <IconButton
                          leftIcon={<DeleteIcon className="mr-2 text-red-500" />}
                          onClick={e => {
                            e.stopPropagation()

                            googleAnalytics.handleCategoryEvent({
                              action: "click",
                              params: {
                                action: "Delete Comment",
                                [`${typeComment.toLowerCase()}_id`]: itemId,
                              },
                            })

                            onDeleted()
                            setIsOpenDropDown(false)
                          }}
                          className={
                            "flex p-2 rounded-lg items-center justify-start bg-transparent hover:bg-atherGray-600 w-full"
                          }
                          title="Delete"
                        >
                          <p className="text-xs">Delete</p>
                        </IconButton>
                      </div>
                    </Menu>
                  )}
                </div>
                {!isChild && childCommentsCount > 0 && (
                  <div
                    onClick={e => {
                      e.stopPropagation()

                      googleAnalytics.handleCategoryEvent({
                        action: "click",
                        params: {
                          action: "View Replies",
                          [`${typeComment.toLowerCase()}_id`]: itemId,
                        },
                      })

                      setReplyComment(null)
                      onSelected?.(true)
                    }}
                    className="flex items-center cursor-pointer"
                  >
                    <BiCaretDown
                      className={classNames("text-atherPurple-300", {
                        "transform -rotate-90": selectedComment?.id !== comment.id,
                      })}
                    />
                    <p className="ml-2 text-xs font-semibold cursor-pointer text-atherPurple-300">
                      {childCommentsCount} {childCommentsCount > 1 ? "replies" : "reply"}
                    </p>
                  </div>
                )}
              </div>
            </LexicalCustomEditor>
          </motion.div>
        </AnimatePresence>
      </div>
      <AnimatePresence mode="wait">
        {selectedComment && selectedComment.id === comment.id && (
          <ChildItemsComment
            isMentionsLoading={isMentionsLoading}
            nodeId={nodeId}
            itemOwnerUid={itemOwnerUid}
            otherClassName={otherClassName}
            selectedMention={replyComment}
            onClearMention={() => setReplyComment(null)}
            typeComment={typeComment}
            setIsSuccessChild={setIsSuccessChild}
            setIsFocus={setIsFocus}
            isDisabledComment={isDisabledComment}
            setSearchMention={setSearchMention}
            setChildCommentsCount={setChildCommentsCount}
            mentions={mentions}
            itemId={itemId}
            selectedComment={selectedComment}
            setSelectedComment={setSelectedComment}
            baseChildParam={baseChildParam}
            nextBaseChildParam={nextBaseChildParam}
            prevBaseChildParam={prevBaseChildParam}
            childTakePage={childTakePage}
            childTotalPage={childTotalPage}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default memo(ItemComment)
