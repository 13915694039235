import { CreatorProfileItem } from "@/api/sdk"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/lib/utils"
import { useBrowserRouter } from "@/providers/BrowserRouterProvider"
import { useAuth } from "@/providers/authContext"
import { useMutateFollowUser } from "@/queries"
import { useShareProfileStore } from "@/stores"
import { useHasClientHistory } from "@/stores/ClientHistoryStore"
import { currency } from "@/utils"
import classNames from "classnames"
import useCustomRouter from "@/hooks/useCustomRouter"
import React, { Fragment, useEffect, useState } from "react"
import IconButton from "../IconButton"
import LoadingLogo from "../LoadingLogo"
import { Menu } from "../MenuFloating"
import { DownloadIcon1, EditIcon, FavoriteFillIcon, ShareIcon8, ThreeDotHorizontalIcon, WOWIcon } from "../shared/icons"
import FollowerModal from "./FollowerModal"
import TipModal from "./TipModal"
import TogglePublic from "./shared/TogglePublic"
import UserCreated from "../Explore/Gallery/UserCreated"

export const MetricProfileValue = ({
  value,
  label,
  icon,
  className,
  onClick,
}: {
  value: number
  label?: string
  icon?: React.ReactNode
  className?: string
  onClick?: () => void
}) => {
  return (
    <div
      className={cn(
        "flex flex-col gap-1 md:gap-0 md:flex-row items-center justify-center text-sm md:text-lg text-atherPrimary-100 ",
        className,
      )}
      onClick={onClick}
    >
      {icon && <span className="md:mr-1 order-2 md:order-1">{icon}</span>}
      <span className="font-semibold order-1 md:order-2">{currency(value)}</span>
      {label && <span className="ml-1 text-normal text-xs md:text-sm order-2">{label}</span>}
    </div>
  )
}

const About = ({ text }: { text: string }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    setIsExpanded(text.length <= 100)
  }, [text.length])

  const handleClick = () => {
    setIsExpanded(true)
  }

  if (!text) return null

  return (
    <div className="flex flex-col">
      <p className="text-sm md:text-base text-atherGray-300">
        {!isExpanded ? `${text.slice(0, 100)}...` : text}
        {!isExpanded && (
          <span className="text-white cursor-pointer" onClick={handleClick}>
            ... read more
          </span>
        )}
      </p>
    </div>
  )
}

interface ProfileUserProps {
  isMe: boolean
  modalState: boolean
  setModalState: (v: boolean) => void
  dataUserProfile: CreatorProfileItem | undefined
  isLoadingUserProfile: boolean
  mutateToggle: any
  isLoadingMutateToggle: boolean
  pointBalance?: string
}

const ProfileUser = ({
  isMe,
  modalState,
  setModalState,
  dataUserProfile,
  isLoadingUserProfile,
  mutateToggle,
  isLoadingMutateToggle,
  pointBalance,
}: ProfileUserProps) => {
  const router = useCustomRouter()
  const { back } = useBrowserRouter()
  const hasHistory = useHasClientHistory()
  const [isOpen, setIsOpen] = useState<"followers" | "following" | null>(null)
  const { user, handleSignIn } = useAuth()
  const [isOpenTipModal, setOpenTipModal] = useState<boolean>(false)
  const [isOpenMenu, setOpenMenu] = useState<boolean>(false)
  const setShareProfileModal = useShareProfileStore(state => state.setShareProfileModal)

  const { mutate: mutateFollow, isPending: isFollowing } = useMutateFollowUser()

  const handleBack = () => {
    googleAnalytics.event({
      action: "click",
      category: "profile_user_account",
      label: "back",
    })

    if (hasHistory) {
      back()
    } else {
      router.push("/explore/creators")
    }
  }

  useEffect(() => {
    if (!dataUserProfile || !isMe) return
    googleAnalytics.handleCategoryEvent({
      action: "view",
      params: {
        action: "View Profile Me",
        profile_username: dataUserProfile?.username ?? "",
        profile_id: dataUserProfile?.uid ?? "",
        is_private: dataUserProfile?.isPrivate ? "true" : "false",
        follower_count: dataUserProfile?.metric?.followerCount.toString() ?? "0",
        following_count: dataUserProfile?.metric?.followingCount.toString() ?? "0",
        favorite_count: dataUserProfile?.metric?.favoriteCount.toString() ?? "0",
        download_count: dataUserProfile?.metric?.downloadCount.toString() ?? "0",
        wow_point: pointBalance ?? "0",
      },
    })
  }, [dataUserProfile, isMe])

  if (isLoadingUserProfile) {
    return <LoadingLogo />
  }
  return (
    <>
      {/* <div className="z-[10] fixed top-[6.5rem] md:top-[7rem] left-4 md:left-6">
        <BackButton
          onClick={() => {
            handleBack()
          }}
        />
      </div> */}
      <div className="w-full flex flex-col space-y-2 md:space-y-4 justify-center max-w-[85rem] 2xl:max-w-[105rem] 3xl:max-w-[135rem] 5xl:max-w-[155rem] relative px-4 md:px-0">
        <div className="flex items-center">
          <div className="flex flex-col flex-1 overflow-hidden pt-[2rem] md:pt-[3.5rem]">
            <div className="w-[4rem] h-[4rem] md:h-[6rem] md:w-[6rem] rounded-full overflow-hidden absolute top-0 border-[4px] border-atherGray-950 -translate-y-1/2 left-2 md:left-0">
              <UserCreated
                picture={dataUserProfile?.picture}
                name={dataUserProfile?.name}
                hiddenName
                className="w-full h-full"
                imageClassName="w-full h-full"
              />
            </div>
            <h3 className="md:text-xl text-atherPrimary-100 font-semibold truncate">{dataUserProfile?.name}</h3>
          </div>
          <div className="flex self-end">
            {isMe ? (
              <div className="flex justify-center items-center space-x-2">
                {isMe && (
                  <>
                    <TogglePublic
                      text="Public"
                      isLoadingMutateToggle={isLoadingMutateToggle}
                      mutateToggle={mutateToggle}
                      isPublic={dataUserProfile?.isPrivate ?? false}
                      setModalState={setModalState}
                      boxClassName="min-w-[98px] md:min-w-[120px] rounded-xl"
                      className="min-h-0 rounded-xl"
                    />
                    {/* Mobile */}
                    <Menu
                      isOpen={isOpenMenu}
                      setIsOpen={setOpenMenu}
                      listClassName="p-0 bg-transparent "
                      className="md:hidden"
                      trigger={
                        <IconButton
                          onClick={e => {
                            e.stopPropagation()
                          }}
                          className="ml-1"
                        >
                          <ThreeDotHorizontalIcon className="w-3 h-3" />
                        </IconButton>
                      }
                    >
                      <div
                        className={
                          "text-sm w-[10rem] text-atherGray-300 bg-atherGray-900 border border-atherGray-850 p-2 rounded-2xl space-y-1"
                        }
                      >
                        <IconButton
                          className="py-2 px-3 min-h-0 rounded-xl"
                          colorScheme="transparent"
                          onClick={e => {
                            e.stopPropagation()
                            setOpenMenu(false)

                            googleAnalytics.handleCategoryEvent({
                              action: "click",
                              params: {
                                action: "Share Profile",
                              },
                            })

                            setShareProfileModal(true)
                          }}
                        >
                          Share
                        </IconButton>
                        <IconButton
                          className="py-2 px-3 min-h-0 rounded-xl"
                          colorScheme="transparent"
                          onClick={e => {
                            e.stopPropagation()
                            setOpenMenu(false)

                            googleAnalytics.handleCategoryEvent({
                              action: "click",
                              params: {
                                action: "Edit Profile",
                              },
                            })

                            router.push("/settings/account")
                          }}
                        >
                          Edit
                        </IconButton>
                      </div>
                    </Menu>
                    {/* PC */}
                    <IconButton
                      className="p-2 min-h-0 rounded-lg max-md:hidden"
                      colorScheme="primary"
                      onClick={() => setShareProfileModal(true)}
                    >
                      <ShareIcon8 width={16} height={16} />
                    </IconButton>
                    <IconButton
                      className="p-2 min-h-0 rounded-lg max-md:hidden"
                      colorScheme="primary"
                      onClick={() => router.push("/settings/account")}
                    >
                      <EditIcon width={16} height={16} />
                    </IconButton>
                  </>
                )}
              </div>
            ) : (
              <Fragment>
                <IconButton
                  isLoading={isFollowing}
                  disabled={isMe}
                  onClick={async e => {
                    e.stopPropagation()
                    e.preventDefault()

                    const isSignIn = await handleSignIn()

                    if (!isSignIn || !dataUserProfile) return

                    googleAnalytics.handleCategoryEvent({
                      action: "click",
                      params: {
                        action: dataUserProfile?.isFollowed ? "Unfollow Profile" : "Follow Profile",
                        profile_username: dataUserProfile?.username ?? "",
                        profile_id: dataUserProfile?.uid ?? "",
                      },
                    })

                    mutateFollow({ data: { followingUid: dataUserProfile.uid }, userUid: user?.uid ?? "" })
                  }}
                  className="p-2 text-xs md:text-sm max-md:hidden"
                  colorScheme={!dataUserProfile?.isFollowed ? "primary" : "secondary"}
                >
                  {dataUserProfile?.isFollowed ? "Following" : "Follow"}
                </IconButton>

                <IconButton
                  disabled={isMe}
                  onClick={async () => {
                    const isSignIn = await handleSignIn()

                    if (!isSignIn) return

                    googleAnalytics.handleCategoryEvent({
                      action: "click",
                      params: {
                        action: "Tip",
                        profile_username: dataUserProfile?.username ?? "",
                        profile_id: dataUserProfile?.uid ?? "",
                      },
                    })

                    setOpenTipModal(true)
                  }}
                  className="px-4 py-2 ml-2 text-xs md:text-sm max-md:hidden"
                  leftIcon={
                    <div className="w-4 h-4">
                      <WOWIcon width={16} height={16} />
                    </div>
                  }
                >
                  Tip
                </IconButton>
                <IconButton
                  className="py-2 ml-2 px-3 min-h-0 rounded-xl"
                  colorScheme="primary"
                  onClick={() => setShareProfileModal(true)}
                >
                  <ShareIcon8 width={16} height={16} />
                </IconButton>
              </Fragment>
            )}
          </div>
        </div>

        <div>
          <Metrics
            handleSignIn={handleSignIn}
            isMe={isMe}
            setIsOpen={setIsOpen}
            dataUserProfile={dataUserProfile}
            pointBalance={pointBalance}
          />

          <div className="w-full max-w-full md:max-w-[500px] my-4">
            <About text={dataUserProfile?.bio ? dataUserProfile.bio : ""} />
          </div>
          {dataUserProfile?.followerInfo && dataUserProfile?.followerInfo?.followers.length > 0 && (
            <FollowerInfo dataUserProfile={dataUserProfile} />
          )}
          {/* Mobile */}
          {!isMe && (
            <div className="flex w-full justify-evenly items-center mt-4 md:hidden">
              <IconButton
                isLoading={isFollowing}
                disabled={isMe}
                onClick={async e => {
                  e.stopPropagation()
                  e.preventDefault()

                  const isSignIn = await handleSignIn()

                  if (!isSignIn || !dataUserProfile) return

                  googleAnalytics.handleCategoryEvent({
                    action: "click",
                    params: {
                      action: dataUserProfile?.isFollowed ? "Unfollow Profile" : "Follow Profile",
                      profile_username: dataUserProfile?.username ?? "",
                      profile_id: dataUserProfile?.uid ?? "",
                    },
                  })

                  mutateFollow({ data: { followingUid: dataUserProfile.uid }, userUid: user?.uid ?? "" })
                }}
                className="p-2 text-xs w-full"
                colorScheme={!dataUserProfile?.isFollowed ? "primary" : "secondary"}
              >
                {dataUserProfile?.isFollowed ? "Following" : "Follow"}
              </IconButton>

              <IconButton
                disabled={isMe}
                onClick={async () => {
                  const isSignIn = await handleSignIn()

                  if (!isSignIn) return

                  googleAnalytics.handleCategoryEvent({
                    action: "click",
                    params: {
                      action: "Tip",
                      profile_username: dataUserProfile?.username ?? "",
                      profile_id: dataUserProfile?.uid ?? "",
                    },
                  })

                  setOpenTipModal(true)
                }}
                className="px-4 py-2 ml-2 text-xs w-full"
                leftIcon={
                  <div className="w-4 h-4">
                    <WOWIcon width={16} height={16} />
                  </div>
                }
              >
                Tip
              </IconButton>
            </div>
          )}
        </div>
      </div>
      <FollowerModal
        dataUserProfile={dataUserProfile}
        defaultTab={isOpen}
        isOpen={!!isOpen}
        onClose={() => setIsOpen(null)}
      />
      <TipModal
        isOpen={isOpenTipModal}
        onClose={() => setOpenTipModal(false)}
        pointBalance={pointBalance}
        recipientUid={dataUserProfile?.uid}
        recipientName={dataUserProfile?.name}
      />
    </>
  )
}

export const FollowerInfo = ({ dataUserProfile }: { dataUserProfile: CreatorProfileItem }) => {
  return (
    <div className="flex justify-start items-start text-sm font-light">
      <div className="flex">
        {dataUserProfile?.followerInfo?.followers.map((f, index) => (
          <UserCreated
            key={f.name}
            hiddenName
            className={classNames({
              "ml-[-8px]": index !== 0,
            })}
            picture={f.picture}
            name={f.name}
            size="xs"
          />
        ))}
      </div>
      <span className="ml-1">
        Followed by{" "}
        <span className="text-atherGray-0 font-semibold">
          {dataUserProfile?.followerInfo.followers.map(f => f.name).join(", ")}
        </span>
        {""}
        {dataUserProfile?.followerInfo?.otherCount && dataUserProfile?.followerInfo?.otherCount > 0 ? (
          <span>, & {dataUserProfile?.followerInfo.otherCount} others</span>
        ) : null}
      </span>
    </div>
  )
}

export const Metrics = ({
  dataUserProfile,
  isMe,
  handleSignIn,
  setIsOpen,
  pointBalance,
}: {
  dataUserProfile?: CreatorProfileItem
  isMe: boolean
  handleSignIn: (isAcceptedEmail?: boolean | undefined) => Promise<boolean>
  setIsOpen: (i: "followers" | "following") => void
  pointBalance?: string
}) => {
  return (
    <div className="flex items-center justify-center md:justify-start my-2">
      <div className="flex flex-wrap divide-x divide-atherGray-800 w-full">
        <MetricProfileValue
          onClick={async () => {
            if (dataUserProfile?.isPrivate && !isMe) return

            const signIn = await handleSignIn()

            if (!signIn) return

            googleAnalytics.handleCategoryEvent({
              action: "click",
              params: {
                action: "View Followers",
                profile_username: dataUserProfile?.username ?? "",
                profile_id: dataUserProfile?.uid ?? "",
              },
            })

            setIsOpen("followers")
          }}
          className={"cursor-pointer md:pr-4 grow md:grow-0"}
          value={dataUserProfile?.metric!.followerCount ?? 0}
          label="Followers"
        />
        <MetricProfileValue
          onClick={async () => {
            if (dataUserProfile?.isPrivate && !isMe) return

            const signIn = await handleSignIn()

            if (!signIn) return

            googleAnalytics.handleCategoryEvent({
              action: "click",
              params: {
                action: "View Following",
                profile_username: dataUserProfile?.username ?? "",
                profile_id: dataUserProfile?.uid ?? "",
              },
            })

            setIsOpen("following")
          }}
          className="cursor-pointer md:px-4 grow md:grow-0"
          value={dataUserProfile?.metric!.followingCount ?? 0}
          label="Following"
        />
        {dataUserProfile?.metric!.favoriteCount !== undefined && (
          <MetricProfileValue
            className="md:px-4 grow md:grow-0"
            value={dataUserProfile?.metric.favoriteCount}
            icon={<FavoriteFillIcon width={20} height={20} />}
          />
        )}
        {dataUserProfile?.metric!.downloadCount !== undefined && (
          <MetricProfileValue
            className="md:px-4 grow md:grow-0"
            value={dataUserProfile?.metric.downloadCount}
            icon={<DownloadIcon1 width={20} height={20} />}
          />
        )}
        {pointBalance !== undefined && isMe && (
          <MetricProfileValue
            className="md:pl-4 grow md:grow-0"
            value={parseInt(pointBalance)}
            icon={<WOWIcon width={20} height={20} />}
          />
        )}
      </div>
    </div>
  )
}

export default ProfileUser
