import { RecipeInputType, RecipeStep } from "@/api/sdk"
import NotFoundItems from "@/components/Workspace/Pinned/NotFoundItems"
import classNames from "classnames"
import _camelCase from "lodash/camelCase"
import { forwardRef, useCallback, useImperativeHandle, useState } from "react"
import { UseFormReturn, useFieldArray } from "react-hook-form"
import { GraphField } from "../../../ComfyUI/utils/graph"
import IconButton from "../../../IconButton"
import Popover from "../../../Popover"
import { EyeIcon, PlusIcon, UploadIcon5 } from "../../../shared/icons"
import ComfyUIWorkflowSetting from "./ComfyUIWorkflowSetting"

export type ComfyUIWorkflowSettingsProps = {
  graphInputs: GraphField[]
  onSelect: (comfyKey: string) => void
  onPublish: () => void
  form: UseFormReturn<RecipeStep, any, undefined>
  isDraft?: boolean
  onPreview?: () => void
}

export type ComfyUIWorkflowSettingsRef = {
  addInput: (type: RecipeInputType, comfyKey?: string) => void
  setSettings: (steps: RecipeStep[]) => void
  validateSettings: (shouldTrigger?: boolean) => Promise<RecipeStep[] | undefined>
}

export const workflowInputTypes = [
  { name: "Image", type: RecipeInputType.Image, dataTypes: ["image"] },
  { name: "Pose", type: RecipeInputType.Pose, dataTypes: ["image"] },
  { name: "Text", type: RecipeInputType.Text, dataTypes: ["string"] },
  { name: "Dropdown", type: RecipeInputType.Select, dataTypes: ["string", "select"] },
  { name: "Radio", type: RecipeInputType.Radio, dataTypes: ["string", "select", "number"] },
  { name: "Number", type: RecipeInputType.Number, dataTypes: ["number"] },
  { name: "Boolean", type: RecipeInputType.Boolean, dataTypes: ["boolean"] },
  { name: "Model", type: RecipeInputType.Model, dataTypes: ["model"] },
  { name: "Style", type: RecipeInputType.Style, dataTypes: ["style"] },
  { name: "Lora", type: RecipeInputType.Lora, dataTypes: ["lora"] },
  // { name: "Lora Simple", type: RecipeInputType.LoraSimple, dataTypes: ["lora_simple"] },
  { name: "Aspect Ratio", type: RecipeInputType.Ratio, dataTypes: ["ratio"] },
]

const ComfyUIWorkflowSettings = forwardRef<ComfyUIWorkflowSettingsRef, ComfyUIWorkflowSettingsProps>((props, ref) => {
  const { graphInputs: graphInputs, onSelect, onPublish, form, onPreview } = props

  const [showAddOptions, setShowAddOptions] = useState(false)

  const { fields, append, remove, move } = useFieldArray({
    control: form.control,
    name: "inputs",
  })

  const handleAddInput: ComfyUIWorkflowSettingsRef["addInput"] = useCallback(
    (type, comfyKey) => {
      append({ type, comfyKey: comfyKey ?? "", name: "", key: "" })
    },
    [append],
  )

  const handleSetSettings = useCallback(
    (steps: RecipeStep[]) => {
      const inputs = steps.flatMap(s => s.inputs)

      form.setValue("inputs", inputs)
    },
    [form],
  )

  const handleValidateSettings = useCallback(
    async (shouldTrigger = true) => {
      if (shouldTrigger) {
        const valid = await form.trigger(undefined, { shouldFocus: true })
        if (!valid) return
      }

      const step = form.getValues()

      step.inputs.forEach(input => {
        input.key = _camelCase(input.name)
      })

      return [step]
    },
    [form],
  )

  const handleSave = async () => {
    const valid = await form.trigger()
    if (!valid) return

    const step = form.getValues()
    step.inputs.forEach(input => {
      if (input.type === RecipeInputType.Style) {
        input.weightComfyKey = input.comfyKey.replace("data", "weight")
      }

      input.key = _camelCase(input.name)
    })

    // onSave([step])

    return [step]
  }

  useImperativeHandle(ref, () => {
    return {
      addInput: handleAddInput,
      setSettings: handleSetSettings,
      validateSettings: handleValidateSettings,
    }
  }, [handleAddInput, handleSetSettings, handleValidateSettings])

  // const toast = useToast()

  return (
    <>
      <div className="pt-4 px-4">
        <p className="font-semibold mb-2">Convert to Recipe</p>
        <div className="flex items-center space-x-4 max-w-[36rem]">
          <IconButton disabled={fields.length === 0} onClick={onPreview} className="w-full" colorScheme="secondary">
            <EyeIcon width={20} height={20} />
            <span className="ml-1">Preview</span>
          </IconButton>
          <IconButton
            disabled={fields.length === 0}
            onClick={async () => {
              const result = await handleSave()

              if (result && result?.length === 0) return

              onPublish()
            }}
            className="w-full"
          >
            <UploadIcon5 width={20} height={20} />
            <span className="ml-1">Publish</span>
          </IconButton>
        </div>
      </div>
      <div className="p-4 border-b border-atherGray-800 flex gap-2">
        <Popover
          isOpen={showAddOptions}
          setIsOpen={open => {
            setShowAddOptions(open)
          }}
          trigger={
            <IconButton
              colorScheme="secondary"
              className="flex-1 max-w-[36rem]"
              leftIcon={<PlusIcon height={12} width={12} />}
            >
              Add Input
            </IconButton>
          }
          align="center"
          className="origin-top-right"
        >
          <div className="p-3 overflow-auto max-h-[15rem] rounded-xl bg-atherGray-850 border border-atherGray-800 flex w-[18rem] flex-col space-y-2">
            {workflowInputTypes.map(option => (
              <IconButton
                key={option.type}
                className={classNames(
                  "flex items-center text-left text-atherGray-300 hover:bg-atherGray-800 py-2 hover:text-atherGray-0 justify-start px-4 active:bg-atherGray-800 bg-transparent w-full cursor-pointer rounded-xl",
                )}
                onClick={() => {
                  setShowAddOptions(false)
                  handleAddInput(option.type)
                }}
              >
                {option.name}
              </IconButton>
            ))}
          </div>
        </Popover>
      </div>
      <div className="flex-1 overflow-auto flex flex-col w-full p-4">
        <fieldset className="w-full flex flex-col space-y-4">
          {fields.map((field, index) => (
            <ComfyUIWorkflowSetting
              key={field.id}
              field={field}
              graphInputs={graphInputs}
              form={form}
              index={index}
              onSelect={onSelect}
              onRemove={() => remove(index)}
              onSwap={move}
            />
          ))}
        </fieldset>
      </div>
    </>
  )
})

export default ComfyUIWorkflowSettings
