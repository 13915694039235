import { Comment, EntityType, GetCommentsResult } from "@/api/sdk"
import React, { MutableRefObject, useEffect, useState } from "react"
import { css } from "@emotion/css"
import ItemComment from "./ItemComment"
import { QueryKey, useQueryClient } from "@tanstack/react-query"
import { useDeleteCommentImage, useLikeCommentImage } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import useCustomRouter from "@/hooks/useCustomRouter"
import ViewOtherComment from "./ViewOtherComment"
import { MentionDataV2 } from "@/components/LexicalEditor/MentionPlugin"
import classNames from "classnames"

interface ViewCommentProps {
  image: {
    id: string
    canComment?: boolean
  }
  mentions: MentionDataV2[]
  mappedComments?: GetCommentsResult
  hasNextPage?: boolean
  hasPreviousPage?: boolean
  fetchNextPage: () => void
  fetchPreviousPage: () => void
  itemClassName?: string
  isCommentsSuccess: boolean
  isMentionsLoading: boolean
  refetchComments: () => void
  queryCommentsImageKey: QueryKey
  isFetchingPreviousPage: boolean
  isFetchingNextPage: boolean
  prevBaseParentParam: MutableRefObject<number | null>
  nextBaseParentParam: MutableRefObject<number | null>
  baseChildParam: number | null
  prevBaseChildParam: MutableRefObject<number | null>
  nextBaseChildParam: MutableRefObject<number | null>
  childTakePage: number
  childTotalPage: MutableRefObject<number>
  setIsFocus: (isFocus: boolean) => void
  typeComment: EntityType
  setSearchMention: (search: string) => void
  nodeId?: string | null
  onSelectedNode?: (node: string) => void
  otherClassName?: string
  itemOwnerUid?: string
}

const ViewComment = ({
  setIsFocus,
  image,
  otherClassName,
  mentions,
  isMentionsLoading,
  mappedComments,
  hasNextPage,
  isCommentsSuccess,
  fetchNextPage,
  itemOwnerUid,
  onSelectedNode,
  setSearchMention,
  queryCommentsImageKey,
  itemClassName,
  fetchPreviousPage,
  refetchComments,
  isFetchingNextPage,
  nodeId,
  isFetchingPreviousPage,
  hasPreviousPage,
  prevBaseParentParam,
  nextBaseParentParam,
  typeComment,
  baseChildParam,
  nextBaseChildParam,
  prevBaseChildParam,
  childTakePage,
  childTotalPage,
}: ViewCommentProps) => {
  const router = useCustomRouter()
  const parentCommentId = router.query.parentCommentId as string

  const { userInfoQuery, user, handleSignIn } = useAuth()
  const [isSuccessChild, setIsSuccessChild] = useState(false)

  const { mutate: mutateLike } = useLikeCommentImage(queryCommentsImageKey)
  const { mutate: mutateDeleteComment } = useDeleteCommentImage(queryCommentsImageKey)

  const [selectedComment, setSelectedComment] = useState<Comment | null>(null)
  const [isRedirect, setIsRedirect] = useState(false)

  useEffect(() => {
    if (router.isReady && isCommentsSuccess && router.query.notificationId) {
      setIsRedirect(true)
    }
  }, [isCommentsSuccess, router.isReady, router.query.notificationId])

  useEffect(() => {
    if (image.id) {
      setSelectedComment(null)
    }
  }, [image.id])

  useEffect(() => {
    const commentId = router.query.commentId as string

    if (commentId && isRedirect && (!parentCommentId || isSuccessChild)) {
      const timer = setTimeout(() => {
        const element = document.getElementById(commentId)
        element?.scrollIntoView({ behavior: "smooth", block: "center" })
        setIsRedirect(false)
      }, 250)

      return () => clearTimeout(timer)
    }
  }, [isRedirect, isSuccessChild, parentCommentId, router.query.commentId])

  //<----------------------------------------->

  const qc = useQueryClient()

  const primaryChildKey =
    typeComment === EntityType.ARTICLE
      ? "infinite-comment-child-articles"
      : typeComment === EntityType.INSPIRATION_IMAGE
        ? "infinite-explore-comment-child-images"
        : typeComment === EntityType.IMAGE
          ? "infinite-comment-child-images"
          : typeComment === EntityType.EXPLORE_STYLE
            ? "infinite-explore-comment-child-styles"
            : typeComment === EntityType.EXPLORE_RECIPE
              ? "infinite-explore-comment-child-recipes"
              : typeComment === EntityType.EXPLORE_WORKFLOW
                ? "infinite-explore-comment-child-workflows"
                : typeComment === EntityType.EXPLORE_SD_WORKFLOW
                  ? "infinite-explore-comment-child-sd-workflows"
                  : typeComment === EntityType.STYLE
                    ? "infinite-comment-child-styles"
                    : typeComment === EntityType.WORKFLOW
                      ? "infinite-comment-child-workflows"
                      : typeComment === EntityType.SD_WORKFLOW
                        ? "infinite-comment-child-sd-workflows"
                        : "infinite-comment-child"

  return (
    <div
      className={css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        marginBottom: "1rem",
      })}
    >
      {mappedComments && mappedComments.comments.length === 0 ? (
        <div className="flex items-center justify-center flex-1 p-4">
          <p className="text-xs text-center text-atherGray-500">
            There is no comment yet! Lets make a conversation here!
          </p>
        </div>
      ) : null}

      {mappedComments && mappedComments.comments.length > 0 ? (
        <div className="relative flex flex-col flex-1 max-w-full">
          <div className="flex items-center" id="from-notification"></div>
          {(hasPreviousPage || isFetchingPreviousPage) && (
            <ViewOtherComment
              className={classNames("mb-4", otherClassName)}
              isFetchingPage={isFetchingPreviousPage}
              fetchPage={() => {
                qc.resetQueries({ queryKey: [primaryChildKey] })

                if (prevBaseParentParam.current !== null) {
                  fetchPreviousPage()
                  prevBaseParentParam.current -= 1
                }
              }}
              content="View previous comments"
            />
          )}

          <div className="w-full py-2">
            {mappedComments?.comments.map((comment, idx) => (
              <ItemComment
                isMentionsLoading={isMentionsLoading}
                itemOwnerUid={itemOwnerUid}
                otherClassName={otherClassName}
                className={itemClassName}
                nodeId={nodeId}
                typeComment={typeComment}
                setSearchMention={setSearchMention}
                setIsSuccessChild={selectedComment ? setIsSuccessChild : () => {}}
                setIsFocus={setIsFocus}
                user={userInfoQuery?.data}
                queryCommentsImageKey={queryCommentsImageKey}
                isDisabledComment={!image.canComment}
                isOwner={userInfoQuery?.data?.uid === comment.commenter?.uid}
                userId={userInfoQuery?.data?.uid}
                selectedComment={selectedComment}
                itemId={image.id}
                mentions={mentions}
                onSelectedNode={() => {
                  if (!onSelectedNode || !comment.nodeId) return

                  onSelectedNode(comment.nodeId.toString())
                }}
                onSelected={async active => {
                  const isSignIn = await handleSignIn()

                  if (!isSignIn) return

                  if (!!selectedComment && comment.id === selectedComment.id && active) {
                    qc.resetQueries({
                      queryKey: [primaryChildKey],
                    })

                    setSelectedComment(null)
                  } else {
                    // if (baseChildParam !== null) {
                    //   prevChildPageParam.current = childPageParam.current - 1
                    //   nextCalPageParam.current = childPageParam.current + 1
                    // }
                    setSelectedComment(comment)
                  }
                }}
                onDeleted={() => mutateDeleteComment(comment.id)}
                onLike={() => mutateLike(comment.id)}
                key={comment.id + idx}
                comment={comment}
                shouldOpenedComment={comment.id === parentCommentId}
                setSelectedComment={setSelectedComment}
                baseChildParam={baseChildParam}
                nextBaseChildParam={nextBaseChildParam}
                prevBaseChildParam={prevBaseChildParam}
                childTakePage={childTakePage}
                childTotalPage={childTotalPage}
                refetchComments={refetchComments}
              />
            ))}
          </div>
          {(hasNextPage || isFetchingNextPage) && (
            <ViewOtherComment
              className={classNames("mt-4", otherClassName)}
              isFetchingPage={isFetchingNextPage}
              fetchPage={() => {
                if (nextBaseParentParam.current !== null) {
                  fetchNextPage()
                  nextBaseParentParam.current += 1
                }
              }}
              content="View more comments"
              length={mappedComments.comments.length}
              totalLength={mappedComments.totalParentComment ?? 0}
            />
          )}
        </div>
      ) : null}
    </div>
  )
}

export default ViewComment
