import { EntityType, GetMentionableUsersResult, SDWorkflowDetail } from "@/api/sdk"
import AddComment from "@/components/Explore/ImageDetail/TabImage/CommenstImage/AddComment"
import { cn } from "@/lib/utils"
import { FC, HTMLAttributes, memo, useEffect, useMemo, useRef, useState } from "react"
import SelectNodes from "./ComfyCommentComponents/SelectNodes"
import useComment from "@/components/Explore/ImageDetail/TabImage/CommenstImage/useComment"
import ViewComment from "@/components/Explore/ImageDetail/TabImage/CommenstImage/ViewComment"
import { useAuth } from "@/providers/authContext"
import SignInRequiredButton from "@/components/Explore/SignInRequiredButton"
import { css } from "@emotion/css"
import client from "@/api/client"
import { createAuthenticatedQuery } from "@/queries/createAuthenticatedQuery"
import LoadingIcon from "@/components/LoadingIcon"

type ComfyUIRecipeInfoProps = HTMLAttributes<HTMLDivElement> & {
  isEditting: boolean
  workflow?: SDWorkflowDetail
  onSelectNode?: (node: string) => void
  isOpen?: boolean
  canvasClick?: boolean
  onCanvasClose?: () => void
  selectedNodes: string[]
}

export const useComfyUIMentions = createAuthenticatedQuery<
  GetMentionableUsersResult,
  { sdWorkflowId: string; searchQuery: string },
  Error
>({
  primaryKey: "users-mentions-sdWorkflow",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api
      .sdWorkflowControllerGetSdWorkflowMentionableUsers(variables.sdWorkflowId, {
        search: variables.searchQuery,
        skip: 0,
        take: 12,
      })
      .then(res => res.data),
  refetchOnWindowFocus: false,
  use: [
    useQueryNext => {
      return options => {
        const enabled = !!options.variables?.sdWorkflowId ? options.enabled : false

        return useQueryNext({
          enabled,
          ...options,
        })
      }
    },
  ],
})

const ComfyUIRecipeComments: FC<ComfyUIRecipeInfoProps> = props => {
  const {
    workflow,
    isEditting,
    className,
    isOpen,
    onSelectNode,
    canvasClick,
    onCanvasClose,
    selectedNodes,
    ...divProps
  } = props
  const [searchMentions, setSearchMention] = useState<string>("")
  const [selectedNode, setSelectedNode] = useState<string | null>(null)
  const { user } = useAuth()
  const ref = useRef<HTMLDivElement>(null)

  const {
    states: {
      hasNextPage,
      hasPreviousPage,
      isLoading,
      isFetchingNextPage,
      isFetchingPreviousPage,
      isCommentsSuccess,
      isCommentFromNoti,
    },
    functions: { fetchNextPage, fetchPreviousPage, refetchComments, resetParams },
    contants: { childTakePage, baseChildParam, queryCommentsImageKey, childTotalPage },
    data: { mappedComments },
    paginations: { nextBaseChildParam, prevBaseChildParam, nextBaseParentParam, prevBaseParentParam },
  } = useComment(workflow?.id ?? "", EntityType.SD_WORKFLOW)

  const { data: initMentions, isFetching: isMentionsLoading } = useComfyUIMentions({
    variables: {
      sdWorkflowId: workflow?.id ?? "",
      searchQuery: searchMentions,
    },
    enabled: !!workflow?.id,
  })

  const grapNodes = useMemo(
    () =>
      workflow?.workflow?.nodes.map(i => ({
        ...i,
        id: i.id.toString(),
        type: i.type,
      })),
    [workflow],
  ) as { id: string; type: string }[]

  const mentions = useMemo(() => {
    return (
      initMentions?.users
        ?.filter(u => user?.uid !== u.uid)
        .map(us => ({
          ...us,
          picture: us.picture ?? "",
          name: us.name ?? "",
          uid: us.uid,
          label: `${us.name}`,
          value: `${us.name}`,
          itemValue: `${us.name}`,
          email: us.email ?? "",
        })) ?? []
    )
  }, [initMentions, user?.uid])

  useEffect(() => {
    if (isOpen) {
      refetchComments()
    }
  }, [isOpen, refetchComments])

  useEffect(() => {
    if (selectedNodes.length > 0) {
      setSelectedNode(selectedNodes[0])
    }
  }, [selectedNodes])

  if (!workflow) return null

  const renderBody = () => {
    if (isLoading)
      return (
        <div className="flex items-center justify-center flex-1 w-full text-gray-600">
          <LoadingIcon width={20} height={20} />
        </div>
      )

    return (
      <ViewComment
        isMentionsLoading={isMentionsLoading}
        itemOwnerUid={workflow.creator.uid}
        itemClassName="hover:bg-atherGray-850 px-4 pt-4 pb-2 cursor-pointer"
        otherClassName="px-4"
        setSearchMention={setSearchMention}
        setIsFocus={() => {}}
        nodeId={selectedNode}
        queryCommentsImageKey={queryCommentsImageKey}
        isCommentsSuccess={isCommentsSuccess}
        refetchComments={refetchComments}
        onSelectedNode={onSelectNode}
        mappedComments={mappedComments}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        fetchNextPage={fetchNextPage}
        fetchPreviousPage={fetchPreviousPage}
        isFetchingNextPage={isFetchingNextPage}
        isFetchingPreviousPage={isFetchingPreviousPage}
        typeComment={EntityType.SD_WORKFLOW}
        mentions={mentions}
        image={{
          id: workflow.id,
          canComment: !!user,
        }}
        prevBaseParentParam={prevBaseParentParam}
        nextBaseParentParam={nextBaseParentParam}
        baseChildParam={baseChildParam}
        nextBaseChildParam={nextBaseChildParam}
        prevBaseChildParam={prevBaseChildParam}
        childTakePage={childTakePage}
        childTotalPage={childTotalPage}
      />
    )
  }

  return (
    <div {...divProps} className={cn("flex-1 flex flex-col overflow-hidden", className)}>
      <div className="border-b border-atherGray-800 px-4 pt-4 pb-2">
        <p className="font-semibold text-sm mb-4">Comment</p>
        <SelectNodes
          canvasClick={canvasClick}
          onCanvasClose={onCanvasClose}
          className="mb-4"
          grapNodes={grapNodes}
          value={selectedNode}
          onChange={node => {
            setSelectedNode(node)

            if (!node) return

            onSelectNode?.(node)
          }}
        />
        <div
          className={css({
            flex: 1,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            padding: "0 0.25rem",
          })}
        >
          {user ? (
            <AddComment
              isMentionsLoading={isMentionsLoading}
              itemOwnerUid={workflow.creator.uid}
              placeholder={selectedNode ? "Add a comment" : "Select a node to comment"}
              disabled={!selectedNode}
              nodeId={selectedNode}
              setIsFocus={() => {}}
              queryCommentsImageKey={queryCommentsImageKey}
              mentions={mentions}
              onAddCommentSucessFully={() => {
                ref?.current?.scrollTo({
                  top: 0,
                  behavior: "smooth",
                })
              }}
              onSearchMention={setSearchMention}
              itemId={workflow.id}
              resetParams={() => {}}
              typeComment={EntityType.SD_WORKFLOW}
              isCommentFromNoti={isCommentFromNoti}
            />
          ) : (
            <div className="w-full mb-2">
              <SignInRequiredButton
                earlyAccessModal
                className="bg-atherGray-800"
                text={
                  <p className="text-xs font-normal text-atherGray-300">
                    You must <span className="font-semibold text-atherGray-0">Sign In</span> to add a comment
                  </p>
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex-1 flex flex-col py-2 overflow-hidden">
        <div className="flex-1 overflow-auto flex flex-col" ref={ref}>
          {renderBody()}
        </div>
      </div>
    </div>
  )
}

export default memo(ComfyUIRecipeComments)
