import { GetModelsResponse, RecipeStep, SDBaseModel } from "@/api/sdk"
import BackButton from "@/components/BackButton"
import { GraphField } from "@/components/ComfyUI/utils/graph"
import IconButton from "@/components/IconButton"
import NotFoundItems from "@/components/Workspace/Pinned/NotFoundItems"
import { CheckableRecipeInput } from "@/components/Workspace/Recipes/RecipeDetail"
import { useLoraQuery } from "@/components/Workspace/Recipes/RecipeDetail/FormDetail/FormDetailLora"
import { useModelQuery } from "@/components/Workspace/Recipes/RecipeDetail/FormDetail/FormDetailModel"
import { RecipeCreateType, RecipeParams, validateRecipeParams } from "@/utils/task"
import { useQueryClient } from "@tanstack/react-query"
import dynamic from "next/dynamic"
import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react"
import { FormProvider, useForm } from "react-hook-form"
import { HiMiniArrowLeftOnRectangle } from "react-icons/hi2"

const DynamicFormRecipe = dynamic(() => import("@/components/Workspace/Recipes/RecipeDetail/FormDetail"), {
  ssr: false,
})

export type ComfyUIWorkflowStepsProps = {
  graphInputs: GraphField[]
  steps: RecipeStep[]
  baseModel?: SDBaseModel
  onConfig: () => void
  isEditing?: boolean
  onPublish: () => void
  onBack?: () => void
  onInputChange?: (inputs: CheckableRecipeInput[]) => void
}

export type ComfyUIWorkflowStepsRef = {
  getSteps: () => RecipeStep[]
  getRecipeParams: () => Promise<RecipeParams | undefined>
}

const ComfyUIWorkflowSteps = forwardRef<ComfyUIWorkflowStepsRef, ComfyUIWorkflowStepsProps>((props, ref) => {
  const { graphInputs, steps, baseModel, onPublish, onInputChange, onBack } = props

  const qc = useQueryClient()
  const form = useForm<RecipeCreateType>({ mode: "onSubmit" })
  const recipeInputsRef = useRef<CheckableRecipeInput[]>([])
  const [recipeInputs, _setRecipeInputs] = useState<CheckableRecipeInput[]>(recipeInputsRef.current)
  const recipe = useMemo(() => ({ steps, baseModel }), [steps, baseModel])

  const { data: loras } = useLoraQuery({ variables: { baseModel: SDBaseModel.All } })

  const setRecipeInputs: Dispatch<SetStateAction<CheckableRecipeInput[]>> = useCallback(
    inputs => {
      if (typeof inputs === "function") {
        _setRecipeInputs(prev => {
          recipeInputsRef.current = inputs(prev)
          onInputChange?.(recipeInputsRef.current)
          return recipeInputsRef.current
        })
      } else {
        recipeInputsRef.current = inputs
        onInputChange?.(recipeInputsRef.current)
        _setRecipeInputs(inputs)
      }
    },
    [onInputChange],
  )

  const handleSetRecipeInputs = useCallback(
    (graphInputs: GraphField[], steps: RecipeStep[], overrideValues = false) => {
      const params = graphInputs.reduce<Record<string, any>>((all, input) => {
        const isWeightInput = recipeInputsRef.current.find(i => i.weightComfyKey === input.id)
        if (isWeightInput?.type === "style") {
          all[`${isWeightInput.key}Intensity`] = input.value
          return all
        }

        const isInRecipe = recipeInputsRef.current.find(i => i.comfyKey === input.id)
        if (!isInRecipe) return all

        // skip style & lora override
        if (isInRecipe.type === "style" || isInRecipe.type === "lora") return all

        // value not in predefinded list
        if (isInRecipe.options?.length && !isInRecipe.options.find(o => o.value === input.value)) return all

        if (isInRecipe.type === "model") {
          const key = useModelQuery.getKey({ baseModel: isInRecipe.baseModel })
          const models = qc.getQueryData<GetModelsResponse>(key)

          const model = models?.models.find(m => m.fileName === input.value)
          if (model) {
            all[isInRecipe.key] = model.id
            return all
          }
        }

        all[isInRecipe.key] = input.value
        return all
      }, {})

      setRecipeInputs(currentInputs => {
        const flattenInputs = steps
          .flatMap(step => step.inputs)
          .map<CheckableRecipeInput>(curr => {
            const oldInput = currentInputs.find(i => i.comfyKey === curr.comfyKey)
            const value = overrideValues
              ? params[curr.key] ?? oldInput?.value ?? curr.value
              : curr.value || oldInput?.value || params[curr.key]
            return { ...curr, value, isChecked: !!value }
          })

        const newParams = flattenInputs.reduce((acc, curr) => {
          acc[curr.key] = curr.value
          if (curr.type === "style" && params[`${curr.key}Intensity`]) {
            acc[`${curr.key}Intensity`] = params[`${curr.key}Intensity`]
          }

          return acc
        }, {})

        setTimeout(() => {
          form.reset(newParams)
        }, 0)

        return flattenInputs
      })
    },
    [setRecipeInputs],
  )

  const getSteps = useCallback(() => {
    const params = form.getValues()

    return steps.map(step => ({
      ...step,
      inputs: step.inputs.map(input => {
        return {
          ...input,
          value: params[input.key] ?? input.value,
        }
      }),
    }))
  }, [form, steps])

  const getRecipeParams = useCallback(() => {
    return validateRecipeParams({
      form,
      recipeInputs: recipeInputsRef.current,
      loras,
    })
  }, [form, loras])

  useImperativeHandle(ref, () => ({ getSteps, getRecipeParams }), [getSteps, getRecipeParams])

  useEffect(() => {
    handleSetRecipeInputs(graphInputs, steps)
  }, [graphInputs, steps])

  return (
    <>
      <div className="flex-1 overflow-hidden flex flex-col">
        <div className="p-4 border-b border-atherGray-800 flex flex-col space-y-2">
          <div className="flex items-center">
            <BackButton className="px-1" label="" onClick={onBack} colorScheme="transparent" />
            <p className="font-semibold">Preview</p>
          </div>
          <IconButton
            disabled={(steps[0]?.inputs.length ?? 0) === 0}
            className="w-full text-sm"
            colorScheme="secondary"
            leftIcon={<HiMiniArrowLeftOnRectangle width={16} height={16} />}
            onClick={() => handleSetRecipeInputs(graphInputs, steps, true)}
          >
            <p>Sync from graph</p>
          </IconButton>
        </div>
        <div className="flex-1 overflow-auto flex flex-col p-4">
          {steps[0]?.inputs.length ?? 0 > 0 ? (
            <FormProvider {...form}>
              <DynamicFormRecipe
                recipeInputs={recipeInputs}
                setRecipeInputs={setRecipeInputs}
                form={form}
                recipe={recipe}
                hideOutputFolder
              />
            </FormProvider>
          ) : (
            <NotFoundItems />
          )}
        </div>
      </div>
    </>
  )
})

export default ComfyUIWorkflowSteps
