export const tipsAndTricksFolder = [
  // {
  //   id: "feature",
  //   name: "Feature Updates",
  //   path: "/tips-and-tricks/feature",
  // },
  // {
  //   id: "tutorials",
  //   name: "Tutorials",
  //   path: "/tips-and-tricks/tutorials",
  // },

  {
    id: "promptbook",
    name: "Prompt Book (EN)",
    path: "/tips-and-tricks/prompt-book?lang=en",
  },
  {
    id: "promptbook",
    name: "Prompt Book (VN)",
    path: "/tips-and-tricks/prompt-book?lang=vn",
  },
  {
    id: "faq",
    name: "FAQ",
    path: "/tips-and-tricks/faq",
  },
]

const Version1_1 = {
  title: "GAIA version 0.1",
  subTitle:
    "We are thrilled to announce the release of GAIA version 0.1, packed with exciting new features and enhancements to enhance your creative journey. This update focuses on unleashing imaginations, collaborative capabilities, personalized remix experiences, and a better user experience for everyone.",
  id: "0.1",
  date: "12 Jun, 2023",
  active: false,
  content: {
    title: "Let's dive into the captivating features that await you:",

    features: [
      {
        id: "1",
        title: "Unleash imaginations & explore inspirations:",
        content: [
          "<p>Efficient Image Library Management: GAIA now supports single and multi-image uploads and deletions, empowering you to effortlessly manage your image library. Easily organize and maintain your content with the ability to drag and drop images between folders.</p>",
          "<p>Enhanced Image Tagging: Add tags to each image (even SD & non-SD image*) for seamless searching. This powerful feature allows you to categorize and find your images effortlessly.</p>",
          "<p>Matrix Table Image Search: Based on the tags, you can set the filter result in the Matrix table.</p>",
          "<p>Comprehensive Image Metadata:  Gain deeper insights into each image with access to all related metadata. Explore prompts, negative prompts, training models, controlnet, StableDiffusion settings, relevant images, owner information, and upload timestamps, all in one place.</p>",
          "<p>Pin/Mark Favorites: Easily mark your favorite images and folders to create personalized collections for quick access.</p>",
        ],
      },
      {
        id: "2",
        title: "Sharing and collaboration features:",
        content: [
          "<p>Streamlined Project Management: Seamlessly manage multiple projects within GAIA. Upload and organize both AI-generated and normal images effortlessly. Leverage powerful team collaboration features, including real-time comment tracking, folder permissions, and image sharing, to enhance collaboration with your team.</p>",
          "<p>Collaborative Communication: Comment on images and tag other users, facilitating seamless collaboration and idea sharing; also foster collaboration and communication within the GAIA community.</p>",
          "<p>Permission Settings: Customize access and collaboration rights with flexible permission settings. Assign viewer, editor roles to control individual image access within your projects.</p>",
          "<p>Flexible sharing: Freely share your images with multiple workspaces, including individual workspace, GAIA Space, Ather Labs Space, etc.</p>",
          "<p>Showcase your artworks to social: Share your creative endeavors with ease by posting images to social platforms like Facebook, Twitter, and LinkedIn.</p>",
        ],
      },
      {
        id: "3",
        title: "Remix Experience:",
        content: [
          "<p>Whether you're a beginner or an experienced user, you can select any existing StableDiffusion (SD) image and quickly remix and unleash your creativity by editing prompts and negative prompts, choose a new training model natively supported on GAIA, and choose the output quality to suit your preferences. (all settings are optional to set)</p>",
        ],
      },
      {
        id: "4",
        title: "Agent Scheduler:",
        content: [
          "<p>Efficient Task Management: Keep track of your Remix Tasks generated directly on GAIA with the new Agent Scheduler.</p>",
          "<p>Control the pending tasks: You can pause, remove, edit the settings and rearrange the order of pending tasks effortlessly.</p>",
          "<p>Stop running tasks if necessary and rerun tasks at any time</p>",
          "<p>History log: All completed, failed, canceled tasks are tracked and can be checked in the History tab.</p>",
        ],
      },
      {
        id: "5",
        title: "Generate GAIA private key",
        content: [
          "<p>Generate your GAIA private key to connect the local StableDiffusion with GAIA to use your Local StableDiffusion (or can say your local node) to run the Remix tasks on GAIA quickly.</p>",
          "<p>By doing this, the Remix outputs still will be uploaded on GAIA directly.</p>",
          "<p>1 key can be connected with multi nodes.</p>",
        ],
      },
      {
        id: "6",
        title: "Better UX - Friendly for everyone:",
        content: [
          "<p>Activity Notifications: Stay updated on relevant activities with notifications. GAIA will keep you informed about important events, ensuring you never miss out on the latest developments.</p>",
          "<p>Seamless Google Sign-In: Enjoy a streamlined login experience with the option to quickly sign in using your Google account.</p>",
          "<p>Updated tutorials: By giving anyone a simple instruction & intro about GAIA's key features.</p>",
        ],
      },
    ],
    note: "",
  },
}

const Version1_2 = {
  title: "GAIA version 0.1.2",
  subTitle:
    "Hello Creators, We're so excited to release GAIA version 0.1.2, well packed with exciting updates: new features, and improvements.",
  id: "0.1.2",
  date: "30 Wed, 2023",
  active: false,
  content: {
    title: "Let's dive into the captivating features that await you:",

    features: [
      {
        id: "1",
        title:
          "Ready-to-use Generative AI Recipes (or call as “Recipes”) to create dazzling visuals with a few clicks. Our recipes have been tested and proven by the expertises (Artists, Creators, Engineers). Including: ",
        content: [
          "<p>Image Upscaler: The Image Upscaler recipe is well packed and set by using Stable Diffusion to upscale images, you can enhance the visual appeal of your content, recover the details, improve the quality of low-resolution images to x2 - x3 - x4 simultaneously.</p><p>- We suggest you to use 2 popular models for the Image Upscaler recipe:  Flat2D animerge V3 and Children's Stories v1 Tool-Anime.</p>",
          "<p>Hidden Object Game Asset (Low Res): This recipe is more about generating maps for hidden object games. You can input prompts and select the appropriate model to quickly generate the desired maps.</p><p>- We suggest you to use this popular models for this recipe: A-Zovya RPG Artist Tools v3</p>",
          "<p>Text To Gif: You can turn Text to Gif instantly to generate flawless Gif with minimal effort.</p>",
          "<p>Image To Gif: Beside of using text, you can turn Image to Gif instantly with the AI Gif Generator.</p>",
        ],
      },
      {
        id: "2",
        title: "Generate Again:",
        content: [
          "<p>Save and rename any tweaked recipe for future use. Reuse the same recipe and parameters to generate again effortlessly.</p>",
        ],
      },
      {
        id: "3",
        title: "Send to Recipe:",
        content: [
          "<p>You can select any images on GAIA and send it to available Recipe(s) as a input image to generate the new images or gifs.</p>",
        ],
      },
      {
        id: "4",
        title: "Improvements:",
        content: [
          "<p>Switch between images easily with the keyboard: Easily switch between images with keyboard shortcuts. Click to view image details in convenient pop-up mode and seamlessly switch between other images.</p>",
          "<p>Upload multi images:</p><p>- Up to 20 images can be uploaded at once</p><p>- Up to 20 images can be uploaded at once</p>",
          "<p>Folder Share Permission:</p><p>- Allow Editors to create sub-folders in Original folder (parent folder)</p><p>- Allow Editors to delete sub-folders even if created by anyone</p><p>- Allow Editors to delete/move pictures inside the shared folder</p><p>- Allow Editors and Owner to recover any deleted sub-folders</p>",
          "<p>Image preview card - UI:</p><p>- Show the image name, owner name, stats (like, comment, download)</p>",
        ],
      },
    ],
    note: "Note: As all recipes have been tested by us and all default prompt, negative prompt and parameters (Model, Denoising Strength) you see are standard presets and highly recommend from us. However you can play around with the new parameters if you want",
  },
}

const Version1_2_1 = {
  title: "GAIA version 0.1.3",
  subTitle:
    "Hello Creators, We're so excited to release GAIA version 0.1.3, well packed with new features and improvements.",
  id: "0.1.3",
  date: "2 Aug, 2023",
  active: false,
  content: {
    title: "Let's dive into the captivating features that await you:",
    note: "",
    features: [
      {
        id: "1",
        title:
          "Ready-to-use Generative AI Recipes (or call as “Recipes”) to create dazzling visuals with a few clicks.",
        content: [
          "<p>New Recipe - SDXL v1.0 Generate high-resolution images with a variety of styles, concepts, and ratios</p>",
          "<p>Send to Recipe - Feature improvement: You can select any images on GAIA and send it to available Recipe(s) as a input image to generate the new images or gifs. By clicking “Send to Recipe” button, it will send the selected image and its metadata to the new recipe</p>",
          "<p>Recent : Recent will display the folders and files you just opened recently for browsing easily.</p>",
        ],
      },
      {
        id: "2",
        title: "Improvements:",
        content: [
          "<p style=color:#E8E8E8>Multi selection:</p> <p>- Allow to select multi images to add or edit tags; to move to another folder, to delete, or to download to users' local device</p><p>- Just can download 10 images at once</p>",
          "<p style=color:#E8E8E8>Press Esc to close the expandation view</p>",
          "<p style=color:#E8E8E8>Sort images by name in alphabetical order</p>",
          "<p style=color:#E8E8E8>Allow users to remove themselves from the shared folders</p>",
          "<p style=color:#E8E8E8>Adjusted UIUX for some flows</p>",
        ],
      },
    ],
  },
}

const Version1_2_2 = {
  title: "GAIA version 0.1.4",
  subTitle:
    "Hello Creators, We're so excited to release GAIA version 0.1.4, well packed with new features and improvements.",
  id: "0.1.4",
  date: "11 Oct, 2023",
  active: false,
  content: {
    title: "Let's dive into the captivating features that await you:",
    note: "",
    features: [
      {
        id: "1",
        title: "Recipes",
        content: [
          "<p>New useful Recipes</p><p>- Remove Background:  Extract the subject from an image with remarkable precision</p><p>- Remix ver 2: quickly remix any existing StableDiffusion (SD) images and unleash your creativity by editing prompts and choose the output quality to suit your preferences</p><p>- Sipher Gun: Creatively design Gun for Sipher game based on presets</p><p>- Sipher - Game Avatar: Creatively design Avatar for Sipher game account based on presets</p><p>- Sipher - Game Avatar Frame: Creatively design Avatar Frame based on presets</p>",
          "<p style=color:#E8E8E8>Simplify UX for each Recipe</p>",
          "<p style=color:#E8E8E8>Convert REMIX feature to REMIX Recipe with a better UX</p>",
        ],
      },
      {
        id: "2",
        title: "Social Sharing",
        content: [
          "<p style=color:#E8E8E8>You can share your images on social without adjust the image setting</p>",
          "<p style=color:#E8E8E8 style=color:#E8E8E8>Others can view your images through the sharing link</p>",
          "<p style=color:#E8E8E8>If they haven't logged in yet: When they click to the link, it will direct to GAIA page and show the popup details with image, image name, and prompts</p>",
          "<p style=color:#E8E8E8>If they logged in, it will direct them to GAIA page and show the popup details (full parameters) if you are granted access</p>",
          "<p style=color:#E8E8E8>If they don't have the access, they can send request to you</p>",
          "<p style=color:#E8E8E8>And as a file owner, you can accept or ignore that request</p>",
        ],
      },
      {
        id: "3",
        title: "Folder Layout",
        content: [
          "<p style=color:#E8E8E8>Show folders and images under 1 folder</p>",
          "<p style=color:#E8E8E8>Can search items (folder, image) from that folder</p>",
        ],
      },
      {
        id: "4",
        title: "Agent Scheduler",
        content: [
          "<p style=color:#E8E8E8>Change layout</p>",
          "<p style=color:#E8E8E8>Show the tasks used RECIPES to run </p>",
        ],
      },
      {
        id: "5",
        title: "Universal Search",
        content: [
          "<p style=color:#E8E8E8>Allow users to search items (folders, images) by name, model, parameters, tag</p>",
          "<p style=color:#E8E8E8>Remember the historical searching words</p>",
          "<p style=color:#E8E8E8>Search Results page</p>",
        ],
      },
      {
        id: "6",
        title: "Remix all images (AI and non-AI images)",
        content: [],
      },
    ],
  },
}

const Version1_3 = {
  title: "GAIA version 0.1.5",
  subTitle:
    "Hello Creators, We're so excited to release GAIA version 0.1.5, well packed with new features and improvements.",
  id: "0.1.5",
  date: "2 Aug, 2023",
  active: false,
  content: {
    title: "Feature Improvements",
    note: "",
    features: [
      {
        id: "1",
        title: "Recipes",
        content: [
          `<p>Release new useful Recipes for Businesses</p>
          <p>- AI Mixer (applied IPAdapter):  allows users to mix 2 images (objects) and transform into a new object. Prompts can be used to control output with different models</p>
          <p>- Customize Art Toy ver 2.0 (applied IPAdapter): allows users to upload an Art Toy photo and choose any theme to upload as a reference image to change the texture on the Art Toy itself and the background at the same time.</p>
          <p>- Brand Logo Customization: allows users to upload logo image and create various customizations with prompts and models.</p>
          <p>- Commercial Photoshoot: allows users to upload product image and select the template. Or can create with your own prompts.</p>
          <p>- Artistic AI QR Code: allows users to input URL and select template to generate the Artistic AI QR codes quickly.</p>
          <p>- Upgraded Hidden Object Game Map: allows users to choose a theme/template to create various nearly production-ready maps. Users can tweak the prompts or add the reference images to generate the output according to their preferences.</p>
          `,
        ],
      },
      {
        id: "2",
        title: "Enhance UX & fix bugs",
        content: [
          "<p style=color:#E8E8E8>Allow users to download upto 50 images per time</p>",
          "<p style=color:#E8E8E8 style=color:#E8E8E8>Fix UIUX bugs</p>",
        ],
      },
    ],
  },
}

const Version0_2 = {
  title: "GAIA version 0.2",
  subTitle:
    "Hello GAIAr, We are excited to release GAIA 0.2 with many exciting new features and improvements that will bring more value to Creators, Businesses, Marketers to save time and effort when developing ideas or creating new images",
  id: "0.2",
  date: "2 Aug, 2023",
  active: false,
  content: {
    title: "New Features",
    note: "",
    features: [
      {
        id: "1",
        title: "Create Workflow and Share",
        content: [
          `<p>Create New macro: Users will be able to program recipes into complete end-to-end macros to autonomously run specific, pre-made complex multiple steps GenAI tasks</p>
         `,
          `<p>Save as Draft first & publish it anytime</p>
         `,
          `<p>Add Tags and Description for each Workflow</p>
         `,
          `<p>Duplicate and Edit: Duplicate workflow and edit any steps/ parameters</p>
         `,
          `<p>Share your workflow to anyone in the same workspace and let others can use and/or edit your workflow</p>
         `,
          `<p>Share your workflow to anyone on GAIA - Share to Explore board and let others can view or use your workflow </p>
         `,
          `<p>Filter & Sort & Search</p>
         `,
          `<p>Select multi macros to delete, share, pin</p>
          `,
        ],
      },
      {
        id: "2",
        title: "Create your own Art Style",
        content: [
          `<p>Create your own AI Art Style by uploading upto 8 images</p>
          `,
          `<p>Can adjust the impact strength for each image</p>
          `,
          `<p>Can test style by using to generate AI images before publishing it</p>
          `,
          `<p>Share your Style to anyone in the same workspace and let others can use or duplicate your style</p>
          `,
          `<p>Share your Style to anyone on GAIA - Share to Explore board and let others can view or use your Style</p>
          `,
          `<p>Filter & Sort & Search</p>
          `,
          `<p>Select multi Styles to delete, share, pin</p>
          `,
        ],
      },
      {
        id: "3",
        title: "Explore Board",
        content: [
          `<p>Showcase all Images, Articles, macros and Creators on GAIA</p>
          `,
          `<p>Users can create Articles and contribute the value knowledge, tips, sharing to the community</p>
          `,
          `<p>Users can Enable their images to Explore and turn off anytime</p>
          `,
          `<p>Users can browse to view all public Creators on GAIA</p>
          <p>- Can follow their favorite Creators' profile and unfollow anytime </p>
          <p>- Can view their favorite Creators' images, macros, articles </p>
          `,
          `<p>Filter & Sort & Search</p>
          `,
          `<p>View and interact (like, comment) and even try to create your own images based on your selection</p>
          `,
        ],
      },
      {
        id: "4",
        title: "Creator Profile",
        content: [
          `<p style=color:#E8E8E8>You can enable your profile to public (Everyone on GAIA can see your Profile on Explore board)</p>
          `,
          `<p style=color:#E8E8E8>Can turn to Private mode anytime</p>
          `,
        ],
      },
      {
        id: "5",
        title: "Single Recipes",
        content: [
          `<p style=color:#E8E8E8>New Recipes</p>
          <p>- Inpainting</p>
          <p>- Outpainting</p>
          <p>- Sketch to Image</p>
          <p>- FaceSwap</p>
          <p>- Upscaler</p>
          <p>- Tiled Upscaler</p>
          <p>- AI Mixer</p>`,
          `<p style=color:#E8E8E8>Updated Recipes</p>
          <p>- Apply Lora LCM for some recipes: Upscaler, Tiled Upscaler</p>
          `,
        ],
      },
      {
        id: "6",
        title: "Workspace",
        content: [
          `<p style=color:#E8E8E8>Each user has their own workspace and can invite their friends/ members to join the workspace</p>`,
          `<p style=color:#E8E8E8>Each user can be a member of multi workspaces at the same time</p>`,
          `<p style=color:#E8E8E8>=> Subscription plans will be released in the next version</p>`,
        ],
      },

      {
        id: "7",
        title: "Lite mode for Single Recipes (Alpha)",
        content: [
          `<p style=color:#E8E8E8>Allow users even not sign in to GAIA platform can enjoy the Recipes</p>
            <p>- Miss Universe</p>
            <p>- Halloween Spookify Me</p>
            <p>- QR Code</p>
            <p>- Logo Art</p>
            <p>- Product Photoshoot</p>
            <p>- Transform your photo to Anime</p>
            <p>- Customize your ArtToy  </p>
          `,
          `<p style=color:#E8E8E8>Every new users will have 20 Free CREDITS after the 1st sign-in to use for generating image on this Lite mode</p>`,
          `<p>1 CREDIT = 1 Generation time </p>`,
          `<p style=color:#E8E8E8>User can earn more CREDITs by</p>
          <p>- Give feedback for each style (1 CREDIT free)</p>
          <p>- Flex on social (1 CREDIT/time & maximum 3 times/image)</p>
          <p>- Invite friends to join GAIA (5 CREDITS/time)</p>

          `,
        ],
      },

      {
        id: "8",
        title: "New Updates",
        content: [`<p style=color:#E8E8E8>Update new UI/UX for the whole platform</p>`],
      },
    ],
  },
}

const Version0_2_1 = {
  title: "GAIA version 0.2.1",
  subTitle:
    "Hello GAIAr, We are excited to release GAIA 0.2.1 with many exciting new features and improvements that will bring more value to Creators, Businesses, Marketers to save time and effort when developing ideas or creating new images",
  id: "0.2.1",
  date: "2 Jan, 2024",
  active: false,
  content: {
    title: "New Features",
    note: "",
    features: [
      {
        id: "1",
        title: "Supports new SignUp & SignIn methods",
        content: [
          `<p style=color:#E8E8E8>Google account</p>
         `,
          `<p style=color:#E8E8E8>Email & Passcode or Email & Password</p>
         `,
          `<p style=color:#E8E8E8>Support Solana Wallets thru some wallet providers: Metamask, Solflare, Torus, Phantom, Coinbase, MathWallet, Trust, Coin98 </p>
         `,
          `<p style=color:#E8E8E8>Allow users to link more than 1 Solana Wallets with GAIA Account: <span >Can not link 1 Wallet with multi-GAIA Accounts at once</span> </p>
         `,
        ],
      },
      {
        id: "2",
        title: "Account management",
        content: [
          `<p style=color:#E8E8E8>Change to a new email address (can change 1 time in 60 days)</p>
          `,
          `<p style=color:#E8E8E8>Link and unlink the Solana Wallets with their GAIA account</p>
          `,
          `<p style=color:#E8E8E8>Change Password</p>
          `,
          `<p style=color:#E8E8E8>Delete GAIA account</p>
          `,
          `<p style=color:#E8E8E8>Log out from all devices</p>
          `,
        ],
      },
      {
        id: "3",
        title: "Workspace management",
        content: [
          `<p style=color:#E8E8E8>Invite Guests: Guest can view any specific files you shared with them and leave comments</p>
          `,
          `<p style=color:#E8E8E8>Upgrade from Guest to Member:  Becoming a member will count towards your Workspace Membership count. and will be charged a subscription fee</p>
          `,
          `<p style=color:#E8E8E8>Downgrade from Member to Guest:</p>
          <p>- All the folders, images, styles, workflow that user created under the “Member” role of that Workspace , will transfer the ownership of those files to Workspace owner  </p>
          <p>- And that user just can view and leave comment as a Guest </p>
          `,
          `<p style=color:#E8E8E8>Switch from Workspace to Workspace</p>
          `,
          `<p style=color:#E8E8E8>Currently, each Workspace just can include upto 3 members only</p>
          `,
          `<p style=color:#E8E8E8>1 Account can only be owner of 1 Workspace at max</p>
          `,
        ],
      },
      {
        id: "4",
        title: "Explore Board",
        content: [
          `<p>Showcase all Images, Articles, macros and Creators on GAIA</p>
          `,
          `<p>Users can create Articles and contribute the value knowledge, tips, sharing to the community </p>
          `,
          `<p>Users can Enable their images to Explore and turn off anytime </p>
          `,
          `<p>Users can browse to view all public Creators on GAIA:</p>
          <p>- Can follow their favorite Creators' profile and unfollow anytime </p>
          <p>- Can view their favorite Creators' images, macros, articles  </p>
          `,
          `<p>Search, Filter and sort</p>
          `,
          `<p>View and interact (like, comment) and even try to create your own images based on your selection</p>
          `,
        ],
      },
      {
        id: "5",
        title: "Inpainting - Image Editing",
        content: [
          `<p style=color:#E8E8E8>Update new UIUX</p>
          `,
          `<p style=color:#E8E8E8>Support Smart Selector to quickly select any objects in 1 click</p>
          `,
          `<p style=color:#E8E8E8>Support Smart Eraser to quickly erase (undo) the selected/ brushed areas</p>
          `,
          `<p style=color:#E8E8E8>After selecting any areas/ objects: </p>
          <p>- Users can remove it out of the image </p>
          <p>- Users can edit that area by prompting </p>
          `,
          `<p style=color:#E8E8E8>Can select any results and continue editing from that </p>
          `,
          `<p style=color:#E8E8E8>Can rerun the editing tasks again to get more random results with the same input and settings</p>
          `,
        ],
      },
      {
        id: "6",
        title: "Instruction",
        content: [
          `<p style=color:#E8E8E8>We just release some simple instructions to let new users easily catch the new features: Recipe, workflow, Art Style, Inpainting.</p>`,
        ],
      },

      {
        id: "7",
        title: "Comments on macros, Style",
        content: [
          `<p style=color:#E8E8E8>Allow users in 1 workspace can view the shared macros, styles and leave comments </p>`,
        ],
      },
      {
        id: "8",
        title: "Shortcut",
        content: [`<p style=color:#E8E8E8>Press: Shift Q to view some shortcuts we are supporting</p>`],
      },

      {
        id: "9",
        title: "New Updates",
        content: [`<p style=color:#E8E8E8>New UIUX for the Homespace, Explore and other pages</p>`],
      },
    ],
  },
}

const Version0_2_2 = {
  title: "GAIA version 0.2.2",
  subTitle:
    "Hello GAIAr, We are excited to release GAIA 0.2.2 with many exciting new features and improvements that will bring more value to Creators, Businesses, Marketers to save time and effort when developing ideas or creating new images",
  id: "0.2.2",
  date: "5 Apr, 2024",
  active: false,
  content: {
    title: "New Features",
    note: "",
    features: [
      {
        id: "1",
        title: "Cloud-based ComfyUI workflow",
        content: [
          `<p style=color:#E8E8E8>Allow users to try pre-set models and nodes to experience rapid results from AI generative text to images to GIFs to art style.</p>
         `,
          `<p style=color:#E8E8E8>Share with other workspace members and be able to collaborate together</p>
         `,
          `<p style=color:#E8E8E8>Can comment on each node, step</p>
         `,
          `<p style=color:#E8E8E8>Convert ComfyUI workflow to simple Recipe for end-users to use </p>
         `,
          `<p style=color:#E8E8E8>Share your ComfyUI workflow or | and Recipes for discovery and for the GAIA community to view and use widely</p>
         `,
          `<p style=color:#E8E8E8>Tasks tracker: to check the running progression and cancel anytime</p>
         `,
        ],
      },
      {
        id: "2",
        title: "Wildcards",
        content: [
          `<p style=color:#E8E8E8>Allow users upload their own wildcards to the library</p>
          `,
          `<p style=color:#E8E8E8>Can edit, delete</p>
          `,
          `<p style=color:#E8E8E8>Can share with your own workspace members or | and GAIA members</p>
          `,
          `<p style=color:#E8E8E8>Can use your Wildcards and GAIA Wildcards and others' Wildcards in any recipes (Need to add to your Wildcards list first)</p>
          `,
        ],
      },
      {
        id: "3",
        title: "Creator Profile",
        content: [
          `<p style=color:#E8E8E8>Users can enable their profile to public ⇒ anyone can view and follow</p>
          `,
          `<p style=color:#E8E8E8>Users can turn their public profile to private</p>
          `,
          `<p style=color:#E8E8E8>Users can follow / unfollow the others' profile</p>
          `,
        ],
      },
      {
        id: "4",
        title: "New recipe",
        content: [
          `<p style=color:#E8E8E8>1-click Image Generator</p>
          `,
        ],
      },
      {
        id: "5",
        title: "Update UIUX",
        content: [
          `<p style=color:#E8E8E8>Suggested Recipes for Image</p>
          `,
          `<p style=color:#E8E8E8>Suggested Recipes for Wildcard</p>
          `,
        ],
      },
    ],
  },
}

const Version0_3 = {
  title: "GAIA version 0.3",
  subTitle:
    "We are excited to release GAIA 0.3 with many exciting new features and improvements that will bring more value to Creators, Businesses, Marketers. Let AI Agents give us superpowers!",
  id: "0.3",
  date: "14 May, 2024",
  active: false,
  content: {
    title: "New Features",
    note: "",
    features: [
      {
        id: "1",
        title: "Sign in with new credentials",
        content: [
          `<p style=color:#E8E8E8>Sign in with Farcaster ID</p>
         `,
          `<p style=color:#E8E8E8>Sign in with Ethereum, Polygon Wallets</p>
         `,
        ],
      },
      {
        id: "2",
        title: "Speed up the generation time",
        content: [
          `<p style=color:#E8E8E8>With “Fast” Recipes, we improved the generation time (upto 15s)</p>
          `,
        ],
      },
      {
        id: "3",
        title: "First time experience",
        content: [
          `<p style=color:#E8E8E8>Allow everyone can experience and try out our Recipes, Macros without login (Max 5 times)</p>
          `,
          `<p style=color:#E8E8E8>By logging in, users will receive 1000 free CREDITS, and try more times and remove the watermark on the results</p>
          `,
        ],
      },
      {
        id: "4",
        title: "Credit consumption",
        content: [
          `<p style=color:#E8E8E8>Your credit balance will be deducted based on the generation runtime of each task</p>
          `,
        ],
      },
      {
        id: "5",
        title: "Do Quests and earn $WOW Points",
        content: [
          `<p style=color:#E8E8E8>Anyone can play Quests and earn $WOW Points</p>
          `,
          `<p style=color:#E8E8E8>Can tip $WOW points to other users by commenting or via profile</p>
          <p style=color:#E8E8E8>E.g: Leave a comment “I love your artwork. 100 $WOW for you”</p>
          `,
          `<p>We will allow users to convert $WOW to the exclusive cosmetics, rewards and web3 assets later</p>`,
        ],
      },
      {
        id: "6",
        title: "Invite Friends",
        content: [
          `<p style=color:#E8E8E8>Users can invite friends to join GAIA via referral link</p>
          `,
          `<p style=color:#E8E8E8>The more you invite, more $WOW you can earn</p>
          `,
        ],
      },
      {
        id: "7",
        title: "Feedback",
        content: [
          `<p style=color:#E8E8E8>Users can leave feedback and rating on each task</p>
          `,
        ],
      },
      {
        id: "8",
        title: "Censor NSFW contents",
        content: [
          `<p style=color:#E8E8E8>Runs photo scanning algorithm and will add “NSFW” & “sNSFW” flags to each photo</p>
          `,
          `<p style=color:#E8E8E8>With NSFW: output will not be displayed</p>
          `,
          `<p style=color:#E8E8E8>With sNSFW: output will be displayed privately, and users cannot share to Explore</p>
          `,
        ],
      },
      {
        id: "9",
        title: "Superspeed - gen speed improvement",
        content: [
          `<p style=color:#E8E8E8>All end-to-end recipes are categorized into 3 groups: Fast, Advance & Exclusive for selected Workspace</p>
          `,
        ],
      },
      {
        id: "10",
        title: "Leaderboard",
        content: [
          `<p style=color:#E8E8E8>Top 50 high-performance users will be listed on these boards</p>
          <p style=color:#E8E8E8>+ Popular creators: Most followed, downloaded and favorited</p>
          <p style=color:#E8E8E8>+ Hottest images: Most favourited and viewed</p>
          <p style=color:#E8E8E8>+ Popular macros: Most used, favourited and viewed</p>
          <p style=color:#E8E8E8>+ Popular recipes: Most used, favourited and viewed</p>
          <p style=color:#E8E8E8>+ Popular styles: Most used, favourited and viewed</p>
          `,
        ],
      },
      {
        id: "11",
        title: "Recipe update",
        content: [
          `<p style=color:#E8E8E8>Allow users to create a Style quickly once trying recipe</p>
          `,
          `<p style=color:#E8E8E8>Allow users to save generative outputs into the shared folders (if users have the “Edit” permission)</p>
          `,
          `<p style=color:#E8E8E8>Allows users to upload input images with a maximum size of 25 MB</p>
          `,
        ],
      },

      {
        id: "12",
        title: "Allow users to copy recipe setting from other users' image",
        content: [],
      },
      {
        id: "13",
        title: "Fix bugs",
        content: [],
      },
    ],
  },
}

const Version0_4 = {
  title: "GAIA version 0.4",
  subTitle:
    "We are excited to release GAIA 0.4 with many exciting new features and improvements that will bring more value to Creators, Businesses, Marketers. Let AI Agents give us superpowers!",
  id: "0.4",
  date: "16 Jul, 2024",
  active: false,
  content: {
    title: "New Features",
    note: "",
    features: [
      {
        id: "1",
        title: "Sign in with new credential",
        content: [
          `<p style=color:#E8E8E8>Sign in with Telegram ID</p>
         `,
        ],
      },
      {
        id: "2",
        title: "GAIA dapp on Telegram",
        content: [
          `<p style=color:#E8E8E8>@protogaia_bot</p>
          <p style=color:#E8E8E8>⇒ to let user sign in to GAIA quickly Telegram and use GAIA directly on Telegram bot</p>
          `,
        ],
      },
      {
        id: "3",
        title: "Improve Inpainting recipe",
        content: [
          `<p style=color:#E8E8E8>Upgrade SAM model</p>
          `,
          `<p style=color:#E8E8E8>Upgrade new remove background model</p>
          `,
          `<p style=color:#E8E8E8>Update new UIUX</p>
          <p style=color:#E8E8E8>- Allow users to continue editing images with Remove background, Upscale, Remix, Enhance Details</p>
          `,
        ],
        images: [`/images/release-note/improve-inpainting-recipe.png`],
      },
      {
        id: "4",
        title: "Upgrade ComfyUI",
        content: [
          `<p style=color:#E8E8E8>Allow users to download and upload ComfyUI workflow to GAIA</p>
          `,
          `<p style=color:#E8E8E8>Support more default ComfyUI workflow for easier experiment </p>
          `,
        ],
        images: [`/images/release-note/upgrade-comfyuI.png`],
      },
      {
        id: "5",
        title: "GAIA Room",
        content: [
          `<p style=color:#E8E8E8>Allow users to create Private and Public Room</p>
          `,
          `<p style=color:#E8E8E8>Allow Room ‘s Owner and Admin to add Recipes into the Room and let members use to generate directly in the Room</p>
          <p style=color:#E8E8E8>- Recipes made by GAIA and by that Owner/ Admin will be allowed to add</p>
          `,
          `<p style=color:#E8E8E8>Users can chat, interact with messages and generated tasks</p>`,
          `<p style=color:#E8E8E8>Can invite friends to join via Room link</p>`,
          `<p style=color:#E8E8E8>Currently, we only allow specific Workspaces to create Rooms. The remaining users can join public rooms but cannot create new Rooms</p>`,
        ],
        images: [`/images/release-note/gaia-room.png`],
      },
      {
        id: "6",
        title: "Prompt Library",
        content: [
          `<p style=color:#E8E8E8>You can copy and save the prompt sets you like in the library</p>
          `,
          `<p style=color:#E8E8E8>And when generating images, you can quickly pull them from the prompt library to use without having to memorize them manually</p>
          `,
        ],
        images: [`/images/release-note/prompt-library.png`],
      },
    ],
  },
}

const Version0_5 = {
  title: "GAIA version 0.5",
  subTitle:
    "We are excited to release GAIA 0.5 with many exciting new features and improvements that will bring more value to Creators, Businesses, Marketers.",
  id: "0.5",
  date: "6 Sep, 2024",
  active: true,
  content: {
    title: "New Features",
    note: "",
    features: [
      {
        id: "1",
        title: "Upgraded ComfyUI with new UIUX",
        content: [
          `<p style=color:#E8E8E8>ComfyUI upgraded version with new interface, streamlining users’ creative production with user-friendly design</p>
          <p style=color:#E8E8E8>- Keep track of all generated tasks at Tasks History</p>
          <p style=color:#E8E8E8>- Check, edit, share, or delete your published Recipe at Published to Recipe</p>
          <p style=color:#E8E8E8>- Convert the workflow to Recipe for others to use</p>
          <p style=color:#E8E8E8>- Add Comment in working canvas</p>
          <p style=color:#E8E8E8>- Share the workflow to your workspace, or to Explore to community</p>
          <p style=color:#E8E8E8>- Find buttons to Preview, Duplicate, Pin, Download, or Delete the workflow in More</p>
          <p style=color:#E8E8E8>- Save any latest change or update in your workflow</p>
          <p style=color:#E8E8E8>- Browse different nodes more easily in Node Library</p>
          <p style=color:#E8E8E8>- Click on Show Log to monitor the workflow’s progress</p>
         `,
        ],
        images: [`/images/release-note/new-comfy-ui.png`],
      },
      {
        id: "2",
        title: "Smart Search Engine with Text & Image",
        content: [
          `<p style=color:#E8E8E8>Allows users to search any artwork & its similar results by text or image</p>
          `,
        ],
        images: [`/images/release-note/smart-search.png`],
      },
      {
        id: "3",
        title: "GAIA Room - Upgraded with new features",
        content: [
          `<p style=color:#E8E8E8>Allow users to message, reply to images by quoting the full generated task or quoting a specific image in a task</p>
            <p style=color:#E8E8E8>- Quote a specific generated image in a task</p>
            <br/>
            <img src=https://cdn.protogaia.com/resources/public/images/release-note/quote-image.png alt=img />
            <br/>
            <p style=color:#E8E8E8>- Quote a full generated task</p>
             <br/>
            <img src=https://cdn.protogaia.com/resources/public/images/release-note/quote-task.png alt=img />

          `,
          `<p style=color:#E8E8E8>Share your Room link and let others join the fun</p>
            <br/>
            <img src=https://cdn.protogaia.com/resources/public/images/release-note/share-room.png alt=img />
          `,
          `<p style=color:#E8E8E8>Express yourself with new emoji reactions</p>
            <br/>
            <img src=https://cdn.protogaia.com/resources/public/images/release-note/emoji-reactions.png alt=img />
          `,
        ],
      },
      {
        id: "4",
        title: "Flux model supported on ComfyUI",
        content: [
          `<p style=color:#E8E8E8>Use Flux model easily and conveniently right on GAIA’s ComfyUI canvas</p>
          <p style=color:#E8E8E8>- Experience faster production process</p>
          <p style=color:#E8E8E8>- Leverage advanced features to create complex and intricate work</p>
          <p style=color:#E8E8E8>- Easy to integrate to your current workflow</p>
          `,
        ],
        images: [`/images/release-note/flux-model.png`],
      },
    ],
  },
}

export const VERSION = [
  Version0_5,
  Version0_4,
  Version0_3,
  Version0_2_2,
  Version0_2_1,
  Version0_2,
  Version1_3,
  Version1_2_2,
  Version1_2_1,
  Version1_2,
  Version1_1,
] as {
  title: string
  subTitle: string
  id: string
  date: string
  active: boolean
  content: {
    title: string
    note: string
    features: {
      id: string
      title: string
      content: string[]
      images?: string[]
    }[]
  }
}[]
