import { useAuth } from "@/providers/authContext"
import { AnimatePresence, motion } from "framer-motion"
import { twMerge } from "tailwind-merge"

const convertBytesToGB = (bytes: number) => {
  return bytes / 1024 / 1024 / 1024
}

const ProgressbarStorage = ({ className }: { className?: string }) => {
  const { userInfoQuery } = useAuth()

  //byte to GB
  const currentSize = convertBytesToGB(userInfoQuery?.data?.storageSize ?? 0)
  const totalSize = convertBytesToGB(parseInt(userInfoQuery?.data?.subscription.cloudStorage ?? "0"))
  const progress = (currentSize / totalSize) * 100
  const description = `${currentSize.toFixed(2)} GB of ${totalSize.toFixed(2)} GB`

  return (
    <AnimatePresence>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: "auto", opacity: 1 }}
        exit={{ height: 0, opacity: 0 }}
        className={twMerge("overflow-hidden mt-4 mb-2", className)}
      >
        <div className="relative h-1 overflow-hidden rounded-lg bg-atherGray-800">
          <motion.div
            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
            style={{
              transition: "width 0.6s ease-in-out",
              width: `${progress}%`,
              height: "100%",
              backgroundColor: "#5E17EB",
              backgroundSize: "1rem 1rem",
            }}
          />
        </div>
        {description && <p className="mt-1 text-xs text-atherGray-500">{description}</p>}
      </motion.div>
    </AnimatePresence>
  )
}

export default ProgressbarStorage
