import client from "@/api/client"
import { GetWorkflowsResult, WorkflowItem, WorkflowSortOptions, WorkflowStatus, WorkflowViewOptions } from "@/api/sdk"
import endOfDay from "date-fns/endOfDay"
import startOfDay from "date-fns/startOfDay"
import { createAuthenticatedInfiniteQuery } from "../createAuthenticatedQuery"

export interface UseWorkspaceWorkflowInfiniteQueryVariables {
  view: WorkflowViewOptions
  sortBy?: WorkflowSortOptions
  statuses?: WorkflowStatus[]
  search?: string
  fromDate?: Date
  toDate?: Date
  onlyDiscoverable?: boolean
  onlyShared?: boolean
  loraHashes?: string[]
  modelHashes?: string[]
  recipeIds?: string[]
  styleIds?: string[]
}

export const useWorkspaceWorkflowInfiniteQuery = createAuthenticatedInfiniteQuery<
  GetWorkflowsResult,
  UseWorkspaceWorkflowInfiniteQueryVariables,
  Error,
  WorkflowItem
>({
  primaryKey: "infinite-workspace-workflows",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .workflowControllerGetWorkflows({
        skip: pageParam * 20,
        take: 20,
        view: variables.view,
        sortBy: variables.sortBy,
        statuses: variables.statuses,
        searchTerm: variables.search,
        fromDate: variables.fromDate ? startOfDay(variables?.fromDate).toISOString() : undefined,
        toDate: variables?.toDate ? endOfDay(variables?.toDate).toISOString() : undefined,
        onlyDiscoverable: variables?.onlyDiscoverable,
        onlyShared: variables?.onlyShared,
        loraHashes: variables?.loraHashes,
        modelHashes: variables?.modelHashes,
        recipeIds: variables?.recipeIds,
        styleIds: variables?.styleIds,
      })
      .then(res => res.data),
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage.workflows.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  flattenFn: res => res.workflows,
  getTotalFn: res => res.total,
})
