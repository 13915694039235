import { RecipeStep, SDBaseModel } from "@/api/sdk"
import { cn } from "@/lib/utils"
import classNames from "classnames"
import { motion } from "framer-motion"
import { FC, Fragment, useMemo, useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import { Controller, FieldArrayWithId, UseFormReturn, useFieldArray, useWatch } from "react-hook-form"
import { GraphField } from "../../../ComfyUI/utils/graph"
import Input from "../../../Input"
import Selector from "../../../Selector"
import Toggle from "../../../Toggle"
import { ChevronDownIcon, MenuIcon, PlusIcon, Target3Icon, XIcon } from "../../../shared/icons"
import { workflowInputTypes } from "./ComfyUIWorkflowSettings"

export type WorkflowInput = {
  id: string
  type: string
  field?: string
  name?: string
  description?: string
  tooltip?: string
}

export type ComfyUIWorkflowSettingProps = {
  graphInputs: GraphField[]
  form: UseFormReturn<RecipeStep>
  index: number
  onSelect: (comfyKey: string) => void
  onRemove: () => void
  onSwap: (indexA: number, indexB: number) => void
  disabled?: boolean
  field: FieldArrayWithId<RecipeStep, "inputs", "id">
}

type DropItem = {
  index: number
}

const shorten = (string: string, maxLength: number) => {
  return string.length <= maxLength ? string : string.slice(0, maxLength - 2) + "..."
}

const baseModels = [
  { id: SDBaseModel.All, name: "SD1.5 + SDXL" },
  { id: SDBaseModel.Sd1X, name: "SD1.5 only" },
  { id: SDBaseModel.SdXl, name: "SDXL only" },
]

const ComfyUIWorkflowSetting: FC<ComfyUIWorkflowSettingProps> = props => {
  const { graphInputs, form, index, disabled, onSelect, onRemove, onSwap, field } = props

  const { control } = form
  const {
    fields: options,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: `inputs.${index}.options`,
    rules: {
      validate: (v, f) => {
        if (["select", "radio"].includes(f.inputs[index]?.type)) {
          return v.length > 0 ? true : "This field is required"
        }

        return true
      },
    },
  })

  const values = useWatch({
    control,
    name: `inputs.${index}`,
  })

  const type = values?.["type"]

  const availableInputs = useMemo(() => {
    const dataTypes = workflowInputTypes.find(i => i.type === type)?.dataTypes ?? [type]
    return graphInputs
      .filter(i => dataTypes.includes(i.type))
      .map(i => ({ ...i, name: `#${i.nodeId}.${i.field} (${shorten(i.nodeName, 25)})` }))
  }, [type, graphInputs])

  const availableWeightInputs = useMemo(() => {
    return graphInputs
      .filter(i => i.type === "number")
      .map(i => ({ ...i, name: `#${i.nodeId}.${i.field} (${shorten(i.nodeName, 25)})` }))
  }, [graphInputs])

  const [open, setOpen] = useState(true)

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: "comfyUiWorkflowInput",
      item: () => ({ index }),
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const result = monitor.getDropResult()
        console.log("droping", item, "result", result)
      },
      canDrag() {
        return !disabled
      },
    }),
    [index, disabled],
  )

  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: "comfyUiWorkflowInput",
      drop: (item: DropItem) => {
        console.log("droping", item, "over", index)
        if (item.index !== index) {
          onSwap(item.index, index)
        }
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [index],
  )
  return (
    <div
      ref={ref => {
        dropRef(ref)
      }}
      className="bg-atherGray-900 text-atherGray-300 max-w-[36rem] overflow-hidden"
    >
      <div
        ref={ref => {
          dragRef(ref)
        }}
        className={classNames("", {
          "border-t border-atherGray-800 pt-4": index > 0,
          "opacity-50": isDragging,
          "bg-atherGray-800": isOver,
        })}
      >
        <div className="flex gap-2 items-center overflow-hidden">
          <MenuIcon className="text-atherGray-500 cursor-ns-resize" />
          <a
            className="flex-1 flex items-center justify-start overflow-hidden gap-2 cursor-pointer text-atherGray-300"
            onClick={() => setOpen(!open)}
          >
            <p className="text-xs font-semibold truncate">{values["name"] || field.name || type}</p>
            <ChevronDownIcon
              className={classNames("w-3 h-3", {
                "-rotate-90": !open,
              })}
            />
          </a>
          <Target3Icon
            onClick={() => onSelect(values["comfyKey"] || field.comfyKey)}
            className={classNames({
              "cursor-pointer": !!values["comfyKey"] || !!field.comfyKey,
              "opacity-60": !values["comfyKey"] && !field.comfyKey,
            })}
            width={12}
            height={12}
          />
          <XIcon
            width={12}
            height={12}
            className={cn("text-red-500", { "cursor-pointer": !disabled, "opacity-70": disabled })}
            onClick={() => !disabled && onRemove()}
          />
        </div>
        <motion.div
          initial={{ height: "auto", opacity: 1 }}
          animate={
            open
              ? { height: "auto", opacity: 1, display: "block" }
              : { height: 0, opacity: 0, transitionEnd: { display: "none" } }
          }
          className={classNames({
            "overflow-hidden": !open,
          })}
        >
          <div className="flex flex-col gap-1.5 pt-2">
            <div>
              <Controller
                name={`inputs.${index}.comfyKey`}
                control={control}
                rules={{ required: { value: true, message: "This field is required" } }}
                render={({ field, fieldState }) => (
                  <>
                    <Selector
                      searchable
                      placement="bottom"
                      className="text-xs h-7 pl-2 py-1"
                      listClassName="bg-[#464646] text-sm"
                      value={availableInputs.find(i => i.id === field.value)?.name ?? "Select mapping input"}
                      options={availableInputs}
                      disabled={disabled}
                      onSelect={v => {
                        field.onChange(v.id)

                        onSelect(v.id)
                        if (type === "select" || type === "radio") {
                          form.setValue(
                            `inputs.${index}.options`,
                            (v.values ?? [v.value]).map(value => ({ display: value, value })),
                          )
                        } else if (type === "model" || type === "lora") {
                          form.setValue(`inputs.${index}.baseModel`, SDBaseModel.Sd1X)
                        }
                      }}
                    />
                    {fieldState.error && (
                      <p className="text-red-500 text-xs error-message">{fieldState.error.message}</p>
                    )}
                  </>
                )}
              ></Controller>
            </div>
            <Controller
              name={`inputs.${index}.name`}
              control={control}
              rules={{ required: { value: true, message: "This field is required" } }}
              render={({ field, fieldState }) => (
                <>
                  <Input
                    containerClassName="flex-1"
                    className="rounded-lg text-xs h-7 px-2 py-1"
                    placeholder="Name"
                    disabled={disabled}
                    {...field}
                  />
                  {fieldState.error && <p className="text-red-500 text-xs error-message">{fieldState.error.message}</p>}
                </>
              )}
            ></Controller>

            <Controller
              name={`inputs.${index}.description`}
              control={control}
              render={({ field, fieldState }) => (
                <div className="relative overflow-hidden rounded-lg p-1 bg-atherGray-800">
                  <textarea
                    disabled={disabled}
                    className="bg-transparent w-full px-1 text-xs disabled:text-atherGray-400 disabled:placeholder:text-gray-500"
                    placeholder="Decscription"
                    rows={2}
                    {...field}
                  />
                </div>
              )}
            ></Controller>

            <div className="flex gap-2 py-1 flex-col">
              <Controller
                name={`inputs.${index}.optional`}
                control={control}
                render={({ field }) => (
                  <div className="flex-0 flex gap-2 items-center justify-between">
                    <p className="text-xs">Optional</p>
                    <Toggle
                      sizeToggle="sm"
                      disabled={disabled}
                      checked={field.value}
                      onChange={e => {
                        e.stopPropagation()
                        field.onChange(e.target.checked)
                      }}
                    />
                  </div>
                )}
              />
              {(type === "text" || type === "image") && (
                <Controller
                  name={`inputs.${index}.private`}
                  control={control}
                  render={({ field }) => (
                    <div className="flex-0 flex gap-2 items-center justify-between">
                      <p className="text-xs">Private</p>
                      <Toggle
                        disabled={disabled}
                        sizeToggle="sm"
                        checked={field.value}
                        onChange={e => {
                          e.stopPropagation()
                          field.onChange(e.target.checked)
                        }}
                      />
                    </div>
                  )}
                />
              )}
              {type === "text" && (
                <Controller
                  name={`inputs.${index}.allowWildcard`}
                  control={control}
                  render={({ field }) => (
                    <div className="flex-0 flex gap-2 items-center justify-between">
                      <p className="text-xs">Wildcard</p>
                      <Toggle
                        disabled={disabled}
                        sizeToggle="sm"
                        checked={field.value}
                        onChange={e => {
                          e.stopPropagation()
                          field.onChange(e.target.checked)
                        }}
                      />
                    </div>
                  )}
                />
              )}
            </div>

            {type === "text" && (
              <>
                <Input
                  className="rounded-lg text-xs h-7 px-2 py-1"
                  placeholder="Placeholder"
                  disabled={disabled}
                  {...form.register(`inputs.${index}.placeholder`)}
                />
              </>
            )}
            {type === "number" && (
              <div className="flex flex-col space-y-2">
                <Controller
                  name={`inputs.${index}.min`}
                  control={control}
                  rules={{ required: { value: true, message: "This field is required" } }}
                  render={({ field, fieldState }) => (
                    <div>
                      <div className="flex items-center">
                        <p className="text-xs mr-2 min-w-[2rem]">Min:</p>
                        <Input
                          className="max-w-[10rem] rounded-lg text-xs flex-1 h-7 px-2 py-1"
                          type="number"
                          disabled={disabled}
                          placeholder="0"
                          {...field}
                          value={field.value ? parseFloat(parseFloat(field.value.toString()).toFixed(3)) : undefined}
                          onChange={e => {
                            field.onChange(parseFloat(parseFloat(e.target.value).toFixed(3)))
                          }}
                        />
                      </div>
                      {fieldState.error && (
                        <p className="text-red-500 text-xs error-message mt-1">{fieldState.error.message}</p>
                      )}
                    </div>
                  )}
                />
                <Controller
                  name={`inputs.${index}.max`}
                  control={control}
                  rules={{
                    required: { value: true, message: "This field is required" },
                    validate: {
                      //check max bigger than min
                      validate: (_, values) => {
                        return parseFloat(values.inputs[index].max?.toString() ?? "0") >=
                          parseFloat(values.inputs[index].min?.toString() ?? "0")
                          ? true
                          : "Max must be bigger than min"
                      },
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <div>
                      <div className="flex items-center">
                        <p className="text-xs mr-2 min-w-[2rem]">Max:</p>
                        <Input
                          disabled={disabled}
                          className="max-w-[10rem] rounded-lg text-xs flex-1 h-7 px-2 py-1"
                          type="number"
                          placeholder="0"
                          {...field}
                          value={field.value ? parseFloat(parseFloat(field.value.toString()).toFixed(3)) : undefined}
                          onChange={e => {
                            field.onChange(parseFloat(parseFloat(e.target.value).toFixed(3)))
                          }}
                        />
                      </div>

                      {fieldState.error && (
                        <p className="text-red-500 text-xs error-message mt-1">{fieldState.error.message}</p>
                      )}
                    </div>
                  )}
                />
                <Controller
                  name={`inputs.${index}.step`}
                  control={control}
                  rules={{
                    required: { value: true, message: "This field is required" },
                    validate: {
                      //check step smaller than max - min
                      validate: (_, values) =>
                        parseFloat(values.inputs[index].step?.toString() ?? "0") <=
                        parseFloat(values.inputs[index].max?.toString() ?? "0") -
                          parseFloat(values.inputs[index].min?.toString() ?? "0")
                          ? true
                          : "Step must be smaller than max - min",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <div>
                      <div className="flex items-center">
                        <p className="text-xs mr-2 min-w-[2rem]">Step:</p>
                        <Input
                          disabled={disabled}
                          className="max-w-[10rem] rounded-lg text-xs flex-1 h-7 px-2 py-1"
                          type="number"
                          placeholder="0"
                          {...field}
                          value={field.value ? parseFloat(parseFloat(field.value.toString()).toFixed(3)) : undefined}
                          onChange={e => {
                            field.onChange(parseFloat(parseFloat(e.target.value).toFixed(3)))
                          }}
                        />
                      </div>

                      {fieldState.error && (
                        <p className="text-red-500 text-xs error-message mt-1">{fieldState.error.message}</p>
                      )}
                    </div>
                  )}
                />
              </div>
            )}
            {(type === "select" || type === "radio") && (
              <div>
                <div
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() => append({ value: "", display: "" })}
                >
                  <p className="text-xs font-semibold">Values</p>
                  <PlusIcon height={10} width={10} />
                </div>
                <div className="grid grid-cols-[minmax(0,_1fr)_minmax(0,_1fr)_20px] grid-flow-row mt-1 gap-y-1">
                  {options.map((option, idx) => (
                    <Fragment key={option.id}>
                      <Input
                        disabled={disabled}
                        className="rounded-l-lg rounded-r-none text-xs h-7 px-2 py-1 border-r border-atherGray-700"
                        placeholder="Display"
                        {...form.register(`inputs.${index}.options.${idx}.display`)}
                      />
                      <Input
                        disabled={disabled}
                        className="rounded-r-lg rounded-l-none text-xs h-7 px-2 py-1 text-right"
                        placeholder="Value"
                        {...form.register(`inputs.${index}.options.${idx}.value`)}
                      />
                      <div className="justify-self-end flex items-center">
                        <XIcon
                          width={12}
                          height={12}
                          className="text-red-500 cursor-pointer "
                          onClick={() => remove(idx)}
                        />
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>
            )}
            {type === "model" && (
              <Controller
                name={`inputs.${index}.baseModel`}
                control={control}
                render={({ field }) => (
                  <Selector
                    placement="bottom"
                    className="text-xs bg-atherGray-800 h-7 pl-2 py-1"
                    listClassName="bg-atherGray-700 text-sm"
                    value={baseModels.find(i => i.id === field.value)?.name ?? "SD1.5 + SDXL"}
                    options={baseModels}
                    onSelect={v => field.onChange(v.id)}
                    disabled={disabled}
                  />
                )}
              ></Controller>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default ComfyUIWorkflowSetting
