import { useDebounce } from "@/hooks"
import classNames from "classnames"
import { motion } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import { twMerge } from "tailwind-merge"
import { cn } from "@/utils/cn"
import { InfoIcon, XIcon } from "./shared/icons"
import Tooltip from "./Tooltip"
import Popover from "./Popover"
import { Menu } from "./MenuFloating"

type SelectorOption = { id: string; name: string }

type DefaultSelectorProps<T extends SelectorOption = SelectorOption> = {
  placeholder?: string
  className?: string
  value: string
  onSelect: (v: T) => void
  options: T[]
  title?: string
  containerClassName?: string
  iconSize?: string
  listClassName?: string
  titlePlacement?: "in" | "out"
  inputClassName?: string
  titleClassName?: string
  tooltip?: string
  listContentClassName?: string
  placement?: "top" | "bottom"
  searchable?: boolean
  clearable?: false
  disabled?: boolean
  itemClassName?: string
  rightIcon?: React.ReactNode
}

type ClearableSelectorProps<T extends SelectorOption = SelectorOption> = Omit<
  DefaultSelectorProps<T>,
  "onSelect" | "clearable"
> & {
  onSelect: (v?: T) => void
  clearable: true
}

type SelectorProps<T extends SelectorOption = SelectorOption> = DefaultSelectorProps<T> | ClearableSelectorProps<T>

function Selector<T extends SelectorOption = SelectorOption>({
  placeholder,
  className,
  value,
  listContentClassName,
  options,
  rightIcon,
  titleClassName,
  onSelect,
  title,
  searchable,
  containerClassName,
  inputClassName,
  itemClassName,
  iconSize = "1.4rem",
  listClassName,
  titlePlacement = "out",
  tooltip,
  clearable,
  disabled,
}: SelectorProps<T>) {
  const [isOpen, _setIsOpen] = useState(false)
  const [search, setSearch] = useState("")
  const searchDebounce = useDebounce(search, 350)
  const searchRef = useRef<HTMLInputElement>(null)

  const triggerRef = useRef<HTMLDivElement>(null)
  const [triggerRefWidth, setTriggerRefWidth] = useState(0)

  useEffect(() => {
    if (triggerRef.current) {
      setTriggerRefWidth(triggerRef.current.getBoundingClientRect().width)
    }
  }, [isOpen])

  const setIsOpen = (open: boolean) => {
    _setIsOpen(open)

    if (!open) {
      setSearch("")
    }

    if (open && searchable) {
      searchRef.current?.focus()
    }
  }

  return (
    <div
      className={cn(
        "max-w-full",
        {
          "cursor-pointer": !disabled,
        },
        containerClassName,
      )}
      ref={triggerRef}
    >
      {title && titlePlacement === "out" && (
        <div className="mb-1 flex items-center">
          <p className={twMerge("text-sm font-bold", titleClassName)}>{title} </p>
          {tooltip && (
            <div className="ml-2">
              <Tooltip
                trigger={
                  <div>
                    <InfoIcon className="text-atherGray-600" width={14} height={14} />
                  </div>
                }
              >
                {tooltip}
              </Tooltip>
            </div>
          )}
        </div>
      )}
      <div>
        <Popover
          isOpen={isOpen}
          setIsOpen={open => !disabled && setIsOpen(open)}
          align="center"
          trigger={
            <div
              className={twMerge(
                classNames(
                  "w-full relative flex items-center min-h-[2.5rem] py-1.5 px-2 text-atherGray-300 justify-between mb-[0.5px] bg-atherGray-800 rounded-lg",
                ),
                className,
              )}
            >
              <div
                className={cn("line-clamp-1 flex-1", {
                  "text-atherGray-400": disabled,
                })}
              >
                {title && titlePlacement === "in" && (
                  <div className="flex items-center">
                    <p className={cn("text-xs text-atherGray-300", titleClassName)}>{title}</p>
                    {tooltip && (
                      <div className="ml-2">
                        <Tooltip
                          trigger={
                            <div>
                              <InfoIcon className="text-atherGray-600" width={14} height={14} />
                            </div>
                          }
                        >
                          {tooltip}
                        </Tooltip>
                      </div>
                    )}
                  </div>
                )}
                {value ? (
                  <p className="truncate w-[calc(100%-20px)]">{value}</p>
                ) : (
                  <p className="text-atherGray-300">{placeholder}</p>
                )}
              </div>
              {clearable && !!value && (
                <div className="absolute right-8">
                  <XIcon
                    className="text-atherGray-300 hover:text-red-500"
                    width={16}
                    height={16}
                    onClick={e => {
                      e.stopPropagation()
                      onSelect(undefined)
                    }}
                  />
                </div>
              )}
              <motion.div className="flex flex-col">
                {rightIcon || (
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-atherGray-300"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                )}
              </motion.div>
            </div>
          }
        >
          <motion.div
            className={cn(
              "shadow-xl flex flex-col flex-1 w-full overflow-hidden rounded-lg z-20 max-h-[14rem] bg-atherGray-800",
              `${isOpen ? listClassName : ""}`,
            )}
            style={{ width: triggerRefWidth }}
          >
            {searchable && (
              <input
                ref={searchRef}
                className={cn("bg-transparent p-2", inputClassName)}
                placeholder="Search..."
                onClick={e => e.stopPropagation()}
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            )}
            <div className={cn("flex flex-col overflow-auto", listContentClassName)}>
              {options
                .filter(i => i.name.toLowerCase().includes(searchDebounce.toLowerCase()))
                .map((i, idx) => (
                  <div
                    onClick={() => {
                      onSelect(i)
                      setIsOpen(false)
                      setSearch("")
                    }}
                    key={i.id}
                    className={cn("px-[1.5px] py-[5px] text-whiteAlpha-900 hover:bg-atherGray-600", itemClassName)}
                  >
                    <p className="px-2">{i.name}</p>
                  </div>
                ))}
            </div>
          </motion.div>
        </Popover>
      </div>
    </div>
  )
}

export default Selector
