import { cn } from "@/utils/cn"
import {
  FloatingFocusManager,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  Placement,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTypeahead,
} from "@floating-ui/react"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import * as React from "react"
import { twMerge } from "tailwind-merge"

interface MenuItemProps {
  children: React.ReactNode
  disabled?: boolean
}

export const MenuItem = React.forwardRef<
  HTMLButtonElement,
  MenuItemProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ children, disabled, className, ...props }, ref) => {
  return (
    <button
      type="button"
      className={twMerge(classNames("menu-dropdown-item text-white focus:outline-none"), className)}
      {...props}
      ref={ref}
      role="menuitem"
      disabled={disabled}
    >
      {children}
    </button>
  )
})

interface MenuProps {
  trigger: React.ReactNode
  nested?: boolean
  children?: React.ReactNode
  listClassName?: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  placement?: Placement
}

export const MenuComponent = React.forwardRef<HTMLDivElement, MenuProps & React.HTMLProps<HTMLButtonElement>>(
  ({ children, trigger, listClassName, isOpen, setIsOpen, placement, ...props }, forwardedRef) => {
    const [activeIndex, setActiveIndex] = React.useState<number | null>(null)
    const [allowHover, setAllowHover] = React.useState(false)

    const listItemsRef = React.useRef<Array<HTMLButtonElement | null>>([])
    const listContentRef = React.useRef(
      React.Children.map(children, child => (React.isValidElement(child) ? child.props.children : null)) as Array<
        string | null
      >,
    )

    const tree = useFloatingTree()
    const nodeId = useFloatingNodeId()
    const parentId = useFloatingParentNodeId()
    const isNested = parentId != null

    const { x, y, strategy, refs, context } = useFloating<HTMLDivElement>({
      nodeId,
      open: isOpen,
      onOpenChange: setIsOpen,
      placement: isNested ? "right-start" : placement || "bottom-end",
      middleware: [offset({ mainAxis: isNested ? 0 : 4, alignmentAxis: isNested ? -4 : 0 }), flip(), shift()],
      whileElementsMounted: autoUpdate,
    })

    const hover = useHover(context, {
      enabled: isNested && allowHover,
      delay: { open: 75 },
      handleClose: safePolygon({
        restMs: 25,
        blockPointerEvents: true,
      }),
    })
    const click = useClick(context, {
      event: "mousedown",
      toggle: !isNested || !allowHover,
      ignoreMouse: isNested,
    })
    const role = useRole(context, { role: "menu" })
    const dismiss = useDismiss(context)
    const listNavigation = useListNavigation(context, {
      listRef: listItemsRef,
      activeIndex,
      nested: isNested,
      onNavigate: setActiveIndex,
    })
    const typeahead = useTypeahead(context, {
      enabled: isOpen,
      listRef: listContentRef,
      onMatch: isOpen ? setActiveIndex : undefined,
      activeIndex,
    })

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
      hover,
      click,
      role,
      dismiss,
      listNavigation,
      typeahead,
    ])

    // Event emitter allows you to communicate across tree components.
    // This effect closes all menus when an item gets clicked anywhere
    // in the tree.
    React.useEffect(() => {
      if (!tree) return

      function handleTreeClick() {
        setIsOpen(false)
      }

      function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
        if (event.nodeId !== nodeId && event.parentId === parentId) {
          setIsOpen(false)
        }
      }

      //handle click menu item to close menu
      // tree.events.on("click", handleTreeClick)

      tree.events.on("menuopen", onSubMenuOpen)

      return () => {
        tree.events.off("click", handleTreeClick)
        tree.events.off("menuopen", onSubMenuOpen)
      }
    }, [tree, nodeId, parentId])

    React.useEffect(() => {
      if (isOpen && tree) {
        tree.events.emit("menuopen", { parentId, nodeId })
      }
    }, [tree, isOpen, nodeId, parentId])

    // Determine if "hover" logic can run based on the modality of input. This
    // prevents unwanted focus synchronization as menus open and close with
    // keyboard navigation and the cursor is resting on the menu.
    React.useEffect(() => {
      function onPointerMove({ pointerType }: PointerEvent) {
        if (pointerType !== "touch") {
          setAllowHover(true)
        }
      }

      function onKeyDown() {
        setAllowHover(false)
      }

      window.addEventListener("pointermove", onPointerMove, {
        once: true,
        capture: true,
      })
      window.addEventListener("keydown", onKeyDown, true)
      return () => {
        window.removeEventListener("pointermove", onPointerMove, {
          capture: true,
        })
        window.removeEventListener("keydown", onKeyDown, true)
      }
    }, [allowHover])

    const referenceRef = useMergeRefs([refs.setReference, forwardedRef])

    return (
      <FloatingNode id={nodeId}>
        <div
          ref={referenceRef}
          data-open={isOpen ? "" : undefined}
          {...getReferenceProps({
            ...props,
            className: twMerge(`${isNested ? "MenuItem" : "RootMenu"}`, props.className),
            onClick(event) {
              event.preventDefault()
              event.stopPropagation()
            },
            onDoubleClick(event) {
              event.stopPropagation()
            },
            ...(isNested && {
              // Indicates this is a nested <Menu /> acting as a <MenuItem />.
              role: "menuitem",
            }),
          })}
        >
          {trigger}
          {isNested && (
            <span aria-hidden style={{ marginLeft: 10 }}>
              ➔
            </span>
          )}
        </div>
        <FloatingPortal>
          <AnimatePresence mode="wait">
            {isOpen && (
              <FloatingFocusManager
                context={context}
                // Prevent outside content interference.
                modal={false}
                // Only initially focus the root floating menu.
                initialFocus={isNested ? -1 : 0}
                // Only return focus to the root menu's reference when menus close.
                returnFocus={!isNested}
              >
                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  transition={{ type: "spring", stiffness: 500, damping: 30, duration: 0.3 }}
                  ref={refs.setFloating}
                  className={cn(
                    "menu-dropdown bg-atherGray-700 rounded-md z-[50] flex flex-col p-2 focus:outline-none",
                    listClassName,
                  )}
                  style={{
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,
                  }}
                  {...getFloatingProps()}
                >
                  {React.Children.map(
                    children,
                    (child, index) =>
                      React.isValidElement(child) &&
                      React.cloneElement(
                        child,
                        getItemProps({
                          tabIndex: activeIndex === index ? 0 : -1,
                          ref(node: HTMLButtonElement) {
                            listItemsRef.current[index] = node
                          },
                          onClick(event) {
                            event.stopPropagation()
                            child.props.onClick?.(event)
                            tree?.events.emit("click")
                          },
                          // Allow focus synchronization if the cursor did not move.
                          onMouseEnter() {
                            if (allowHover && isOpen) {
                              setActiveIndex(index)
                            }
                          },
                        }),
                      ),
                  )}
                </motion.div>
              </FloatingFocusManager>
            )}
          </AnimatePresence>
        </FloatingPortal>
      </FloatingNode>
    )
  },
)

export const Menu = React.forwardRef<HTMLDivElement, MenuProps & React.HTMLProps<HTMLButtonElement>>((props, ref) => {
  const parentId = useFloatingParentNodeId()

  if (parentId === null) {
    return (
      <FloatingTree>
        <MenuComponent {...props} ref={ref} />
      </FloatingTree>
    )
  }

  return <MenuComponent {...props} ref={ref} />
})
