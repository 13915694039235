import {
  PinnedItemType,
  RecipeTaskStatus,
  RecipeType,
  WorkflowDetail,
  WorkflowItem as WorkflowItemType,
} from "@/api/sdk"
import IconButton from "@/components/IconButton"
import { useRef, useState } from "react"
import FolderActionRow from "../../FolderActions/FolderActionRow"

import client from "@/api/client"
import {
  DeleteIcon,
  EditIcon,
  FlowIcon,
  LayerAddIcon,
  OpenNewTabIcon,
  PinIcon,
  RefreshIcon,
  ShareIcon8,
  ThreeDotHorizontalIcon,
  UnPinIcon,
} from "@/components/shared/icons"
import { useToast } from "@/hooks"
import useModalStore from "@/lib/store"
import { useCreateTaskMutation, usePinMutation } from "@/queries"
import { useDeleteItemStore, useManagementErrorsStore, useRecoverItemStore, useRenameItemStore } from "@/stores"
import { QueryKey, useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { createMutation } from "react-query-kit"
import { RecipeTaskChainParams } from "../WorkflowsChaining"
import { useAuth } from "@/providers/authContext"
import { cn } from "@/utils/cn"
import { googleAnalytics } from "@/lib/gtag"
import { Menu } from "@/components/MenuFloating"
import { useWildcardListMutate } from "@/components/Explore/ImageDetail/ImageActionButtons"
import { useGetRecipesMutation } from "@/queries/workspace/recipe"
import useCustomRouter from "@/hooks/useCustomRouter"
import { runAgainWorkflowParams } from "@/utils/task"

interface ActionButtonsProps {
  workflow: WorkflowItemType
  queryKey?: QueryKey
  className?: string
  layout?: "grid" | "list"
}

export const useWorkflowDetailMutate = createMutation<WorkflowDetail, { workflowId: string }, Error>({
  mutationFn: ({ workflowId }) => client.api.workflowControllerGetWorkflowById(workflowId).then(res => res.data),
})

const ActionButtonsWorkflow = ({ workflow, queryKey, className, layout = "grid" }: ActionButtonsProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const qc = useQueryClient()
  const setDeletingItem = useDeleteItemStore(state => state.setDeletingItem)
  const setRenameWorkflow = useRenameItemStore(state => state.setRenamingItem)
  const setSharingData = useModalStore(state => state.setSharingData)
  const toast = useToast()
  const [tempWorkflow, setTempWorkflow] = useState<WorkflowDetail | null>(null)
  const selectedRef = useRef("")
  const { user } = useAuth()
  const setRecoverItem = useRecoverItemStore(state => state.setRecoverItem)
  const { openNewTab } = useCustomRouter()
  const isDisabled = user?.uid !== workflow.creator?.uid
  const setErrorState = useManagementErrorsStore(state => state.setErrorState)

  const { mutate: mutateCreateTask, isPending: isPendingCreate } = useCreateTaskMutation({
    onSuccess: () => {
      qc.invalidateQueries({ queryKey })
      toast({
        status: "success",
        title: "Submitted! Your task is running",
        duration: 5000,
      })
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }

      if (err?.error === "Bad Request") {
        toast({ title: "Cannot Complete Request", message: [err.message], status: "error" })
      } else {
        toast({ title: "Error", message: [err.message], status: "error" })
      }
    },
  })

  const { mutateAsync: mutateGetWorkflow, isPending: isPendingGetWorkflow } = useWorkflowDetailMutate({
    onSuccess: data => {
      setTempWorkflow(data)
      setIsOpen(false)

      handlerunAgainWorkflowParams(data)
    },
  })

  const { mutate: mutatePinTask, isPending: isPendingPinTask } = usePinMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: `Macro ${workflow.pinned ? "unpinned" : "pinned"}`,
      })
    },
  })

  const handleShare = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Share Macro",
        tab_name: "macros",
        macro_id: workflow.id,
        macro_name: workflow.name,
      },
    })

    setSharingData({
      id: workflow.id,
      name: workflow.name ?? workflow.id,
      type: "macro",
      workspaceId: workflow.workspace?.id,
      creator: workflow.creator,
      link: `${window.location.origin}/workspace/macros/${workflow.id}`,
    })
  }

  const handlePin = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: workflow.pinned ? "Unpin Macro" : "Pin Macro",
        tab_name: "macros",
        macro_id: workflow.id,
        macro_name: workflow.name,
      },
    })

    mutatePinTask({ ids: [workflow.id], type: PinnedItemType.WORKFLOW, isPinned: !workflow.pinned })
  }

  const handleNewTab = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Open Macro in new tab",
        tab_name: "macros",
        macro_id: workflow.id,
        macro_name: workflow.name,
      },
    })

    setTimeout(() => {
      openNewTab(`/workspace/macros/${workflow.id}`)
    }, 150)
  }

  const handleDelete = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Delete Macro",
        tab_name: "macros",
        macro_id: workflow.id,
        macro_name: workflow.name,
      },
    })

    setDeletingItem({
      type: "macro",
      id: workflow.id,
      name: workflow.name,
      deletedAt: workflow.deletedAt,
    })
  }

  const { mutateAsync: mutateWildcardsRes, isPending: isLoadingWildcards } = useWildcardListMutate()
  const { mutateAsync: mutateGetRecipes, isPending: isMutatingRecipes } = useGetRecipesMutation()

  const handlerunAgainWorkflowParams = async (workflow: WorkflowDetail) => {
    if (!workflow) return

    const recipes = await mutateGetRecipes({
      types: [RecipeType.Normal, RecipeType.Describe],
      recipeIds: workflow.params.map(i => i.recipeId),
      take: workflow.params.length,
    })

    for (let i = 0; i < workflow.params.length; i++) {
      const param = workflow.params[i]

      if (
        !recipes
          .filter(r => !r.deletedAt)
          .map(i => i.id)
          .includes(param.recipeId)
      ) {
        toast({
          status: "error",
          title: "Can not run workflow!",
          message: [`Recipe of step ${i + 1} is not found.`],
        })
        return
      }
    }

    const mappedParams = await runAgainWorkflowParams(workflow)

    mutateCreateTask({
      params: mappedParams as RecipeTaskChainParams[],
      recipeId: "recipe-to-recipe",
      folderId: workflow.folder.id,
      name: workflow.name,
    })
  }

  const handleRunAgain = async () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Run Macro Again",
        tab_name: "macros",
        macro_id: workflow.id,
        macro_name: workflow.name,
      },
    })

    if (tempWorkflow) {
      handlerunAgainWorkflowParams(tempWorkflow)

      return
    }

    selectedRef.current = "Create"

    await mutateGetWorkflow({ workflowId: workflow.id })
  }

  const handleRename = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Rename Macro",
        tab_name: "macros",
        macro_id: workflow.id,
        macro_name: workflow.name,
      },
    })

    setRenameWorkflow({
      type: "macro",
      id: workflow.id,
      name: workflow.name,
    })
  }

  const handleDuplicate = async () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Duplicate Macro",
        tab_name: "macros",
        macro_id: workflow.id,
        macro_name: workflow.name,
      },
    })

    setTimeout(() => {
      openNewTab(`/workspace/macros/new-macro?originId=${workflow.id}`)
    }, 100)
  }

  const handleRecovery = () => {
    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Recover Macro",
        tab_name: "macros",
        macro_id: workflow.id,
        macro_name: workflow.name,
      },
    })

    setRecoverItem({
      ids: [workflow.id],
      deletedAt: workflow.deletedAt ?? null,
      type: "macro",
    })
  }

  const actions = [
    {
      label: isDisabled ? "Try this macro" : "Duplicate",
      icon: isDisabled ? <FlowIcon width={16} height={16} /> : <LayerAddIcon width={16} height={16} />,
      onClick: handleDuplicate,
      visible: workflow.capabilities?.canUpdate && !workflow.deletedAt,
      enabled: workflow.capabilities?.canUpdate,
      isLoading: selectedRef.current === "Duplicate" && isPendingGetWorkflow,
    },
    {
      label: "Run again",
      icon: <RefreshIcon className="rotate-180" width={16} height={16} />,
      onClick: handleRunAgain,
      visible: !isDisabled && workflow.status !== RecipeTaskStatus.DRAFT && !workflow.deletedAt,
      enabled: workflow.capabilities?.canUpdate,
      isLoading: (selectedRef.current === "Create" && isPendingGetWorkflow) || isMutatingRecipes || isLoadingWildcards,
    },
    {
      label: "Rename",
      icon: <EditIcon width={16} height={16} />,
      onClick: handleRename,
      visible: !isDisabled && !workflow.deletedAt,
      enabled: workflow.capabilities?.canUpdate,
    },
    {
      label: "Share",
      icon: <ShareIcon8 width={16} height={16} />,
      onClick: handleShare,
      visible: workflow.capabilities?.canShare && workflow.status !== RecipeTaskStatus.DRAFT && !workflow.deletedAt,
      enabled: workflow.capabilities?.canShare,
    },
    {
      label: workflow.pinned ? "Unpin" : "Pin",
      icon: workflow.pinned ? <UnPinIcon width={16} height={16} /> : <PinIcon width={16} height={16} />,
      onClick: handlePin,
      visible: workflow.capabilities.canView && !workflow.deletedAt,
      enabled: workflow.capabilities?.canView,
    },
    {
      label: "Open in new tab",
      icon: <OpenNewTabIcon width={16} height={16} />,
      onClick: handleNewTab,
      visible: workflow.capabilities?.canView && !workflow.deletedAt,
      enabled: true,
    },
    {
      label: "Recover",
      icon: <RefreshIcon className="rotate-180" width={16} height={16} />,
      onClick: handleRecovery,
      enabled: workflow.capabilities?.canRecover,
      visible: !!workflow.deletedAt,
    },
    {
      label: "Delete",
      icon: <DeleteIcon className="text-red-500" width={16} height={16} />,
      onClick: handleDelete,
      visible: !isDisabled,
      enabled: workflow.capabilities?.canDelete,
    },
  ]

  return (
    <div
      className={cn(
        "transition-opacity duration-300",
        {
          "opacity-100 lg:opacity-100": isOpen,
          "absolute top-1.5 right-1.5 opacity-100 lg:opacity-0 group-hover:opacity-100": layout === "grid",
        },
        className,
      )}
    >
      <Menu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        placement="bottom-end"
        listClassName="p-0 bg-transparent overflow-hidden"
        trigger={
          <IconButton
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
            }}
            colorScheme="secondary"
            className={classNames("bg-blackAlpha-700 px-2 min-h-[1.5rem]", {
              "bg-transparent hover:bg-transparent": className?.includes("bg-transparent"),
            })}
          >
            <ThreeDotHorizontalIcon width={14} height={14} />
          </IconButton>
        }
      >
        <div
          className={
            "text-sm w-[13rem] text-atherGray-300 bg-atherGray-900 border border-atherGray-850 p-2 rounded-2xl space-y-1"
          }
        >
          {actions
            .filter(a => a.visible)
            .map(action => (
              <FolderActionRow
                key={action.label}
                isLoading={action.isLoading}
                iconLeft={action.icon}
                onClick={e => {
                  e.stopPropagation()
                  action.onClick()
                  setIsOpen(false)
                }}
                isDisabled={!action.enabled}
              >
                {action.label}
              </FolderActionRow>
            ))}
        </div>
      </Menu>
    </div>
  )
}

export default ActionButtonsWorkflow
