import { PinnedItemType, WildcardDetail } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import { useState } from "react"

import { Menu } from "@/components/MenuFloating"
import FolderActionRow from "@/components/Workspace/FolderActions/FolderActionRow"
import {
  AddSquareIcon,
  DeleteIcon,
  EditIcon,
  LayerAddIcon,
  MoreHorizontalIcon,
  OpenNewTabIcon,
  PinIcon,
  RefreshIcon,
  RemoveSquareIcon,
  ScienceIcon,
  ShareIcon3,
  ShareIcon8,
  UnPinIcon,
  UploadIcon,
} from "@/components/shared/icons"
import { useToast } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import useModalStore from "@/lib/store"
import {
  useAddWildcardsMutation,
  useCreateWildcardMutation,
  usePinMutation,
  useRemoveWildcardsMutation,
} from "@/queries"

import { useDeleteItemStore, useRecipeImageStore, useRecoverItemStore, useRenameItemStore } from "@/stores"
import { cn } from "@/utils/cn"
import { triggerRoutedDialog } from "@/providers/RoutedDialogProvider"
import useCustomRouter from "@/hooks/useCustomRouter"

interface ActionButtonsProps {
  wildcard: WildcardDetail
  className?: string
  layout?: "grid" | "list"
}

const WildcardActionButtons = (props: ActionButtonsProps) => {
  const { wildcard, className, layout } = props
  const router = useCustomRouter()

  const toast = useToast()
  const { user } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const setRenameWildcard = useRenameItemStore(state => state.setRenamingItem)
  const setSharingData = useModalStore(state => state.setSharingData)
  const setDeleteItem = useDeleteItemStore(state => state.setDeletingItem)
  const setRecipeImage = useRecipeImageStore(state => state.setRecipeImage)

  const { mutate: addToLibrary, isPending: isAddingToLibrary } = useAddWildcardsMutation({
    onSuccess() {
      googleAnalytics.event({
        action: "click",
        category: "wildcard_item",
        label: "add_to_library",
        params: {
          wildcard_id: wildcard.id,
          wildcard_name: wildcard.name ?? wildcard.id,
          pathname: window.location.pathname,
        },
      })
      toast({
        status: "success",
        title: "Wildcard added",
        message: ["Wildcard has been added to your library"],
      })
    },
  })

  const { mutate: removeFromLibrary, isPending: isRemovingFromLibrary } = useRemoveWildcardsMutation({
    onSuccess() {
      googleAnalytics.event({
        action: "click",
        category: "wildcard_item",
        label: "remove_from_library",
        params: {
          wildcard_id: wildcard.id,
          wildcard_name: wildcard.name ?? wildcard.id,
        },
      })
      toast({
        status: "success",
        title: "Wildcard removed",
        message: ["Wildcard has been removed from your library"],
      })
    },
  })

  const handleNewTab = () => {
    setTimeout(() => {
      router.openNewTab(`/workspace/tools/wildcards/${wildcard.id}`)
    }, 150)
  }

  const handleRename = () => {
    googleAnalytics.event({
      action: "click",
      category: "wildcard_item",
      label: "rename_wildcard",
      params: {
        style_id: wildcard.id,
        wildcard_name: wildcard.name ?? wildcard.id,
        pathname: window.location.pathname,
      },
    })

    setRenameWildcard({
      type: "wildcard",
      id: wildcard.id,
      name: wildcard.name || "My wildcard",
    })
  }

  const handleShare = () => {
    googleAnalytics.event({
      action: "click",
      category: "wildcard_item",
      label: "share_wildcard",
      params: {
        wildcard_id: wildcard.id,
        wildcard_name: wildcard.name ?? wildcard.id,
        pathname: window.location.pathname,
      },
    })

    setSharingData({
      id: wildcard.id,
      name: wildcard.name ?? wildcard.id,
      link: `${window.location.origin}/workspace/tools/wildcards/${wildcard.id}`,
      type: "wildcard",
      creator: wildcard.creator,
      workspaceId: wildcard.workspace.id,
      url: "",
    })
  }

  const setRecoverItem = useRecoverItemStore(state => state.setRecoverItem)

  const handleOpenWildcards = (wildcards: WildcardDetail) => {
    googleAnalytics.event({
      action: "click",
      category: "wildcard_item",
      label: "recover_wildcard",
      params: {
        wildcard_id: wildcard.id,
        wildcard_name: wildcard.name ?? wildcard.id,
        pathname: window.location.pathname,
      },
    })

    setRecoverItem({
      ids: [wildcards.id],
      deletedAt: wildcards.deletedAt ?? null,
      type: "wildcard",
    })
  }

  const { mutate: mutatePinTask } = usePinMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: `Wildcard ${wildcard.pinned ? "unpinned" : "pinned"}`,
      })
    },
  })

  const handlePin = () => {
    googleAnalytics.event({
      action: "click",
      category: "wildcard_item",
      label: `${wildcard.pinned ? "unpin" : "pin"}_wildcard`,
      params: {
        style_id: wildcard.id,
        style_name: wildcard.name ?? wildcard.id,
        pathname: window.location.pathname,
      },
    })

    mutatePinTask({ ids: [wildcard.id], type: PinnedItemType.WILDCARD, isPinned: !wildcard.pinned })
  }

  const { mutate: mutateCreateWildCard, isPending: isLoadingCreate } = useCreateWildcardMutation({
    onSuccess: () => {
      if (router.query.tab === "shared-with-me") {
        router.push(`/workspace/tools/wildcards?tab=my-wildcards`, undefined, { shallow: true })
      }
      toast({
        status: "success",
        title: "Duplicate wildcard",
        message: ["Duplicate wildcard successfully"],
      })
    },
  })

  const handleRecipe = async () => {
    googleAnalytics.event({
      action: "click",
      category: "wildcard_item",
      label: `send_to_recipe`,
      params: {
        style_id: wildcard.id,
        style_name: wildcard.name ?? wildcard.id,
        pathname: window.location.pathname,
      },
    })

    const params = {
      prompt: `__${wildcard.id}`,
      prompt_wildcard: {
        value: `__${wildcard.id}`,
        wildcards: [
          {
            id: wildcard.id,
            isPublic: wildcard.isPublic,
            name: wildcard.name,
            wildcards: wildcard.wildcards,
          },
        ],
      },
    }

    setRecipeImage({
      id: wildcard.id,
      isModal: true,
      type: "wildcard",
      params: {
        ...params,
      },
    })
  }

  const isMutating = isAddingToLibrary || isRemovingFromLibrary

  const actions = [
    {
      label: "Send to recipe",
      icon: <ScienceIcon width={16} height={16} />,
      onClick: () => {
        handleRecipe()
      },
      visible: !wildcard.deletedAt,
      enabled: wildcard.capabilities?.canView,
    },
    {
      label: "Update",
      icon: <UploadIcon width={16} height={16} />,
      onClick: () => {
        triggerRoutedDialog({
          name: "wildcardDetailWorkspace",
          state: {
            wildcardId: wildcard.id,
            filters: {
              editMode: true,
            },
          },
          type: "push",
        })
      },
      visible: wildcard.capabilities?.canUpdate && !wildcard.deletedAt,
      enabled: !isMutating,
    },
    {
      label: "Duplicate",
      icon: <LayerAddIcon width={16} height={16} />,
      onClick: () => {
        googleAnalytics.event({
          action: "click",
          category: "wildcard_item",
          label: "duplicate_wildcard",
          params: {
            wildcard_id: wildcard.id,
            wildcard_name: wildcard.name ?? wildcard.id,
            pathname: window.location.pathname,
          },
        })

        const nameOrigin = wildcard?.name?.replaceAll("Copy_of_", "") ?? wildcard.id

        const name = nameOrigin ? `Copy_of_${nameOrigin}` : `Copy_of_New Wildcard`

        mutateCreateWildCard({
          list: [
            {
              name,
              wildcards: wildcard.wildcards,
            },
          ],
        })
      },
      visible: wildcard.capabilities?.canUpdate && !wildcard.deletedAt,
      enabled: !isMutating,
      isLoading: isLoadingCreate,
    },
    {
      label: "Rename",
      icon: <EditIcon width={16} height={16} />,
      onClick: handleRename,
      visible: wildcard.capabilities?.canUpdate && !wildcard.deletedAt && wildcard.creator.uid === user?.uid,
      enabled: !isMutating,
    },
    {
      label: "Share",
      icon: <ShareIcon8 width={16} height={16} />,
      onClick: handleShare,
      visible: wildcard.capabilities?.canShare && !wildcard.deletedAt,
      enabled: true,
    },
    {
      label: "Add to My wildcards",
      icon: <AddSquareIcon width={16} height={16} />,
      onClick: () => addToLibrary({ ids: [wildcard.id] }),
      visible: wildcard.capabilities?.canAddToLibrary && !wildcard.deletedAt,
      enabled: !isMutating,
      isLoading: isAddingToLibrary,
    },
    {
      label: "Remove from My wildcards",
      icon: <RemoveSquareIcon width={16} height={16} />,
      onClick: () =>
        removeFromLibrary({ ids: [wildcard.id], view: router.query.tab === "shared-with-me" ? "shared" : "owned" }),
      visible: wildcard.capabilities?.canRemoveFromLibrary && !wildcard.deletedAt,
      enabled: !isMutating,
      isLoading: isRemovingFromLibrary,
    },
    {
      label: wildcard.pinned ? "Unpin" : "Pin",
      icon: wildcard.pinned ? <UnPinIcon width={16} height={16} /> : <PinIcon width={16} height={16} />,
      onClick: handlePin,
      visible: wildcard.capabilities?.canView && !wildcard.deletedAt,
      enabled: wildcard.capabilities?.canView,
    },
    {
      label: "Open in new tab",
      icon: <OpenNewTabIcon width={16} height={16} />,
      onClick: handleNewTab,
      visible: wildcard.capabilities?.canUpdate && !wildcard.deletedAt,
      enabled: true,
    },
    {
      label: "Recover",
      icon: <RefreshIcon className="rotate-180" width={16} height={16} />,
      onClick: () => handleOpenWildcards(wildcard),
      visible: wildcard.deletedAt && wildcard.capabilities?.canRecover,
      enabled: true,
    },
    {
      label: "Delete",
      icon: <DeleteIcon className="text-red-500" width={16} height={16} />,
      onClick: () => {
        googleAnalytics.event({
          action: "click",
          category: "wildcard_item",
          label: "delete_wildcard",
          params: {
            wildcard_id: wildcard.id,
            wildcard_name: wildcard.name ?? wildcard.id,
            pathname: window.location.pathname,
          },
        })

        setDeleteItem({
          id: wildcard.id,
          type: "wildcard",
          deletedAt: wildcard.deletedAt,
        })
      },
      visible: wildcard.capabilities?.canDelete,
      enabled: !isMutating,
    },
  ]

  return (
    <div
      className={cn(
        "transition-opacity duration-300",
        {
          "opacity-100 lg:opacity-100": isOpen,
          "absolute top-1.5 right-1.5 opacity-100 lg:opacity-0 group-hover:opacity-100": layout === "grid",
        },
        className,
      )}
    >
      <Menu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        trigger={
          <IconButton
            colorScheme="transparent"
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
            }}
            className={cn("rounded-2xl p-0 w-7 h-7 min-h-0 hover:bg-atherGray-700 active:bg-atherGray-700", {
              "bg-atherGray-700": isOpen,
            })}
          >
            <MoreHorizontalIcon />
          </IconButton>
        }
        placement="bottom-end"
        listClassName="p-0 bg-transparent overflow-hidden"
      >
        <div
          className={
            "text-sm w-[16rem] text-atherGray-300 bg-atherGray-900 border border-atherGray-850 p-2 rounded-2xl space-y-1"
          }
        >
          {actions
            .filter(a => a.visible)
            .map(action => (
              <FolderActionRow
                key={action.label}
                isLoading={action.isLoading}
                iconLeft={action.icon}
                onClick={e => {
                  e.stopPropagation()
                  action.onClick?.()
                  setIsOpen(false)
                }}
                isDisabled={!action.enabled}
              >
                {action.label}
              </FolderActionRow>
            ))}
        </div>
      </Menu>
    </div>
  )
}

export default WildcardActionButtons
