import {
  CreateRecipeTaskDto,
  EntityType,
  FolderDetail,
  GetModelsResponse,
  ImageSimpleDetail,
  RecipeFilterMode,
  RecipeInputField,
  RecipeInputType,
  RecipeItem,
  RecipeOutputType,
  RecipeTaskStatus,
  RecipeType,
  WildcardDetail,
} from "@/api/sdk"
import { useWildcardListMutate } from "@/components/Explore/ImageDetail/ImageActionButtons"
import LoadingLogo from "@/components/LoadingLogo"
import { Metadata } from "@/components/Metadata"
import { useScreen, useToast } from "@/hooks"
import { useClearUrlQuery } from "@/hooks/useQuery"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import {
  useCreateTaskMutation,
  useCreateTaskNotAuthMutation,
  useTrackingViewMutation,
  useUpdateTaskMutation,
  useUpdateTaskNotAuthMutation,
} from "@/queries"
import { useGetRecipesQuery } from "@/queries/workspace/recipe"
import {
  useTagsWorkflowMutation,
  useWorkspaceWorkflowDetailNotAuthQuery,
  useWorkspaceWorkflowDetailQuery,
} from "@/queries/workspace/workflow"
import { useManagementErrorsStore, useSignInStore, useTempTaskStoreV2 } from "@/stores"
import generateCaptchaToken from "@/utils/generateCaptchaToken"
import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"

import { uniqBy } from "lodash"
import dynamic from "next/dynamic"
import useCustomRouter from "@/hooks/useCustomRouter"
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useLoraQuery } from "../../Recipes/RecipeDetail/FormDetail/FormDetailLora"
import { formatInputKey } from "../WorkflowDetailStep/WorkFlowDetailStep"
import WorkflowContainer from "./WorkflowContainer"
// import WorkflowGenerate from "./WorkflowGenerate"
import WorkflowSetting from "./WorkflowSetting"
import {
  randomWildcardPublic,
  RecipeCreateType,
  handleGetPrevTask,
  recipeParamsWithWildcard,
  getWildcardIdsByWorkflow,
  getStyleParamWithQueryClient,
} from "@/utils/task"
import ErrorUI from "@/components/ErrorUI"

const WorkflowGenerate = dynamic(() => import("./WorkflowGenerate"))

export type RecipeTaskChainParams = CreateRecipeTaskDto & {
  recipeName: string
  recipeInputStep?: RecipeInputField[]
  fullDataImages?: ImageSimpleDetail[]
  id?: string
}

export type SelectableRecipeInputField = RecipeInputField & { isChecked?: boolean }

export const initWorkflowChain = {
  params: {},
  recipeId: "add-a-recipe",
  recipeName: "Add a recipe",
  folderId: "",
  name: "Add a recipe",
  id: "0",
}

const RecipeDetail = ({ workflowId }: { workflowId: string }) => {
  const sendRecipe = "recipe-to-recipe"

  const router = useCustomRouter()

  const toast = useToast()
  const qc = useQueryClient()
  const clearUrl = useClearUrlQuery()
  const { width } = useScreen()
  const isTablet = width < 1024
  const { user, loading } = useAuth()

  const form = useForm<RecipeCreateType>({
    mode: "onSubmit",
  })
  const { setError, clearErrors } = form

  const [isOpenSideBar, setIsOpenSidebar] = useState(true)
  const [recipeInputs, setRecipeInputs] = useState<SelectableRecipeInputField[]>([])
  const [inputName, setInputName] = useState<string>(`New Macro`)
  const [chains, setChains] = useState<RecipeTaskChainParams[]>([initWorkflowChain])
  const [selectedItem, setSelectedItem] = useState<number | undefined>(undefined)
  const [folder, setFolder] = useState<FolderDetail>()
  const [selectedRecipe, _setSelectedRecipe] = useState<RecipeItem>()
  const [selectedRecipes, setSelectedRecipes] = useState<RecipeItem[]>([])

  const [isCompletedTask, setIsCompletedTask] = useState(false)

  const { tempTask, setTempTask } = useTempTaskStoreV2()
  const setSignInModal = useSignInStore(state => state.setSignInModal)
  const { mutate: mutateTrackingView } = useTrackingViewMutation()

  const macroQueryAuth = useWorkspaceWorkflowDetailQuery({
    variables: { workflowId: workflowId ?? "" },
    refetchOnWindowFocus: false,
    enabled: workflowId !== "new-macro" && !!user && !loading,
  })

  const macroQueryNotAuth = useWorkspaceWorkflowDetailNotAuthQuery({
    variables: { workflowId: workflowId ?? "" },
    refetchOnWindowFocus: false,
    enabled: workflowId !== "new-macro" && !user && !loading,
  })

  const macroQuery = useMemo(
    () => (user ? macroQueryAuth : macroQueryNotAuth),
    [user, macroQueryAuth, macroQueryNotAuth],
  )

  const {
    data: recipeResponse,
    isLoading,
    isError,
    isSuccess,
    isFetching,
  } = useGetRecipesQuery({
    variables: {
      types: [RecipeType.Normal, RecipeType.Describe],
      recipeIds: !!macroQuery.data?.params.filter(p => p.recipeId !== "add-a-recipe").map(i => i.recipeId)?.length
        ? uniqBy(
            macroQuery.data?.params.filter(p => p.recipeId !== "add-a-recipe").map(i => i.recipeId),
            i => i,
          )
        : undefined,
      take: macroQuery.data?.params.length,
      userUid: user?.uid,
      mode: !!macroQuery.data?.params.filter(p => p.recipeId !== "add-a-recipe").map(i => i.recipeId)?.length
        ? undefined
        : RecipeFilterMode.Public,
    },
    enabled: !!macroQuery.data && macroQuery.data.status === RecipeTaskStatus.DRAFT,
  })

  const [isFetchedRecipes, setIsFetchedRecipes] = useState(false)

  const recipes = useMemo(() => recipeResponse?.recipes ?? [], [recipeResponse?.recipes])

  useEffect(() => {
    if (!macroQuery.data) return

    if (isFetchedRecipes || isFetching) return

    if (macroQuery.data.status !== RecipeTaskStatus.DRAFT || isSuccess) {
      mutateTrackingView({
        entityId: workflowId,
        entityType: EntityType.WORKFLOW,
      })

      googleAnalytics.handleCategoryEvent({
        action: "view",
        params: {
          action: "View Macro Detail",
          macro_id: macroQuery.data.id,
          macro_name: macroQuery.data.name,
        },
      })

      setSelectedRecipes(prev => uniqBy([...(prev ?? []), ...recipes], r => r.id))
      console.log("recipes", recipes)
      setIsFetchedRecipes(true)
    }
  }, [isFetching, isFetchedRecipes, isSuccess, macroQuery?.data])

  const selectQueryIndex = useCallback(
    (index: number) => {
      setTimeout(() => {
        router.replace(
          {
            query: { ...router.query, index: index.toString() },
          },
          undefined,
          {
            shallow: true,
            scroll: false,
          },
        )
      }, 0)
    },
    [router],
  )

  const setSelectedRecipe = useCallback(
    (recipe: RecipeItem, params?: Record<string, any>, prevStepParams?: any) => {
      setSelectedRecipes(prev => {
        return uniqBy([...(prev ?? []), recipe ?? []], r => r.id)
      })

      const prevStepParamsTemp = prevStepParams ?? handleGetPrevTask(chains, selectedItem ?? 0)

      const skipDefaultValueRecipe = {
        ...recipe,
        steps: recipe?.steps.map(step => ({
          ...step,
          inputs: step.inputs.map(input => ({
            ...input,
            value: params?.[input.key] ?? prevStepParamsTemp?.[input.key] ?? input.value,
          })),
        })),
      }

      const promptWildcards = {
        prompt_wildcard: prevStepParamsTemp?.prompt_wildcard,
      }

      form.reset({
        ...params,
        ...skipDefaultValueRecipe.steps
          ?.map(step =>
            step.inputs.reduce((acc, curr) => {
              acc[curr.key] =
                curr.allowWildcard && params?.[`${curr.key}_wildcard`]
                  ? params?.[`${curr.key}_wildcard`].value
                  : params?.[curr.key] ?? prevStepParamsTemp?.[curr.key] ?? curr.value
              return acc
            }, {}),
          )
          .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
        ...promptWildcards,
      })

      const allowBatch = chains.length == 0

      const newRecipeInputs = skipDefaultValueRecipe.steps
        ?.map(step => step.inputs)
        .flat()
        .filter(i => allowBatch || (i.comfyKey !== "batch_size" && i.comfyKey !== "batchSize"))
        .map(i => ({
          ...i,
          prompt_wildcard: i.allowWildcard ? params?.[`${i.key}_wildcard`] : undefined,
          value: params?.[i.key] ?? prevStepParamsTemp?.[i.key] ?? i.value,
          isChecked: !!params?.[i.key] ?? !!prevStepParamsTemp?.[i.key] ?? !i.optional,
        }))

      setRecipeInputs(newRecipeInputs)
      _setSelectedRecipe(skipDefaultValueRecipe)
    },
    [chains, form, selectedItem],
  )

  const selectChainItem = useCallback(
    (index: number, e?: React.MouseEvent, chainData?: RecipeTaskChainParams) => {
      if (e) e.preventDefault()

      setSelectedItem(index)
      selectQueryIndex(index)
      const chainItem = chainData ?? chains[index]

      const prevStepValue = handleGetPrevTask(chains, index)

      const recipe = selectedRecipes.find(i => i.id === chainItem?.recipeId) ?? selectedRecipes[0]

      if (recipe) {
        const newRecipe = {
          ...recipe,
          steps: (recipe.steps || []).map(step => ({
            ...step,
            inputs: step.inputs.map(input => ({
              ...input,
              value: prevStepValue?.[input.key] ?? input.value,
            })),
          })),
        }

        setSelectedRecipe(newRecipe, chainItem?.params, prevStepValue)
      }
    },
    [chains, selectedRecipes, selectQueryIndex, setSelectedRecipe],
  )

  const addChainItem = e => {
    e.preventDefault()
    e.stopPropagation()

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Add Chain",
        macro_id: workflowId,
        macro_name: inputName,
        macro_status: RecipeTaskStatus.DRAFT,
      },
    })

    const newChain = [
      ...chains,
      {
        ...initWorkflowChain,
        id: new Date().getTime().toString(),
      },
    ]

    setChains(prev => [
      ...prev,
      {
        ...initWorkflowChain,
        id: new Date().getTime().toString(),
      },
    ])

    handleAutoSaveDraft({
      params: newChain,
    })
    selectChainItem(chains.length, undefined, initWorkflowChain)
    selectQueryIndex(chains.length)
  }

  const removeChainItem = (e: React.MouseEvent, index: number) => {
    e.preventDefault()
    e.stopPropagation()

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Remove Chain",
        macro_id: workflowId,
        macro_name: inputName,
        macro_status: RecipeTaskStatus.DRAFT,
        step: index + 1,
      },
    })

    const chain = chains.slice(0, index).concat(chains.slice(index + 1)).length
      ? chains.slice(0, index).concat(chains.slice(index + 1))
      : [initWorkflowChain]

    handleAutoSaveDraft({
      params: chain,
    })

    if (index === 0 && chains.length === 1) {
      setSelectedRecipe(selectedRecipes[0])
      setChains([initWorkflowChain])
      return
    }

    if (selectedItem !== undefined && selectedItem >= index) {
      selectChainItem(Math.max(0, selectedItem - 1))
    }

    setChains(prev => prev.slice(0, index).concat(prev.slice(index + 1)))
  }

  const duplicateChainItem = (e: React.MouseEvent, index: number) => {
    e.preventDefault()
    e.stopPropagation()

    const chain = chains[index]

    const newChain = {
      ...chain,
      params: {
        ...chain.params,
        image: chain.recipeInputStep?.find(i => i.type === RecipeInputType.Image) ? `$$prev.0` : chain.params?.image,
      },
    }

    const newChains = [...chains.slice(0, index + 1), newChain, ...chains.slice(index + 1)]

    const newChainsWithId = newChains.map((chain, idx) => ({
      ...chain,
      id: `${new Date().getTime().toString() + idx}`,
    }))

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Duplicate Chain",
        macro_id: workflowId,
        macro_name: inputName,
        macro_status: RecipeTaskStatus.DRAFT,
        step: index + 1,
        ...newChain.params,
      },
    })

    setChains(newChainsWithId)

    selectChainItem(index + 1, undefined, newChain)

    handleAutoSaveDraft({
      params: newChainsWithId,
    })
  }

  const lorasKey = useLoraQuery.getKey({
    baseModel: selectedRecipe?.baseModel,
  })

  const setErrorState = useManagementErrorsStore(state => state.setErrorState)

  const { mutate: mutateTagsWorkflow } = useTagsWorkflowMutation()
  const [stylesNotFound, setStylesNotFound] = useState<string[]>([])

  const { mutateAsync: mutateCreateTask, isPending: isLoadingCreate } = useCreateTaskMutation({
    onSuccess: (data, { status }) => {
      if (status === RecipeTaskStatus.DRAFT) {
        router.replace(`/workspace/macros/${data.id}?draft=true&index=0`)

        return
      }
      setIsOpenSidebar(false)
      router.push(`/workspace/macros/${data.id}`)

      toast({
        status: "success",
        title: "Submitted! Your task is running",
        duration: 5000,
      })
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }

      if (err?.error === "Bad Request") {
        toast({ title: "Cannot Complete Request", message: [err.message], status: "error" })
      } else {
        toast({ title: "Error", message: [err.message], status: "error" })
      }

      setTimeout(() => {
        router.push("/workspace/macros")
      }, 200)
    },
  })

  const { mutateAsync: mutateCreateTaskNotAuth, isPending: isLoadingCreateNotAuth } = useCreateTaskNotAuthMutation({
    onSuccess: (data, { status }) => {
      if (status === RecipeTaskStatus.DRAFT) {
        router.replace(`/workspace/macros/${data.id}?draft=true&index=0`)

        return
      }
      setIsOpenSidebar(false)
      router.push(`/workspace/macros/${data.id}`)

      toast({
        status: "success",
        title: "Submitted! Your task is running",
        duration: 5000,
      })
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }
      if (err?.error === "Bad Request") {
        toast({ title: "Cannot Complete Request", message: [err.message], status: "error" })
      } else {
        toast({ title: "Error", message: [err.message], status: "error" })
      }
      router.back()
    },
  })

  const { mutate: mutateUpdateTask, isPending: isLoadingUpdateAuth } = useUpdateTaskMutation({
    onSuccess: data => {
      if (data && data.status === RecipeTaskStatus.QUEUED) {
        googleAnalytics.handleCategoryEvent({
          action: "click",
          params: {
            action: "Submit Generated Task Completed",
            macro_id: data.id,
            macro_name: data.name ?? inputName,
            task_id: data.id,
          },
        })
      }
    },
    onError: (err: any) => {
      if (err.statusCode === 500) return

      if (err.message.includes("Some styles are not found or deleted")) {
        const styles = err.message.split(": ")[1].split(", ")
        setStylesNotFound(styles)
      }

      toast({ title: "Error", message: [err.message], status: "error", duration: 4500 })
    },
  })

  const { mutate: mutateUpdateTaskNotAuth, isPending: isLoadingUpdateNotAuth } = useUpdateTaskNotAuthMutation({
    onSuccess: data => {
      if (data && data.status === RecipeTaskStatus.QUEUED) {
        setTempTask([...(tempTask || []), { id: data.id, type: "macro" }])
      }
    },
    onError: (err: any) => {
      toast({ title: "Error", message: [err.message], status: "error" })
    },
  })

  const handleAutoSaveDraft = useCallback(
    async ({
      params,
      description,
      name,
      tagIds,
      originId,
    }: {
      name?: string
      description?: string
      params: RecipeTaskChainParams[]
      tagIds?: number[]
      originId?: string
    }) => {
      if (macroQuery.data && macroQuery.data?.status === RecipeTaskStatus.DRAFT && user) {
        mutateUpdateTask({
          taskId: workflowId,
          data: {
            description,
            folderId: folder?.id,
            name: name ?? inputName,
            status: RecipeTaskStatus.DRAFT,
            params: params as any,
          },
        })

        googleAnalytics.handleCategoryEvent({
          action: "click",
          params: {
            action: "Update Draft",
            macro_id: workflowId,
            macro_name: inputName,
            macro_status: RecipeTaskStatus.DRAFT,
          },
        })

        return
      }

      if (workflowId !== "new-macro") return

      if (!user && !loading) {
        if (tempTask && tempTask?.length >= 5) {
          toast({
            status: "error",
            title: "Limit reached",
            message: ["Please sign in to generate more"],
          })

          setSignInModal({
            signIn: true,
          })

          return
        }

        const captchaToken = await generateCaptchaToken()

        mutateCreateTaskNotAuth({
          params: params as any,
          status: RecipeTaskStatus.DRAFT,
          name: name ?? inputName,
          recipeId: sendRecipe,
          basedRecipeTaskId: originId,
          captchaToken,
        })

        return
      }

      const newWorkflow = await mutateCreateTask({
        params: params as any,
        status: RecipeTaskStatus.DRAFT,
        name: name ?? inputName,
        recipeId: sendRecipe,
        basedRecipeTaskId: originId,
      })

      mutateTagsWorkflow({
        workflowIds: [newWorkflow.id],
        tagIds: tagIds ?? [],
      })

      mutateUpdateTask({
        taskId: newWorkflow.id,
        data: {
          description,
          status: RecipeTaskStatus.DRAFT,
        },
      })

      googleAnalytics.handleCategoryEvent({
        action: "click",
        params: {
          action: "Update Draft",
          macro_id: workflowId,
          macro_name: inputName,
          macro_status: RecipeTaskStatus.DRAFT,
        },
      })
    },
    [
      macroQuery.data,
      workflowId,
      folder?.id,
      inputName,
      mutateUpdateTask,
      mutateCreateTask,
      mutateTagsWorkflow,
      tempTask,
      setSignInModal,
      toast,
      mutateCreateTaskNotAuth,
      user,
      loading,
    ],
  )

  const validateParams = async (): Promise<RecipeTaskChainParams | undefined> => {
    const rest = form.getValues()
    const loras = qc.getQueryData<GetModelsResponse>(lorasKey)

    let isTrigger = true

    await form.trigger()

    const mappedKeyStringNull = Object.keys(rest).filter(
      key => typeof rest[key] === "string" && rest[key].trim() === "",
    )
    const promptWildcards = Object.keys(rest).filter(key => key.endsWith("_wildcard")) // prompt_wildcard

    for (const key of mappedKeyStringNull) {
      const input = recipeInputs?.find(input => input.key === key)
      const dependInput = input?.depends ? recipeInputs?.find(i => i.key === input?.depends) : undefined

      if (!input?.optional && (!dependInput || dependInput.isChecked)) {
        setError(key, {
          message: "This field is required",
        })
        const inputElement = document.getElementById(key) as HTMLDivElement

        if (inputElement) {
          inputElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          })
        }

        isTrigger = false
      }
    }

    if (!isTrigger || !selectedRecipe) {
      return
    }

    clearErrors()

    const mappedParams = recipeInputs.map(param => ({
      optional: param.optional,
      key: param.key,
      depend: param.depends,
      value: typeof rest[param.key] === "string" ? (rest[param.key] as string).trim() : rest[param.key],
    })) as { key: string; value: string; optional?: boolean; depend?: string }[]

    const checkDependValue = mappedParams.map(param => {
      if (param.depend) {
        const dependValue = mappedParams.find(i => i.key === param.depend)?.value

        if (!dependValue) {
          return {
            ...param,
            value: "",
          }
        }

        return param
      }
      return param
    })

    const params = {
      ...checkDependValue
        .filter(param => (param.optional ? !!param.value : param.depend ? !!param.value : true))
        .reduce((acc, curr) => {
          acc[curr.key] = curr.value
          return acc
        }, {}),
      ...promptWildcards.reduce((acc, curr) => {
        acc[curr] = rest[curr]
        return acc
      }, {}),
    }

    // remove keys that should not be sent to the backend
    recipeInputs.forEach(input => {
      const value = params[input.key]

      if (value) {
        if (input?.removeKeys && Array.isArray(input.removeKeys)) {
          input.removeKeys.forEach(key => delete params[key])
        }
      }
    })

    // auto add trigger words to prompt if user selected lora model
    if (params["prompt"] && params["lora"]) {
      try {
        const selectedLoras = JSON.parse(params["lora"])

        if (selectedLoras.length) {
          selectedLoras.forEach((lora: { name: string; strength: number }) => {
            const loraModel = loras?.models.find(m => m.fileName === lora.name)

            if (loraModel) {
              const triggerWords = loraModel.defaultSettings ? loraModel.defaultSettings["triggerWords"] : null

              if (triggerWords && params["prompt"].indexOf(triggerWords) === -1) {
                params["prompt"] = `${params["prompt"]}, ${triggerWords}`
              }
            }
          })
        }
      } catch (e) {}
    }

    // read wildcard data
    const newParams = await randomWildcardPublic(params, recipeInputs)

    return {
      recipeId: selectedRecipe.id,
      recipeName: selectedRecipe.name,
      params: newParams,
    }
  }

  const isFetchedRef = useRef(false)

  const { refetch } = useWorkspaceWorkflowDetailQuery({
    variables: { workflowId: (router.query.originId as string) ?? "" },
    refetchOnWindowFocus: false,
    enabled: false,
  })

  const isEnabled = useMemo(() => macroQuery.data?.status === RecipeTaskStatus.DRAFT ?? false, [macroQuery.data])
  const isFirstRender = useRef(false)

  useEffect(() => {
    const handleGetOriginWorkflow = async (originId: string) => {
      if (workflowId === "new-macro") {
        isFetchedRef.current = true

        if (originId) {
          const data = await refetch()

          const params = data?.data?.params as RecipeTaskChainParams[]
          const nameOrigin = data?.data?.name?.replaceAll("Copy_of_", "")

          const name = nameOrigin ? `Copy_of_${nameOrigin}` : `Copy_of_New Macro`

          const newChains =
            data.data && Object.values(params).length > 0
              ? params.map((param, idx) => ({
                  ...param,
                  id: new Date().getTime().toString() + idx,
                }))
              : [initWorkflowChain]

          setInputName(name)
          setChains(newChains)

          handleAutoSaveDraft({
            params: newChains,
            name,
            description: data?.data?.description,
            tagIds: data?.data?.tags.map(tag => tag.id),
            originId,
          })

          return
        }

        setInputName(`New Macro`)
        handleAutoSaveDraft({
          params: [initWorkflowChain],
          name: `New Macro`,
        })
      } else {
        if (macroQuery.data) {
          if (macroQuery.data.status === RecipeTaskStatus.DRAFT) {
            setChains(macroQuery.data.params as RecipeTaskChainParams[])
          }
          setInputName(macroQuery.data.name)

          isFetchedRef.current = true
        }
      }
    }

    if (loading) return

    if (!isFetchedRef.current) {
      const timer = setTimeout(() => {
        handleGetOriginWorkflow(router.query.originId as string)
      }, 150)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [
    router.query.originId,
    refetch,
    handleAutoSaveDraft,
    macroQuery.data,
    workflowId,
    form,
    selectChainItem,
    user,
    loading,
  ])

  useEffect(() => {
    if (loading) return

    if (selectedItem !== undefined) return

    if (!isFetchedRecipes) return

    if (macroQuery.isLoading) return

    if (macroQuery.isSuccess) {
      if (macroQuery.data?.status !== RecipeTaskStatus.DRAFT) return

      const timer = setTimeout(() => {
        const params = macroQuery.data?.params || [initWorkflowChain]

        selectChainItem(0, undefined, params[0] as RecipeTaskChainParams)
      }, 350)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [isFetchedRecipes, loading, macroQuery, selectedItem, selectChainItem])

  useEffect(() => {
    return () => {
      isFetchedRef.current = false
      isFirstRender.current = false
    }
  }, [])

  const { mutateAsync: mutateWildcardsRes, isPending: isLoadingWildcards } = useWildcardListMutate()
  const [isMutating, setIsMutating] = useState(false)

  const isLoadingGenerate =
    isLoadingCreate ||
    isLoadingUpdateAuth ||
    isLoadingUpdateNotAuth ||
    isLoading ||
    isLoadingWildcards ||
    isLoadingCreateNotAuth ||
    isMutating

  const chainRecipe = async () => {
    const data = await validateParams()

    if (!data) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Update Chain",
        macro_id: workflowId,
        macro_name: inputName,
        ...data.params,
      },
    })

    const recipeInputStep: any = recipeInputs.map(step => ({
      key: step.key,
      name: step.name,
      optional: step.optional,
      type: step.type,
      allowWildcard: step.allowWildcard,
      depends: step.depends,
      private: step.private,
    }))

    const hasWildcardField = recipeInputs.some(input => input.allowWildcard)

    let newParams = data.params

    try {
      if (hasWildcardField) {
        setIsMutating(true)

        newParams = await recipeParamsWithWildcard(newParams, recipeInputs)
      }

      if (newParams?.["style"]) {
        setIsMutating(true)

        const style = await getStyleParamWithQueryClient(newParams["style"], qc)

        if (style && style.name) {
          newParams = {
            ...newParams,
            styleName: style.name,
          }
        }
      }
    } catch (error) {
      setIsMutating(false)
    } finally {
      setIsMutating(false)
    }

    if (!selectedItem) {
      setChains(curr => [
        ...curr,
        {
          ...data,
          recipeInputStep,
        },
      ])
      const newChains = [
        ...chains,
        { ...data, params: newParams, recipeInputStep, id: new Date().getTime().toString() },
      ]
      handleAutoSaveDraft({
        params: newChains,
      })
    }

    if (selectedItem === 0) {
      const hasPrevValue = Object.keys(data.params).some(key => data.params[key]?.toString().includes("$$prev"))
      if (hasPrevValue) {
        toast({
          status: "error",
          title: "Can not run workflow!",
          message: [`Input Image of step 1 can't be taken from previous step.`],
        })
        return
      }
    }

    const newChains = chains.map((chain, index) =>
      index === selectedItem ? { ...data, params: newParams, recipeInputStep, id: chain.id } : chain,
    )

    setChains(newChains)

    handleAutoSaveDraft({
      params: newChains,
    })

    if (isTablet) {
      setIsOpenSidebar(false)
    }
  }

  const generate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (!chains.length) return

    if (chains?.some(chain => chain.recipeId === "add-a-recipe")) {
      toast({
        status: "error",
        title: "Can not run macro!",
        message: ["Please select a recipe for each step."],
      })
      return
    }

    clearErrors()

    for (let i = 0; i < chains.length; i++) {
      const chain = chains[i]
      const recipeInputStep = chain.recipeInputStep ?? []
      const params = chain.params
      const requiredKeys = recipeInputStep.filter(input => !input.optional)

      if (
        !selectedRecipes
          .filter(r => !r.deletedAt)
          .map(i => i.id)
          .includes(chain.recipeId)
      ) {
        toast({
          status: "error",
          title: "Can not run macro!",
          message: [`Recipe of step ${i + 1} is not found.`],
        })
        selectChainItem(i)
        return setTimeout(() => validateParams(), 0)
      }

      const hasPrevValue = Object.keys(params).some(key => params[key]?.toString().includes("$$prev"))

      if (hasPrevValue && i === 0) {
        toast({
          status: "error",
          title: "Can not run macro!",
          message: [`Input Image of step 1 can't be taken from previous step.`],
        })
        selectChainItem(i)
        return setTimeout(() => validateParams(), 0)
      }
      // Skip this check if the value is $$prev, since old macro doesn't include step number in $$prev
      if (hasPrevValue) {
        // Validate input step
        const keyParamsPrev = Object.keys(params).filter(key => params[key]?.toString().includes("$$prev"))

        for (const key of keyParamsPrev) {
          const inputStepIndex = parseInt(params[key].split(".")[1])
          const isValidPrevValue = !isNaN(inputStepIndex) && inputStepIndex < i
          if (!isValidPrevValue) {
            toast({
              status: "error",
              title: `Step ${i + 1}: Invalid image input`,
              message: [`Input can only be taken from previous steps`],
            })
            selectChainItem(i)

            return setTimeout(() => validateParams(), 0)
          }
          // Validate previous step's output type
          const prevStepRecipe = selectedRecipes.find(recipe => recipe.id === chains[inputStepIndex].recipeId)

          const validOutputType =
            prevStepRecipe?.outputType?.includes(RecipeOutputType.Image) && prevStepRecipe?.outputType?.length === 1 // Should only have image output

          if (!validOutputType) {
            toast({
              status: "error",
              title: `Step ${i + 1}: Invalid image input`,
              message: [`Input can only accept image type`],
            })
            selectChainItem(i)
            return setTimeout(() => validateParams(), 0)
          }
        }
      }

      for (const key of requiredKeys) {
        const depends = key.depends ? recipeInputStep.find(i => i.key === key.depends) : undefined
        if (!depends && params[key.key] === undefined) {
          toast({
            status: "error",
            title: "Can not run macro!",
            message: [`Missing value for required input "${formatInputKey(key.key)}" of step "${i + 1}.`],
          })
          selectChainItem(i)
          return setTimeout(() => validateParams(), 0)
        } else if (depends && params[key.key] === undefined && params[depends.key] !== undefined) {
          toast({
            status: "error",
            title: "Can not run macro!",
            message: [`Missing value for required input "${formatInputKey(key.key)}" of step "${i + 1}.`],
          })
          selectChainItem(i)
          return setTimeout(() => validateParams(), 0)
        }
      }
    }

    clearUrl(["draft", "index", "duplicate"])

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Generate Macro",
        macro_id: workflowId,
        macro_name: inputName,
      },
    })

    if (!user) {
      if (tempTask && tempTask?.length >= 5) {
        toast({
          status: "error",
          title: "You have reached the limit of 5 tasks",
          message: ["Please sign in to continue."],
        })

        setSignInModal({
          signIn: true,
        })

        return
      }

      const captchaToken = await generateCaptchaToken()

      mutateUpdateTaskNotAuth({
        taskId: workflowId,
        data: {
          status: RecipeTaskStatus.QUEUED,
          params: chains as any,
          captchaToken,
        },
      })
      return
    }

    mutateUpdateTask({
      taskId: workflowId,
      data: {
        folderId: folder?.id,
        name: form.getValues("name"),
        status: RecipeTaskStatus.QUEUED,
        params: chains as any,
      },
    })
  }

  const [wildcardsState, setWildcardsState] = useState<WildcardDetail[]>([])

  useEffect(() => {
    const handleGetWildcardIds = async () => {
      if (!macroQuery.data) return
      if (isFirstRender.current) return

      const wildcardIds = getWildcardIdsByWorkflow(macroQuery.data)

      if (wildcardIds.length === 0) return setWildcardsState([])

      const wildcardsRes = await mutateWildcardsRes({
        ids: wildcardIds,
      }).then(res => res.wildcards)

      isFirstRender.current = true
      setWildcardsState(wildcardsRes)
    }

    if (macroQuery.data) {
      handleGetWildcardIds()
    }
  }, [macroQuery.data])

  const renderBody = () => {
    if (macroQuery.isLoading || !isFetchedRecipes || workflowId === "new-macro")
      return (
        <div className="flex items-center justify-center flex-1 w-full text-gray-600">
          <LoadingLogo />
        </div>
      )

    if (isError) return <ErrorUI />

    return (
      <FormProvider {...form}>
        <form className="flex flex-col flex-1" onSubmit={generate}>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="flex flex-1 w-full ">
            <div className="w-full flex-1 flex">
              <div className="w-full flex-1 flex">
                <WorkflowSetting
                  form={form}
                  isLoading={isLoadingWildcards}
                  wildcardsState={wildcardsState}
                  isEnabled={isEnabled}
                  workflowState={macroQuery.data}
                  isOpenSideBar={isOpenSideBar}
                  recipeInputs={recipeInputs}
                  selectedItem={selectedItem}
                  selectedRecipe={selectedRecipe}
                  setFolder={setFolder}
                  setIsOpenSidebar={setIsOpenSidebar}
                  setRecipeInputs={setRecipeInputs}
                  setSelectedRecipe={setSelectedRecipe}
                  chains={chains}
                  chainRecipe={chainRecipe}
                  folder={folder}
                />
                <div className="flex-1 flex flex-col">
                  <WorkflowContainer
                    inputName={inputName}
                    sendRecipe={sendRecipe}
                    isCompletedTask={isCompletedTask}
                    recipes={selectedRecipes}
                    workflowState={macroQuery.data}
                    onInputNameChange={v => setInputName(v)}
                    isLoadingGenerate={isLoadingGenerate}
                    chains={chains}
                    onCreateTask={({ folderId, recipeId, params }) =>
                      mutateCreateTask({
                        folderId,
                        recipeId,
                        params,
                        name: `${inputName}`,
                      })
                    }
                  />
                  <WorkflowGenerate
                    recipes={selectedRecipes}
                    stylesNotFound={stylesNotFound}
                    isLoadingRecipes={isLoading}
                    workflowQuery={macroQuery}
                    isLoadingUpdate={isLoadingUpdateAuth || isLoadingUpdateNotAuth}
                    chains={chains}
                    isEnabled={isEnabled}
                    onHandleAutoSaveDraft={v =>
                      handleAutoSaveDraft({
                        params: v.params ?? chains,
                        description: v.description,
                      })
                    }
                    removeChainItem={removeChainItem}
                    addChainItem={addChainItem}
                    duplicateChainItem={duplicateChainItem}
                    selectChainItem={selectChainItem}
                    selectedItem={selectedItem}
                    sendRecipe={sendRecipe}
                    setChains={setChains}
                    setIsOpenSidebar={setIsOpenSidebar}
                    workflowId={workflowId}
                    setIsCompletedTask={setIsCompletedTask}
                  />
                </div>
              </div>
            </div>
            <AnimatePresence mode="wait">
              {isOpenSideBar && isEnabled && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    transition: { duration: 0.2 },
                  }}
                  className={classNames("fixed lg:hidden inset-0 bg-blackAlpha-800 z-[1]")}
                  onClick={() => setIsOpenSidebar(false)}
                />
              )}
            </AnimatePresence>
          </motion.div>
        </form>
      </FormProvider>
    )
  }

  return (
    <Fragment>
      <Metadata title={inputName} />
      <div className="flex-1 w-full flex flex-col">{renderBody()}</div>
    </Fragment>
  )
}

export default RecipeDetail
