import { SDWorkflowFilterMode, SDWorkflowSortOption } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import TabsList from "@/components/Workspace/FoldersV2/TabsList"
import NotFoundItems from "@/components/Workspace/Pinned/NotFoundItems"
import { PlusIcon } from "@/components/shared/icons"
import { motion } from "framer-motion"
import { Fragment, useEffect, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import LoadingLogo from "../../LoadingLogo"
import ComfyUIRecipeCard from "./ComfyUIRecipeCard"
import dynamic from "next/dynamic"
import GridBreakpointColsContainer from "@/components/GridBreakpointColsContainer"
import { comfyUItabs, useComfyUiWorkflows } from "./ComfyUIRecipes"
import ComfyUITabActionsSlim from "./ComfyUITabActionsSlim"
import { useDebounce } from "@/hooks"
import { useRouter } from "next/router"
import ErrorUI from "@/components/ErrorUI"

const ComfyUISlim = ({ onSelect, onOpenCreateModal }: { onSelect?: () => void; onOpenCreateModal?: () => void }) => {
  const [search, setSearch] = useState("")
  const [tab, setTab] = useState(comfyUItabs[0].id)
  const debouncedSearch = useDebounce(search, 350)

  const mode =
    tab === comfyUItabs[0].id
      ? SDWorkflowFilterMode.Owned
      : tab === comfyUItabs[1].id
        ? SDWorkflowFilterMode.Shared
        : SDWorkflowFilterMode.Draft

  const [sortBy, setSort] = useState<SDWorkflowSortOption>(SDWorkflowSortOption.NEWEST)
  const [filter, setFilter] = useState<{
    shared?: string[]
  } | null>(null)

  const { workflows, isLoading, isFetching, isError, hasNextPage, fetchNextPage, setMode } = useComfyUiWorkflows(
    mode,
    debouncedSearch,
    sortBy,
    filter,
  )

  useEffect(() => setMode(mode), [mode])

  const router = useRouter()

  const containerRef = useRef<HTMLDivElement>(null)

  const mainContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setFilter(null)
    setSort(SDWorkflowSortOption.NEWEST)
  }, [mode])

  const renderBody = () => {
    if (isLoading)
      return (
        <div className="flex items-center justify-center w-full flex-1 text-gray-600">
          <LoadingLogo />
        </div>
      )

    if (isError) return <ErrorUI />

    if (workflows?.length === 0) return <NotFoundItems />

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.35, delay: 0.35 }}
        className={"relative flex-1"}
      >
        <InfiniteScroll
          loadMore={() => fetchNextPage()}
          hasMore={!!hasNextPage && !isFetching}
          getScrollParent={() => containerRef.current!}
          useWindow={false}
          threshold={600}
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "100%",
            position: "relative",
          }}
          loader={
            <div key="loader" className="flex items-center justify-center py-4">
              Loading...
            </div>
          }
        >
          <GridBreakpointColsContainer>
            {workflows?.map(workflow => (
              <ComfyUIRecipeCard
                key={workflow.id}
                workflow={workflow}
                onClick={() => {
                  onSelect?.()
                  router.push(`/workspace/tools/comfyui/${workflow.id}`)
                }}
                href={`/workspace/tools/comfyui/${workflow.id}`}
              />
            ))}
          </GridBreakpointColsContainer>
        </InfiniteScroll>
      </motion.div>
    )
  }

  return (
    <Fragment>
      <div className="flex-1 flex flex-col relative w-full overflow-hidden" ref={mainContainerRef}>
        <div className="flex w-full justify-between items-center p-4">
          <TabsList
            hiddenBorder
            tabQuery={tab}
            tabsData={comfyUItabs}
            onChange={tab => {
              setTab(tab)
            }}
          />
          <IconButton
            onClick={() => {
              onSelect?.()
              onOpenCreateModal?.()
            }}
            leftIcon={<PlusIcon height={16} width={16} />}
          >
            New <span className="hidden md:inline-block">comfyUI</span>
          </IconButton>
        </div>
        <div className="mb-2 px-4">
          <ComfyUITabActionsSlim
            search={search}
            setSearch={setSearch}
            filter={filter}
            setFilter={setFilter}
            mode={mode}
            sort={sortBy}
            setSort={setSort}
          />
        </div>
        <div className="flex-1 flex flex-col w-full overflow-auto p-4" ref={containerRef}>
          {renderBody()}
        </div>
      </div>
    </Fragment>
  )
}

export default ComfyUISlim
