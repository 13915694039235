import dynamic from "next/dynamic"
import {
  EntityType,
  ImageSimpleDetail,
  RecipeInputField,
  RecipeTaskStatus,
  RecipeTaskWithFullImages,
  SDBaseModel,
} from "@/api/sdk"
import LoadingLogo from "@/components/LoadingLogo"
import Modal from "@/components/Modal"
import { COMFY_RECIPE_ID } from "@/components/Tools/ComfyUIRecipe/ComfyUIRecipeBuilder/ComfyUIDetail"
import { TRY_STYLE_RECIPE } from "@/components/Tools/Style/StyleDetail/StyleDetail"
import { useToast } from "@/hooks"
import useContainerWidth from "@/hooks/useContainerWidth"
import { useProgressingContext } from "@/providers/ProgressingProvider"
import { useAuth } from "@/providers/authContext"
import { useGetHistoryTaskInfiniteQuery, useGetOngoingTaskQuery } from "@/queries/task/useGetTaskInfiniteQuery"
import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import React, { Fragment, memo, useEffect, useMemo, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import FeedbackPopup from "../../Feedback/FeedbackPopup"
import { getBatchSize } from "../utils"
import { useModelQuery } from "./FormDetail/FormDetailModel"
import AccordionRecipeItem from "./AccordionRecipeItem"
import ErrorUI from "@/components/ErrorUI"

const RecipeDetailTutorial = dynamic(() => import("@/components/TutorialsInstructions/RecipeDetailTutorial"))
const RecipeDetailTutorial2 = dynamic(() => import("@/components/TutorialsInstructions/RecipeDetailTutorial2"))

export interface MultiselectImagesProps {
  selectionMode?: boolean
  selectedItems?: ImageSimpleDetail[]
  onCheck?: (image: ImageSimpleDetail) => void
}

interface ListRecipesProps extends MultiselectImagesProps {
  sendRecipe: string
  setValues: (params: Record<string, any>) => void
  taskIds?: string[]
  styleId?: string
  folderId?: string
  hiddenActions?: string[]
  setRecipeInputs: React.Dispatch<React.SetStateAction<(RecipeInputField & { isChecked?: boolean })[]>>
  isLoadingCreateTask: boolean
  recipeName?: string
  recipeInputs: (RecipeInputField & { isChecked?: boolean })[]
  onCloseSidebar?: () => void
  onCreateTask: ({
    folderId,
    recipeId,
    params,
  }: {
    folderId: string
    recipeId: string
    params: Record<string, any>
  }) => void
}

export type RecipeSocketData = {
  id: string
  resultImages: string[]
  recipeId: string
  recipeName: string
  resultText?: string
}

const ListRecipes = ({
  sendRecipe,
  setValues,
  isLoadingCreateTask,
  hiddenActions,
  setRecipeInputs,
  folderId,
  recipeInputs,
  recipeName,
  onCreateTask,
  taskIds,
  styleId,
  onCloseSidebar,
  selectionMode,
  onCheck,
  selectedItems,
}: ListRecipesProps) => {
  const router = useCustomRouter()
  const [isFirstTaskCompleted, setIsFirstTaskCompleted] = useState(false)
  const [modal, setModal] = useState<string | null>(null)
  const { loading } = useAuth()

  const {
    data: onGoingRecipes,
    isLoading: isLoadingOnGoing,
    isError: isErrorOnGoing,
  } = useGetOngoingTaskQuery({
    variables: {
      recipeIds: [sendRecipe],
      styleIds: styleId ? [styleId] : undefined,
      taskIds: taskIds ? taskIds : undefined,
    },
  })

  const searchQuery = (router.query.search as string | undefined) ?? ""
  const recipeOngoingKey = useGetOngoingTaskQuery.getKey({
    recipeIds: [sendRecipe],
    styleIds: styleId ? [styleId] : undefined,
    taskIds: taskIds ? taskIds : undefined,
  })

  const {
    hasNextPage,
    fetchNextPage,
    isLoading: isLoadingHistory,
    isFetching,
    flattenData: mappedHistoryRecipes = [],
    isError: isErrorHistory,
    refetch: refetchHistory,
  } = useGetHistoryTaskInfiniteQuery({
    variables: {
      recipeIds: [sendRecipe],
      search: searchQuery,
      taskIds: taskIds ? taskIds : undefined,
      styleIds: styleId ? [styleId] : undefined,
    },
    enabled: !!sendRecipe && !loading,
  })

  const recipeHistoryKey = useGetHistoryTaskInfiniteQuery.getKey({
    recipeIds: [sendRecipe],
    search: searchQuery,
    taskIds: taskIds ? taskIds : undefined,
    styleIds: styleId ? [styleId] : undefined,
  })

  const isLoading = isLoadingOnGoing || isLoadingHistory
  const isError = isErrorOnGoing || isErrorHistory

  const mappedOngoingRecipes = useMemo(
    () =>
      [...(onGoingRecipes?.running ?? []), ...(onGoingRecipes?.queued ?? [])].sort(
        (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      ) as RecipeTaskWithFullImages[],
    [onGoingRecipes?.running, onGoingRecipes?.queued],
  )

  const mappedRecipes = useMemo(
    () =>
      [...mappedOngoingRecipes, ...(mappedHistoryRecipes ?? [])].map(r => ({
        ...r,
        fullDataImages:
          r.fullDataImages ??
          Array.from({ length: getBatchSize([r]) }, (_, i) => ({
            url: "",
            id: `${r.id}-${i}`,
          })),
      })),
    [mappedOngoingRecipes, mappedHistoryRecipes],
  )

  const { data: models } = useModelQuery({
    variables: {
      baseModel: SDBaseModel.All,
    },
  })

  const { socketRef, socketConnected, user } = useAuth()
  const { tasksProgress, setTasksProgress } = useProgressingContext()
  const qc = useQueryClient()

  const toast = useToast()

  useEffect(() => {
    if (!socketConnected || !socketRef.current) return

    socketRef.current.on("recipe-task-complete", async (data: RecipeSocketData) => {
      if (data) {
        if (!mappedOngoingRecipes.map(i => i.id).includes(data.id)) return

        await qc.cancelQueries({ queryKey: recipeOngoingKey })

        qc.invalidateQueries({ queryKey: recipeOngoingKey })
        if (data.resultImages.length > 0 || data.resultText) {
          setTimeout(() => {
            if (data.recipeName) {
              toast({
                status: "success",
                title: "Your task is completed",
                message: [`"${data.recipeName}" Recipe has finished running!`],
              })
            }

            setTasksProgress(prev => prev?.filter(p => p.task_id !== data.id) ?? [])
            setIsFirstTaskCompleted(true)
            refetchHistory()
          }, 250)
          return
        }

        setTasksProgress(prev => prev?.filter(p => p.task_id !== data.id) ?? [])

        if (data.recipeName) {
          toast({
            status: "error",
            title: "Failed to run task",
            message: [`"${data.recipeName}" Recipe has failed running!`],
          })
        }
      }
    })

    return () => {
      socketRef.current?.off("recipe-task-complete")
    }
  }, [socketConnected, socketRef, refetchHistory, setTasksProgress, toast, mappedOngoingRecipes.length])

  const containerRef = useRef<HTMLDivElement>(null)

  const containerWidth = useContainerWidth(containerRef, !isLoading)

  if (isLoading)
    return (
      <div className="flex items-center justify-center flex-1 w-full text-gray-600">
        <LoadingLogo />
      </div>
    )

  if (isError) return <ErrorUI />

  if (!mappedRecipes?.length) {
    return (
      <div className="flex items-center justify-center flex-1 w-full recipe-generated-images">
        <p className="text-lg text-atherGray-500">No tasks found</p>
        {sendRecipe !== "image-to-text" && sendRecipe !== TRY_STYLE_RECIPE && (
          <RecipeDetailTutorial onCloseSidebar={onCloseSidebar} />
        )}
      </div>
    )
  }

  return (
    <Fragment>
      <div id="gallery-images" className={classNames("flex flex-col flex-1 w-full recipe-generated-images")}>
        <div ref={containerRef} className="relative flex flex-col w-full flex-1 px-2 pb-2 md:px-4 md:pb-4">
          <InfiniteScroll
            loadMore={() => fetchNextPage()}
            hasMore={!!hasNextPage && !isFetching}
            useWindow={true}
            threshold={600}
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "100%",
              position: "relative",
            }}
            loader={
              <div key="loader" className="flex items-center justify-center py-4">
                Loading...
              </div>
            }
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.35, delay: 0.35 }}
              className={classNames("relative flex flex-col")}
            >
              {mappedRecipes?.map(task => (
                <AccordionRecipeItem
                  recipeOngoingKey={recipeOngoingKey}
                  progressDatas={tasksProgress}
                  selectionMode={selectionMode}
                  selectedItems={selectedItems}
                  hiddenActions={hiddenActions}
                  onCheck={onCheck}
                  onSelectedRatingItem={setModal}
                  recipeName={recipeName}
                  models={models?.models}
                  recipeId={sendRecipe}
                  recipeHistoryKey={recipeHistoryKey}
                  isLoadingCreateTask={isLoadingCreateTask}
                  key={task.id}
                  task={task as RecipeTaskWithFullImages}
                  setValues={setValues}
                  refetchHistory={refetchHistory}
                  recipeInputs={recipeInputs}
                  setRecipeInputs={setRecipeInputs}
                  containerWidth={containerWidth}
                  onCreateTask={newParams => {
                    onCreateTask({
                      folderId: task.folderId ?? folderId ?? "",
                      recipeId: sendRecipe,
                      params: newParams,
                    })
                  }}
                />
              ))}
            </motion.div>
          </InfiniteScroll>
        </div>
      </div>
      {sendRecipe !== "image-to-text" &&
        sendRecipe !== TRY_STYLE_RECIPE &&
        sendRecipe !== COMFY_RECIPE_ID &&
        mappedRecipes?.[0].status === RecipeTaskStatus.COMPLETED &&
        isFirstTaskCompleted && (
          <RecipeDetailTutorial2
            recipeTask={mappedRecipes?.[0] as RecipeTaskWithFullImages}
            onCloseSidebar={onCloseSidebar}
          />
        )}

      <Modal
        className="max-w-fit p-0"
        bodyClassName="p-0"
        closeButtonClassName="absolute top-2 right-2"
        isOpen={!!modal}
        onClose={() => setModal(null)}
      >
        {modal && (
          <FeedbackPopup
            isModal
            debounceIsOpen={true}
            entityId={modal}
            entityType={EntityType.RECIPE_TASK}
            onSuccess={() => setModal(null)}
            className="w-[20rem]"
          />
        )}
      </Modal>
    </Fragment>
  )
}

export default memo(ListRecipes)
