import client from "@/api/client"
import { SDStyleDetail, SearchSDStyleDto, SearchSDStyleResponse } from "@/api/sdk"
import { createAuthenticatedInfiniteQuery, createAuthenticatedQuery } from "@/queries/createAuthenticatedQuery"
import { enableQueryMiddleware } from "@/queries/middlewares/enableQueryMiddleware"

export const useWorkspaceStyleInfiniteQuery = createAuthenticatedInfiniteQuery<
  SearchSDStyleResponse,
  SearchSDStyleDto,
  Error,
  SDStyleDetail
>({
  primaryKey: "style-list",
  queryFn: ({ queryKey: [_primaryKey, variables], pageParam = 0 }) =>
    client.api
      .sdStyleControllerList({
        skip: pageParam * (variables.take ?? 20),
        take: variables.take ?? 20,
        searchTerm: variables.searchTerm,
        sortBy: variables.sortBy,
        ids: variables.ids,
        mode: variables.mode,
        onlyDiscoverable: variables.onlyDiscoverable,
        onlyShared: variables.onlyShared,
      })
      .then(res => res.data),
  flattenFn: data => data.data,
  getTotalFn: data => data.total,
  getNextPageParam: (lastPage, pages) => (lastPage && lastPage?.data.length < 20 ? undefined : pages.length),
  initialPageParam: 0,
  refetchOnWindowFocus: false,
})

export const useWorkspaceStyleDetailQuery = createAuthenticatedQuery<SDStyleDetail, { styleId: string }, Error>({
  primaryKey: "style-detail",
  queryFn: ({ queryKey: [_primaryKey, variables] }) =>
    client.api.sdStyleControllerGetStyle(variables.styleId!).then(res => res.data),
  use: [enableQueryMiddleware(variables => !!variables.styleId)],
})
