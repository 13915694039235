import client from "@/api/client"
import { useAuthenticatedQuery } from "@/hooks"
import classNames from "classnames"
import React, { useEffect, useState } from "react"
import IconButton from "../../IconButton"
import { RefreshIcon } from "@/components/shared/icons"

const VersionApp = () => {
  const [isOutDated, setIsOutDated] = useState(false)
  const clientVersion = process.env.NEXT_PUBLIC_CLIENT_VERSION ?? "0.0.0"

  const { data, isSuccess } = useAuthenticatedQuery(
    ["version-app"],
    () => client.api.versioningControllerGetAppCurrentVersions().then(res => res.data),
    {
      refetchInterval: 1000 * 60 * 60,
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    if (isSuccess && data) {
      let isOutDated = false
      if (clientVersion) {
        if (clientVersion !== data?.frontendVersion) {
          isOutDated = true
        }
      }
      setIsOutDated(isOutDated)
    }
  }, [isSuccess, data])

  return (
    <div className={classNames("flex items-center justify-center text-xs text-center py-1 text-atherGray-400")}>
      v{clientVersion}{" "}
      {isOutDated ? (
        <div className="flex items-center">
          <p className="mr-2">(Outdated)</p>
          <IconButton
            onClick={() => window.location.reload()}
            className="p-0 min-h-0 bg-transparent hover:bg-transparent"
          >
            <RefreshIcon width={14} height={14} />
          </IconButton>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default VersionApp
