import useCustomRouter from "@/hooks/useCustomRouter"
import TabsList from "../FoldersV2/TabsList"
import RecentImages from "./RecentImages"
import RecentFolders from "./RecentFolders"
import subDays from "date-fns/subDays"
import RecentWorkflow from "./RecentWorkflow"
import RecentRecipes from "./RecentRecipes"
import RecentStyles from "./RecentStyles"
import RecentWildcards from "./RecentWildcards"
import RecentComfyUI from "./RecentComfyUI"
import WorkspaceContainer from "../WorkspaceContainer"

const tabsData = [
  {
    title: "Macros",
    id: "macros",
  },
  {
    title: "Recipes",
    id: "recipes",
  },
  {
    title: "Folders",
    id: "folders",
  },
  {
    title: "Images",
    id: "images",
  },
  {
    title: "Styles",
    id: "styles",
  },
  {
    title: "ComfyUI",
    id: "comfyui",
  },
  {
    title: "Wildcards",
    id: "wildcards",
  },
]

export const formatFilterDate = (type: string) => {
  if (type === "today") {
    return {
      fromDate: subDays(new Date(), 1),
      toDate: new Date(),
    }
  }
  if (type === "all") {
    return {
      fromDate: undefined,
      toDate: undefined,
    }
  }
  if (type === "last 7 days") {
    return {
      fromDate: subDays(new Date(), 7),
      toDate: new Date(),
    }
  }
  if (type === "last 30 days") {
    return {
      fromDate: subDays(new Date(), 30),
      toDate: new Date(),
    }
  }
  if (type === "older") {
    return {
      fromDate: undefined,
      toDate: subDays(new Date(), 30),
    }
  }
}

export const RecentV2 = () => {
  const router = useCustomRouter()

  const tabQuery =
    (router.query.tab as string) && tabsData.some(tab => tab.id === router.query.tab)
      ? (router.query.tab as string)
      : "macros"

  const renderTabs = () => {
    switch (tabQuery) {
      // case "all":
      //   return <RecentAll />
      case "macros":
        return <RecentWorkflow />
      case "recipes":
        return <RecentRecipes />
      case "folders":
        return <RecentFolders />
      case "images":
        return <RecentImages />
      case "styles":
        return <RecentStyles />
      case "wildcards":
        return <RecentWildcards />
      case "comfyui":
        return <RecentComfyUI />
      default:
        return <RecentWorkflow />
    }
  }

  return (
    <div className="flex-1 p-4 lg:p-6 flex flex-col relative w-full">
      <WorkspaceContainer className="flex-1">
        <h3 className="text-xl font-bold mb-4">Recent</h3>
        <div className="flex-1 flex flex-col">
          <TabsList tabQuery={tabQuery} tabsData={tabsData} />
          <div className="flex-1 mt-2 flex flex-col">{renderTabs()}</div>
        </div>
      </WorkspaceContainer>
    </div>
  )
}
