import { ComponentProps, ComponentType } from "react"
import { UrlObject } from "url"
import dynamic from "next/dynamic"

const StyleDetailWorkspace = dynamic(() => import("@/components/Explore/StyleDetail"), { ssr: false })
const StyleDetailExplore = dynamic(() => import("@/components/Explore/StyleDetail"), { ssr: false })
const RecipeDetailExplore = dynamic(() => import("@/components/Explore/RecipeDetail/RecipeDetailModal"), { ssr: false })
const ComfyUIDetailExplore = dynamic(() => import("@/components/Explore/ComfyUIDetail/ComfyUIDetailModal"), {
  ssr: false,
})

const ArticleUIDetailExplore = dynamic(() => import("@/components/Explore/ArticleDetail/ArticleDetailModal"), {
  ssr: false,
})

const ImageDetailAlpha = dynamic(() => import("@/components/Alpha/ImageDetailAlpha/ImageDetailAlphaModal"), {
  ssr: false,
})
const ImageDetailModal = dynamic(() => import("@/components/Explore/ImageDetail/ImageDetailModal"), { ssr: false })
const ImageDetailWorkspaceModal = dynamic(
  () => import("@/components/Workspace/ImageDetailWorkspace/ImageDetailWorkspaceModal"),
  { ssr: false },
)

const WorkflowDetailModal = dynamic(() => import("@/components/Explore/WorkflowDetail/WorkflowDetailModal"), {
  ssr: false,
})
const WildcardDetailWorkspace = dynamic(
  () => import("@/components/Tools/Wildcards/WildcardDetail/WildcardDetailModal"),
  { ssr: false },
)

type Url = UrlObject | string

type DialogItem<T> = {
  requireAuth?: boolean
  component: ComponentType<T>
  target?: string
  resolve: (
    query: Record<string, unknown>,
    args: ComponentProps<ComponentType<T>>,
  ) => { query: Record<string, unknown>; asPath?: Url; state?: Record<string, unknown> }
}

type DialogRegistry<T extends Record<string, any>> = { [K in keyof T]: DialogItem<T[K]> }

function createDialogDictionary<T extends Record<string, unknown>>(dictionary: DialogRegistry<T>): DialogRegistry<T> {
  return dictionary
}

export const dialogs = createDialogDictionary({
  imageDetailExplore: {
    component: ImageDetailModal,
    resolve: (query, { imageId, ...state }) => ({
      query: { ...query, imageId },
      asPath: `/explore/images/${imageId}`,
      state,
    }),
  },
  imageDetailWorkspace: {
    component: ImageDetailWorkspaceModal,
    resolve: (query, { imageId, ...state }) => ({
      query: { ...query, imageId },
      asPath: `/workspace/images/${imageId}`,
      state,
    }),
  },
  imageDetailAlpha: {
    component: ImageDetailAlpha,
    resolve: (query, { imageId, ...state }) => ({
      query: { ...query, imageId },
      asPath: `/ai-recipes/${imageId}`,
      state,
    }),
  },
  workflowDetailExplore: {
    component: WorkflowDetailModal,
    resolve: (query, { workflowId, ...state }) => ({
      query: { ...query, workflowId },
      asPath: `/explore/macros/${workflowId}`,
      state,
    }),
  },
  styleDetailExplore: {
    component: StyleDetailExplore,
    resolve: (query, { styleId, ...state }) => ({
      query: { ...query, styleId },
      asPath: `/explore/styles/${styleId}`,
      state,
    }),
  },
  recipeDetailExplore: {
    component: RecipeDetailExplore,
    resolve: (query, { recipeId, ...state }) => ({
      query: { ...query, recipeId },
      asPath: `/explore/recipes/${recipeId}`,
      state,
    }),
  },
  comfyuiDetailExplore: {
    component: ComfyUIDetailExplore,
    resolve: (query, { sdWorkflowId, ...state }) => ({
      query: { ...query, sdWorkflowId },
      asPath: `/explore/comfyui/${sdWorkflowId}`,
      state,
    }),
  },
  articleDetailExplore: {
    component: ArticleUIDetailExplore,
    resolve: (query, { articleId, ...state }) => ({
      query: { ...query, articleId },
      asPath: `/explore/articles/${articleId}`,
      state,
    }),
  },
  styleDetailWorkspace: {
    component: StyleDetailWorkspace,
    resolve: (query, { styleId, ...state }) => ({
      query: { ...query, styleId },
      asPath: `/workspace/tools/styles/${styleId}`,
      state,
    }),
  },
  wildcardDetailWorkspace: {
    component: WildcardDetailWorkspace,
    resolve: (query, { wildcardId, ...state }) => ({
      query: { ...query, wildcardId },
      asPath: `/workspace/tools/wildcards/${wildcardId}`,
      state,
    }),
  },
})
