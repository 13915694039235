import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

type RecipeImage = {
  isModal: boolean
  type?: "image" | "wildcard"
  id: string
  params?: Record<string, any>
  onClose?: () => void
  onRemoveFocus?: () => void
} | null

interface RecipeImageStore {
  recipeImage: RecipeImage
  setRecipeImage: (recipeImage: RecipeImage) => void
}

export const useRecipeImageStore = create<RecipeImageStore>()(
  devtools(
    persist(
      set => ({
        recipeImage: null,
        setRecipeImage: recipeImage => set({ recipeImage }),
      }),
      {
        name: "send-to-recipe",
      },
    ),
  ),
)
