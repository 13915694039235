import { WEBSOCKET_URL } from "@/api/client"
import { ComfyRoomConnectedUser } from "@/api/sdk"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import Tooltip from "@/components/Tooltip"
import { useOutsideClick } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { useUsersConnectComfyQuery } from "@/queries"
import { useQueryClient } from "@tanstack/react-query"
import { AnimatePresence, motion } from "framer-motion"
import React, { memo, useCallback, useEffect, useRef, useState } from "react"
import { Socket, io } from "socket.io-client"

interface UsersConnectProps {
  triggerClickoutside?: boolean
  onTriggerClose?(): void
  workflowId?: string
}

type SocketLeftRoomType = {
  workflowId: string
}

const UserItem = ({ user, isTooltip }: { user: ComfyRoomConnectedUser; isTooltip?: boolean }) => {
  const [isOpenTooltip, setIsOpenTooltip] = useState(false)

  if (isTooltip)
    return (
      <div onMouseLeave={() => setIsOpenTooltip(false)} key={user.uid} className="flex items-center">
        <Tooltip
          open={isOpenTooltip}
          setOpen={setIsOpenTooltip}
          trigger={
            <div>
              <UserCreated imageClassName="w-6 h-6" size="xs" hiddenName picture={user.picture} name={user.name} />
            </div>
          }
        >
          <p>{user.name}</p>
        </Tooltip>
      </div>
    )

  return <UserCreated imageClassName="w-6 h-6" key={user.uid} size="xs" picture={user.picture} name={user.name} />
}

const UsersConnect = ({ triggerClickoutside, onTriggerClose, workflowId }: UsersConnectProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)
  const { bearerToken, userInfoQuery } = useAuth()
  const socketRef = useRef<Socket>()
  const qc = useQueryClient()
  const [socketConnected, setSocketConnected] = useState(false)

  const { data: usersConnect = [] } = useUsersConnectComfyQuery({
    variables: { workflowId: workflowId ?? "" },
  })

  const key = useUsersConnectComfyQuery.getKey({ workflowId: workflowId ?? "" })

  const refetch = useCallback(async () => {
    await qc.cancelQueries({
      queryKey: key,
    })

    qc.invalidateQueries({
      queryKey: key,
    })
  }, [key, qc])

  useEffect(() => {
    if (!bearerToken) return

    socketRef.current = io(`${WEBSOCKET_URL}/api/comfyui`, {
      extraHeaders: {
        Authorization: `Bearer ${bearerToken}`,
      },
      withCredentials: true,
    })

    if (socketRef.current && !socketRef.current.connected) {
      socketRef.current.connect()

      socketRef.current.on("connect", () => {
        setSocketConnected(true)
      })
    }

    return () => {
      if (socketRef.current) {
        setSocketConnected(false)
        socketRef.current.disconnect()
      }
    }
  }, [bearerToken])

  useEffect(() => {
    if (!socketConnected || !socketRef.current || !userInfoQuery?.data || !workflowId) return

    socketRef.current.on("workflow:user-join", async (data: SocketLeftRoomType) => {
      // Refresh get connected users api
      await refetch()
    })

    socketRef.current.on("workflow:user-left", async (data: SocketLeftRoomType) => {
      // Refresh get connected users api
      await refetch()
    })

    const timer = setTimeout(() => {
      if (!socketRef.current) return
      socketRef.current.emit("workflow:join", workflowId)
    }, 1000)

    return () => {
      clearTimeout(timer)

      if (socketRef.current) {
        socketRef.current.off("workflow:user-join")
        socketRef.current.off("workflow:user-left")
      }
    }
  }, [socketConnected, workflowId, userInfoQuery, refetch])

  useOutsideClick({
    ref,
    enabled: isOpen,
    handler: () => setIsOpen(false),
  })

  useEffect(() => {
    if (!triggerClickoutside || !isOpen) return
    setIsOpen(false)
    onTriggerClose?.()
  }, [triggerClickoutside, onTriggerClose, isOpen])

  if (!usersConnect.length) return <></>

  return (
    <div ref={ref} className="px-2 relative">
      <div className="flex items-center space-x-1">
        {usersConnect?.slice(0, 3)?.map(user => <UserItem key={user.uid} isTooltip user={user} />)}
        {usersConnect && (usersConnect?.length ?? 0) > 3 && (
          <button
            type="button"
            onClick={() => setIsOpen(prev => !prev)}
            className="bg-atherGray-800 font-semibold w-6 h-6 rounded-full text-xs flex items-center justify-center"
          >
            +{usersConnect.length - 3}
          </button>
        )}
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0.5, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0.5, height: 0 }}
            className={
              "absolute top-full mt-1 right-0 sm:right-0 w-[14rem] max-h-[20rem] z-20 overflow-hidden shadow-xl"
            }
          >
            <div className="flex flex-col bg-atherGray-900 border-[1px] p-4 border-[#323230] overflow-hidden rounded-2xl">
              {usersConnect?.slice(3)?.map(user => <UserItem key={user.uid} user={user} />)}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default memo(UsersConnect)
