import { useToast } from "@/hooks"
import React, { memo, useEffect, useState } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { css } from "@emotion/css"
import classNames from "classnames"
import ReactSlider from "react-slider"
import IconButton from "../../IconButton"
import UploadRecipeImage from "../UploadRecipeImage"
import { useRecipeImageStore } from "@/stores"
import { isMobileOnly } from "react-device-detect"
import ScrollHorizontalContainer from "../ScrollHorizontalContainer"
import useUploadImage from "../hooks/useUploadImage"
import useCreateTask from "../hooks/useCreateTask"
import useGetListTask from "../hooks/useGetListTask"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useTempUploadImagesStore } from "@/stores/useTempUploadImagesStore"
import { useStateOnRunningTaskRecipesStore } from "@/stores/useOnRunningTaskRecipesStore"
import { InfoIcon } from "@/components/shared/icons"
import Tooltip from "@/components/Tooltip"
import { cdnPublicFolderUrl } from "@/constants"

interface FormRecipeAnimeProps {
  recipeId: string
}

export const options = [
  {
    display: "Ghibli",
    value: "ghibli",
    image: "/images/alpha/ghibi.png",
  },
  {
    display: "Anime",
    value: "anime",
    image: "/images/alpha/anime.png",
  },
  {
    display: "Cartoon",
    value: "cartoon",
    image: "/images/alpha/cartoon.png",
  },
  {
    display: "Semi-Real",
    value: "semi-real",
    image: "/images/alpha/semi-real.png",
  },
  {
    display: "Manga",
    value: "manga",
    image: "/images/alpha/manga.png",
  },
].map(item => ({
  ...item,
  image: `${cdnPublicFolderUrl}${item.image}`,
}))

const FormAnimeRecipe = ({ recipeId }: FormRecipeAnimeProps) => {
  const [_, setState] = useStateOnRunningTaskRecipesStore()

  const toast = useToast({
    position: !isMobileOnly ? "top-center" : "bottom-center",
  })

  const [imageUploaded, setImageUploaded] = useState<string | undefined>(undefined)
  const [recipeImage, setRecipeImage] = useRecipeImageStore(state => [state.recipeImage, state.setRecipeImage])

  const { infoUser, onGoingRecipes } = useGetListTask({ recipeId })

  const { mutate: mutateCreateTask, isPending: isLoadingCreate } = useCreateTask({
    recipeId,
  })

  const { mutateAsync: mutateUpload, isPending: isLoadingUpload } = useUploadImage(recipeId)

  const form = useForm<{ [key: string]: any }>({
    mode: "onSubmit",
    defaultValues: {
      creative_intensity: 5,
    },
  })

  const { control, setValue } = form

  const value = useWatch({
    control,
  })

  const [file, setFile] = useState<File | undefined>(undefined)

  const isDisabled = (!file && !imageUploaded) || !value["creative_intensity"] || !value["style"]

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (isDisabled) return

    if (infoUser && !infoUser?.email?.includes("@atherlabs.com") && infoUser && infoUser?.availableGeneration === 0) {
      toast({
        status: "error",
        title: "You don't have enough credit to run this recipe",
      })
      return
    }

    if ([...(onGoingRecipes?.running ?? []), ...(onGoingRecipes?.queued ?? [])].length >= 10) {
      toast({
        status: "error",
        title: "You have reached the maximum number of running this recipe",
        message: ["Please wait for the current recipe to finish running."],
      })
      return
    }

    const data = form.getValues()

    let isTrigger = true
    let link = ""

    await form.trigger()

    if (!isTrigger) {
      return
    }

    if (!imageUploaded) {
      link = await mutateUpload([file as File])
      setImageUploaded(link)
    }

    setState(prev => {
      return {
        ...prev,
        [recipeId]: {
          runningTasks: [],
        },
      }
    })

    mutateCreateTask({
      recipeId: recipeId,
      params: {
        ...data,
        image: imageUploaded ?? link,
      },
    })
  }

  useEffect(() => {
    if (recipeImage && !recipeImage.isModal) {
      const timeout = setTimeout(() => {
        if (!recipeImage.params) return

        const params = Object.entries(recipeImage.params).reduce((acc, [key, value]) => {
          if (key === "image") return acc
          return {
            ...acc,
            [value.key]: value.value,
          }
        }, {})

        form.reset({
          style: params["style"],
          creative_intensity: params["creative_intensity"],
        })

        setImageUploaded(recipeImage.params["image"] ?? undefined)
        setRecipeImage(null)
      }, 500)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [recipeImage])

  const router = useCustomRouter()
  const { tempUploadImages, setTempUploadImages } = useTempUploadImagesStore()

  useEffect(() => {
    if (router.isReady && tempUploadImages) {
      setImageUploaded(tempUploadImages.find(item => item.recipeId === recipeId)?.url ?? undefined)
    }
  }, [router.isReady])

  return (
    <div className="flex flex-col items-center flex-shrink-0 bg-[rgb(49,49,49)] flex-1 rounded-xl">
      <div className="w-full flex flex-col flex-1 items-center p-4">
        <FormProvider {...form}>
          <form onSubmit={submit} className="flex-1 flex flex-col w-full">
            <div className="flex-1 space-y-6">
              <div
                className={css({
                  width: "100%",
                })}
              >
                <UploadRecipeImage
                  preview={imageUploaded}
                  value={file}
                  onChange={file => {
                    setFile(file)
                    setImageUploaded(undefined)
                    if (!file && tempUploadImages) {
                      setTempUploadImages(tempUploadImages.filter(item => item.recipeId !== recipeId))
                    }
                  }}
                  title="Upload Image"
                />
              </div>
              <div className="w-full">
                <p className="text-sm mb-1">Select Style</p>
                <ScrollHorizontalContainer containerClassName="mb-1 space-x-2">
                  {options.map(option => (
                    <div
                      className="cursor-pointer flex-shrink-0 "
                      key={option.value}
                      onClick={() => {
                        setValue("style", option.value)
                      }}
                    >
                      <img
                        className={classNames("border-2 rounded-xl h-[8rem] mb-1", {
                          "border-[#7053FF]": value["style"] === option.value,
                          "border-transparent": value["style"] !== option.value,
                        })}
                        src={option.image}
                        alt={option.display}
                      />
                      <p
                        className={classNames("text-xs font-[300] text-center", {
                          "font-semibold": value["style"] === option.value,
                        })}
                      >
                        {option.display}
                      </p>
                    </div>
                  ))}
                </ScrollHorizontalContainer>
              </div>
              <div className="w-full flex  flex-col md:flex-row md:items-center md:justify-between ">
                <div className="md:mr-16 flex items-center mb-4 md:m-0 ">
                  <p className="font-[300] text-sm ">Select Creative Intensity</p>
                  <Tooltip
                    trigger={
                      <div className="ml-1">
                        <InfoIcon className="text-atherGray-600" width={14} height={14} />
                      </div>
                    }
                  >
                    Creative Intensity (or Denoising Strength) controls how much the image will change. We suggest to
                    use value from 3 to 5 as good starting point that eliminate too much changes.
                  </Tooltip>
                </div>
                <div
                  className={css({
                    position: "relative",
                    flex: 1,
                    maxWidth: "25rem",
                    width: "100%",
                    ".track-slider-0": {
                      backgroundColor: "#A670FF",
                    },
                    ".track-slider-1": {
                      backgroundColor: "#4B4B4B",
                    },
                  })}
                >
                  <div className={classNames("relative items-center flex w-full")}>
                    <ReactSlider
                      value={value["creative_intensity"]}
                      onChange={v => setValue("creative_intensity", v)}
                      min={1}
                      max={10}
                      step={0.5}
                      className="h-1 horizontal-slider w-full"
                      thumbClassName="h-4 w-4 bg-white top-1/2 -translate-y-1/2 rounded-full cursor-pointer"
                      trackClassName="h-1 track-slider"
                      renderThumb={(props, _) => (
                        <div {...props}>
                          <div className="bg-[#A670FF] w-2.5 h-2.5 absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] rounded-full" />
                        </div>
                      )}
                    />
                    <div className="py-0.5 w-[4rem] text-sm flex justify-center bg-atherGray-600 ml-2 rounded-md">
                      <p>{value["creative_intensity"]}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <IconButton
                isLoading={isLoadingCreate || isLoadingUpload}
                type="submit"
                className={classNames("bg-[#7053FF] hover:bg-[#7053FF] w-full active:bg-[#7053FF] px-6 font-semibold", {
                  "text-[#313131] bg-[#464646] cursor-not-allowed pointer-events-none": isDisabled,
                })}
              >
                Generate
              </IconButton>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  )
}

export default memo(FormAnimeRecipe)
