import IconButton from "@/components/IconButton"
import ResizeContainer from "@/components/Workspace/ChatRooms/Room/Resize"
import classNames from "classnames"
import { motion } from "framer-motion"
import React, { useState } from "react"
import ComfyUIRecipeComments from "./ComfyUIRecipeComments"
import ComfyUIRecipeInfo from "./ComfyUIRecipeInfo"
import ComfyUIRecipesPublished from "./ComfyUIRecipesPublished"
import ComfyUIWorkflowSettings, { ComfyUIWorkflowSettingsRef } from "./ComfyUIWorkflowSettings"
import ComfyUIWorkflowSteps, { ComfyUIWorkflowStepsRef } from "./ComfyUIWorkflowSteps"
import ComfyUIRecipeTasks from "./RecipeTask"
import { XIcon } from "@/components/shared/icons"
import { FolderDetail, RecipeStep, SDWorkflowDetail, Tag } from "@/api/sdk"
import { UseFormReturn } from "react-hook-form"
import { GraphField } from "@/components/ComfyUI/utils/graph"
import { CheckableRecipeInput } from "@/components/Workspace/Recipes/RecipeDetail"
import Postmate from "@/lib/postmate"
import { ModeComfy, SettingTab } from "./ComfyUIDetail"
import ComfyUIPromptLibrary from "./ComfyUIPromptLibrary"

interface LeftBarOptionProps {
  isLeftbarOpen: boolean
  handleToggleAction: () => void
  mode: ModeComfy | null
  tab: string
  selectedNodes: string[]
  handleSwitchTab: (value: SettingTab) => void
  handleSelectComfyUINode: (key: string) => void
  workflow?: SDWorkflowDetail
  isEditing: boolean
  canvasClick: boolean
  setCanvasClick: (value: boolean) => void
  isUploading: boolean
  preview: string | null
  setPreview: React.Dispatch<React.SetStateAction<string | null>>
  file: File | null
  setFile: (value: File | null) => void
  postmate?: Postmate.ParentAPI
  tags: Tag[]
  setTags: (value: Tag[]) => void
  name: string | null
  description?: string
  setDescription: (value: string) => void
  setIsTaskOnGoing: (value: boolean) => void
  setIsCompletedTask: (value: boolean) => void
  handleGenerate: () => void
  isCreatingTask: boolean
  folder?: FolderDetail
  setFolder: (value?: FolderDetail) => void
  workflowId: string
  setName: (value: string) => void
  settingsRef: React.RefObject<ComfyUIWorkflowSettingsRef>
  settingsForm: UseFormReturn<RecipeStep, any, undefined>
  handleOpenPublishModal: () => void
  graphInputs: GraphField[]
  steps: RecipeStep[]
  handleConfigRecipe: () => void
  handleRecipeInputChange: (value: CheckableRecipeInput[]) => void
  recipeFormRef: React.RefObject<ComfyUIWorkflowStepsRef>
}

const LeftBarOption = ({
  canvasClick,
  handleSelectComfyUINode,
  handleToggleAction,
  isEditing,
  setCanvasClick,
  isLeftbarOpen,
  mode,
  selectedNodes,
  handleSwitchTab,
  workflow,
  isUploading,
  preview,
  setPreview,
  file,
  setFile,
  postmate,
  setName,
  tags,
  setTags,
  name,
  description,
  folder,
  handleGenerate,
  isCreatingTask,
  tab,
  setDescription,
  setFolder,
  setIsCompletedTask,
  setIsTaskOnGoing,
  workflowId,
  graphInputs,
  handleConfigRecipe,
  handleOpenPublishModal,
  handleRecipeInputChange,
  recipeFormRef,
  settingsForm,
  settingsRef,
  steps,
}: LeftBarOptionProps) => {
  const [isDragging, setIsDragging] = useState(false)

  return (
    <>
      <ResizeContainer
        border="right"
        style={{
          minWidth: "20rem",
          width: "20rem",
          maxWidth: "40rem",
          overflow: "hidden",
          position: "absolute",
          zIndex: isLeftbarOpen ? 2 : -1,
          opacity: isLeftbarOpen ? 1 : 0,
        }}
        onStart={() => setIsDragging(true)}
        onEnd={() => setIsDragging(false)}
        handlerStyle={{ backgroundColor: "#323230" }}
        handlerWidth={8}
        className={"absolute top-0 left-14 h-[calc((var(--vh,1vh)*100-3rem))] flex flex-col overflow-hidden"}
      >
        <IconButton
          className="absolute top-2 right-2 z-[1]"
          onClick={() => handleToggleAction()}
          colorScheme="transparent"
        >
          <XIcon />
        </IconButton>
        <div
          className={classNames("flex-1 flex flex-col overflow-hidden bg-atherGray-900", {
            hidden: mode !== "comment",
          })}
        >
          <ComfyUIRecipeComments
            selectedNodes={selectedNodes}
            isOpen={mode === "comment"}
            onSelectNode={handleSelectComfyUINode}
            workflow={workflow}
            isEditting={isEditing}
            canvasClick={canvasClick}
            onCanvasClose={() => setCanvasClick(false)}
          />
        </div>

        <div
          className={classNames("flex-1 flex flex-col overflow-auto w-full bg-atherGray-900", {
            hidden: mode !== "info",
          })}
        >
          <ComfyUIRecipeInfo
            isUploading={isUploading}
            preview={preview}
            setPreview={setPreview}
            file={file}
            setFile={setFile}
            postmate={postmate}
            tags={tags}
            setTags={setTags}
            workflow={workflow}
            name={name ?? ""}
            onNameChanged={setName}
            description={description}
            onDescriptionChanged={setDescription}
            isEditting={isEditing}
          />
        </div>

        <div
          className={classNames("flex-1 flex flex-col overflow-hidden bg-atherGray-900", {
            "w-0 flex-none h-0": mode !== "tasks-history",
          })}
        >
          <ComfyUIRecipeTasks
            onTaskOnGoing={setIsTaskOnGoing}
            onCompletedTask={task => setIsCompletedTask((task.resultImages || []).length > 0)}
            onGenerate={handleGenerate}
            isCreatingTask={isCreatingTask}
            folder={folder}
            setFolder={setFolder}
            workflowId={workflowId}
          />
        </div>

        <div
          className={classNames("flex-1 flex flex-col overflow-hidden bg-atherGray-900", {
            hidden: mode !== "publish-recipe",
          })}
        >
          <ComfyUIRecipesPublished sdWorkflowId={workflow?.id} isOpen={mode === "publish-recipe"} />
        </div>

        <div
          className={classNames("flex-1 flex flex-col overflow-hidden bg-atherGray-900", {
            hidden: mode !== "prompt-library",
          })}
        >
          <ComfyUIPromptLibrary sdWorkflowId={workflow?.id} isOpen={mode === "prompt-library"} />
        </div>

        <div
          className={classNames("flex-1 flex flex-col overflow-hidden bg-atherGray-900", {
            hidden: mode !== "convert-recipe",
          })}
        >
          <form
            className={classNames("flex-1 flex flex-col overflow-hidden w-full", {
              hidden: tab !== "add-inputs" || mode !== "convert-recipe",
            })}
          >
            <ComfyUIWorkflowSettings
              onPreview={() => handleSwitchTab("preview")}
              isDraft={workflow?.isDraft}
              ref={settingsRef}
              form={settingsForm}
              onPublish={handleOpenPublishModal}
              graphInputs={graphInputs}
              onSelect={handleSelectComfyUINode}
            />
          </form>
          <div
            className={classNames("flex-1 flex flex-col overflow-hidden w-full", {
              hidden: tab !== "preview" || mode !== "convert-recipe",
            })}
          >
            <ComfyUIWorkflowSteps
              onBack={() => handleSwitchTab("add-inputs")}
              ref={recipeFormRef}
              isEditing={isEditing}
              graphInputs={graphInputs}
              steps={steps}
              onPublish={handleOpenPublishModal}
              onConfig={handleConfigRecipe}
              onInputChange={handleRecipeInputChange}
            />
          </div>
        </div>
      </ResizeContainer>
      <div
        className={classNames("absolute top-0 left-0 z-[1] w-full h-full", {
          hidden: !isDragging,
        })}
      />
    </>
  )
}

export default LeftBarOption
