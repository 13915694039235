import Popover from "@/components/Popover"
import { PlusIcon } from "@/components/shared/icons"
import { cdnUrl } from "@/constants"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/lib/utils"
import { useAuth } from "@/providers/authContext"
import { useWorkspaceContext } from "@/providers/WorkspaceProvider"
import { currency } from "@/utils"
import classNames from "classnames"
import Link from "next/link"
import { useState } from "react"

const CreditsInfo = ({
  className,
  isNotFormat,
  isButton,
}: {
  className?: string
  isNotFormat?: boolean
  isButton?: boolean
}) => {
  const { userInfoQuery } = useAuth()
  const {
    currentWorkspaceQuery: { data: currentWorkspaceDetail },
  } = useWorkspaceContext()
  const [isOpen, setIsOpen] = useState(false)

  const isHidden = currentWorkspaceDetail?.owner?.uid !== userInfoQuery?.data?.uid

  return (
    <Popover
      align="end"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      trigger={
        <div
          className={cn(
            "flex items-center space-x-1 bg-atherGray-900 rounded-full cursor-pointer py-1.5 px-2 w-[100px]",
            className,
          )}
        >
          <div className="flex items-center flex-1 space-x-1">
            <img src={`https://${cdnUrl}/resources/default-avatar.png`} alt="" className="w-6 h-6" />
            <span
              className={classNames("text-base text-right font-semibold truncate", {
                "flex-1": !isButton,
              })}
            >
              {currency(
                parseInt(userInfoQuery?.data?.subscriptionCredits ?? "0") +
                  parseInt(userInfoQuery?.data?.availableCredits ?? "0"),
                undefined,
                {
                  isConvert: isNotFormat ? false : true,
                },
              )}
            </span>
          </div>
          {isButton && (
            <div className="p-2 bg-atherPurple-500 rounded-xl">
              <PlusIcon width={14} height={14} />
            </div>
          )}
        </div>
      }
    >
      <div className="w-[15.5rem] z-20 shadow-xl overflow-hidden">
        <div className="p-4 text-sm bg-atherGray-900 overflow-auto max-h-[65vh] border-[1px] border-[#323230] rounded-2xl">
          <p className="font-semibold mb-2">CREDIT bank</p>
          <div className="flex items-center border-b border-atherGray-800 py-2 space-x-2">
            <p className="font-semibold text-xs text-atherGray-300">From CREDITs pack</p>
            <div className="flex items-center text-xs font-semibold flex-1 justify-end">
              <img src={`https://${cdnUrl}/resources/default-avatar.png`} alt="" className="w-5 h-5" />
              <span
                title={parseInt(userInfoQuery?.data?.availableCredits?.toString() ?? "0").toString()}
                className="text-right font-semibold truncate w-[3rem]"
              >
                {currency(parseInt(userInfoQuery?.data?.availableCredits?.toString() ?? "0"), undefined, {
                  isConvert: isNotFormat ? false : true,
                })}
              </span>
            </div>
          </div>
          <div className="flex items-center border-b border-atherGray-800 py-2 space-x-2">
            <p className="font-semibold text-xs text-atherGray-300">From Subscription</p>
            <div className="flex items-center text-xs font-semibold flex-1 justify-end">
              <img src={`https://${cdnUrl}/resources/default-avatar.png`} alt="" className="w-5 h-5" />
              <span
                title={(userInfoQuery?.data?.subscriptionCredits ?? "0").toString() ?? "0"}
                className="text-right font-semibold truncate w-[3rem]"
              >
                {currency(parseInt(userInfoQuery?.data?.subscriptionCredits?.toString() ?? "0"), undefined, {
                  isConvert: isNotFormat ? false : true,
                })}
              </span>
            </div>
          </div>
          <div className="flex items-center py-2 space-x-2">
            <p className="font-semibold text-xs text-atherGray-300">Total</p>
            <div className="flex items-center text-xs font-semibold flex-1 justify-end">
              <img src={`https://${cdnUrl}/resources/default-avatar.png`} alt="" className="w-5 h-5" />
              <span
                title={(
                  parseInt(userInfoQuery?.data?.subscriptionCredits ?? "0") +
                  parseInt(userInfoQuery?.data?.availableCredits ?? "0")
                ).toString()}
                className="text-right font-semibold truncate w-[3rem]"
              >
                {currency(
                  parseInt(userInfoQuery?.data?.subscriptionCredits ?? "0") +
                    parseInt(userInfoQuery?.data?.availableCredits ?? "0"),
                  undefined,
                  {
                    isConvert: isNotFormat ? false : true,
                  },
                )}
              </span>
            </div>
          </div>
          {!isHidden && (
            <Link
              onClick={() => {
                googleAnalytics.event({
                  action: "click",
                  category: "navbar",
                  label: "More Credits",
                })
                setIsOpen(false)
              }}
              href={"/settings/account?tab=Plans"}
              title="More Credits"
              className="mt-2 w-full rounded-lg block text-center font-semibold bg-atherPurple-500 hover:bg-atherPurple-400 active:bg-atherPurple-600 disabled:bg-atherPurple-700 p-2"
            >
              + More CREDITS
            </Link>
          )}

          {/* <Link
            onClick={() => {
              googleAnalytics.event({
                action: "click",
                category: "navbar",
                label: "learn more about CREDITS",
              })
              setIsOpen(false)
            }}
            href={"#"}
            title="Learn more about CREDITS"
            className="underline text-atherGray-400 mt-2 inline-block text-xs"
            
          >
            learn more
          </Link> */}
        </div>
      </div>
    </Popover>
  )
}

export default CreditsInfo
